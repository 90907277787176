import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import styled, { useTheme } from 'styled-components';
import { cropBrandAssociations, ICropBrandAssociation } from '../../ProductGamePlan/CropBrandAssociation';
import { TableHeader } from '../../../../components/Table/TableHeader';
import { AppDispatch, RootState } from '../../../../logic/store/Store';
import { getDraftQuotes, IQuoteRequestLineUpdate, ISendQuoteRequest, sendQuote } from '../../../../logic/store/Grower/QuoteThunks';
import { connect, ConnectedProps } from 'react-redux';
import { IDraftQuoteResponse } from '../../../../logic/Models/Responses/DraftQuoteResponse';
import { getSeedsForGrower, getEnogenContractCheck } from '../../../../logic/store/Seeds/SeedsActions';
import { dynamicSort, makeDispatch } from '../../../../logic/Utility/Utils';
import { SingleTreatmentRows } from './SingleTreatmentRows';
import { StyledTableRows, TableRowType } from '../../../../components/Table/TableRows';
import { TableRow } from '../../../../components/Table/TableRow';
import { CropConfig, ICropConfig, soyId } from '../../../../logic/store/Seeds/CropsSlice';
import { StyledModal } from '../../../../components/StyledModal/StyledModal';
import { IGrowerResponse } from '../../../../logic/Models/Responses/GrowerResponse';
import { calculateTotalBags, ISelectableTreatment, setupTreatments } from '../Shared/QuoteUtils';
import { getCustomTreatments } from '../../../../logic/store/User/CustomTreatmentsActions';
import { ShoppingCartHeader } from '../Shared/ShoppingCartHeader';
import { getShipFromAddresses } from '../../../../logic/store/User/UserInfoSlice';
import { hasEffectivePermission, getCurrentActingUser } from '../../../../logic/store/User/AuthSlice';
import { UserPermission } from '../../../../logic/Models/Responses/UserPermissions';
import { currentEnogenFlowUrl, displayEnogenFlow, hideEnogenFlow, setSelectedPlanId } from '../../../../logic/store/UI/UISlice';
import { IFrameModal } from '../../../../components/IFrameModal/IFrameModal';
import { CoveringLoader } from '../../../../components/LoadingSpinner/CoveringLoader';
import { ScaleLoader } from 'react-spinners';

const QuoteStyledTableRows = styled(StyledTableRows)`
	height: 100%;
	padding-top: 1.5%;
	overflow-y: auto;
`;

const StyledTableRow = styled(TableRow)`
	padding: 0 1.5%;
	height: 50px;
`;

const StyledQuoteContainer = styled.div`
	display: flex;
	width: 100%;
	height: 94%;
	padding-right: 5%;
	padding-left: 5%;
	padding-bottom: 2.5%;
	flex-direction: column;
`;

const cornHeaderColumns: string[] =
	[
		'Hybrid',
		'Seed Treatment',
		'Rate',
		'Applied Acres',
		'Paper Bag(s)',
		'QBit(s)',
		'Total Bags / Amt Required'
	];

// Total to 100%, value for each column width
const cornHeaderSpacing = '18% 26% 8% 12% 11% 8% 17%';
const cornSpacing = '18% 26% 8% 12% 11% 8% 13% 4%';

const soyHeaderColumns: string[] =
	[
		'Variety',
		'Seed Treatment',
		'Rate',
		'Applied Acres',
		'Paper Bag(s)',
		'QBit(s)',
		'Tote Bag',
		'TruBulk',
		'Total Bags / Amt Required'
	];

// Total to 100%, value for each column width
const soyHeaderSpacing = '7% 22% 8% 11% 11% 8% 10% 7% 17%';
const soySpacing = '9% 20% 8% 11% 11% 8% 10% 7% 13% 4%';
const soyChildSpacing = '3% 30% 4% 11% 11% 8% 10% 7% 13% 4%';

export interface IProductQuoteRowItem
{
	AmtRequired: number;
	AdjAppliedAcres: number;
	AppliedAcres: number;
	BrandName: string;
	ChildrenTreatmentRows?: { [key: string]: IProductQuoteRowItem };
	CropId: string;
	Cubits: number;
	HybridName: string;
	Id: string;
	QuoteLineId: string;
	PaperBags: number;
	Rate: number;
	RM: number;
	SeedTreatments: ISelectableTreatment[];
	TotalBags: number;
	Totebags: number;
	Trubulk: number;
}

export interface IUpdateHybridTreatmentParams
{
	BrandName: string;
	CropId: string;
	CustomTreatmentId: string;
	HybridId: string;
	TreatmentName: string;
	UpdateItem: Partial<IProductQuoteRowItem>;
}

interface IProductQuoteProps extends PropsFromRedux
{
	selectedPlanId: string;
	selectedGrower: IGrowerResponse;
}

const ProductQuoteComponent = (props: IProductQuoteProps) =>
{
	const {
		AccessToken,
		CurrentCropYear,
		CurrentUserId,
		CustomTreatments,
		EnogenFlowUrl,
		EnogenRootUrl,
		isGHX,
		IsLoadingGetTreatments,
		IsLoadingQuotes,
		IsLoadingSeedsForGrower,
		IsQuoteError,
		Seeds,
		selectedGrower,
		selectedPlanId,
		ShipFromAddresses,
		UISelectedPlanId,
		DisplayEnogenFlow,
		GetCustomTreatments,
		GetEnogenContractCheck,
		GetQuotes,
		GetSeedsForGrower,
		GetShipFromAddresses,
		HideEnogenFlow,
		SendQuote,
		SetSelectedPlanId,
	} = props;

	const theme = useTheme();
	const history = useHistory();

	const [currentRecommendation, setCurrentRecommendation] = useState<ICropBrandAssociation>(cropBrandAssociations[0]);
	const [currentQuote, setCurrentQuote] = useState<IDraftQuoteResponse>(undefined);
	const [quoteLinesToSeeds, setQuoteLinesToSeeds] = useState<{ [key: string]: IProductQuoteRowItem[] }>({});
	const [quoteRetrieved, setQuoteRetrieved] = useState<boolean>(false);
	const [openSendModal, setOpenSendModal] = useState<boolean>(false);
	const [isResetQuote, setIsResetQuote] = useState<boolean>(false);
	const [openResetModal, setOpenResetModal] = useState<boolean>(false);
	const [openEnogenContractModal, setOpenEnogenContractModal] = useState<boolean>(false);
	const [saveSendmodalTitle, setSaveSendmodalTitle] = useState<string>('');
	const [addressSelection, setAddressSelection] = useState<string>(undefined);
	const [sendType] = useState<string>(isGHX ? 'Order' : 'Proposal');
	const [disabledSaveTooltip, setDisabledSaveTooltip] = useState<string>('');
	const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

	useEffect(() =>
	{
		if (!quoteRetrieved)
		{
			GetQuotes({});
			if (!Seeds && !IsLoadingSeedsForGrower)
			{
				GetSeedsForGrower();
			}
			GetCustomTreatments();
			GetShipFromAddresses();
			setQuoteRetrieved(true);
		}
	}, [IsLoadingQuotes, IsLoadingSeedsForGrower]);

	useEffect(() =>
	{
		if (!IsLoadingQuotes && quoteRetrieved && selectedGrower && selectedGrower.DraftQuotes)
		{
			// The location-state value of selectedPlanId will vanish after a certin amount of 'sends' or 'saves'
			// try to use the store based value if this happens
			const planId = selectedPlanId ? selectedPlanId : UISelectedPlanId;

			// TODO: Make this more robust, if it does not find a quote pop an error? Show a different view?
			setCurrentQuote(selectedGrower.DraftQuotes.find(q => q.PlanId === planId && q.IsActive));

			// Only store the plan ID if we do not currently have it
			if (!UISelectedPlanId)
			{
				SetSelectedPlanId(selectedPlanId);
			}
		}
	}, [IsLoadingQuotes, quoteRetrieved, selectedGrower, selectedGrower?.DraftQuotes]);

	useEffect(() =>
	{
		if (!IsLoadingSeedsForGrower && !IsLoadingQuotes && !IsLoadingGetTreatments
			&& !IsQuoteError && Seeds && currentQuote && quoteRetrieved || isResetQuote)
		{
			// If this is a reset, be sure to clear the reset flag
			setIsResetQuote(false);

			// Use the Seeds list to identify the Quoteline items - they do not have a Crop on them so search
			// them all!
			const newQuoteLinesToSeeds: { [key: string]: IProductQuoteRowItem[] } = {};
			currentQuote.QuoteLines.map(ql =>
			{
				const allSeeds = Seeds.flatMap(s => s.BrandApplications.flatMap(ba => ba.Hybrids));
				const foundSeed = allSeeds.find(s => s.Id === ql.HybridId);
				if (foundSeed)
				{
					const { BrandName, CropId, MaterialAvailability } = foundSeed;
					const cropConfig: ICropConfig = CropConfig()[CropId];
					const { seedsPerBag } = cropConfig;
					const amtRequired: number = Math.ceil((ql.Rate * (Math.round(ql.AppliedAcres * 10) / 10)) / seedsPerBag);
					const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
					const key: string = CropId + brandApplication;
					let cropCustomTreatments = CustomTreatments;
					if (CropId !== soyId)
					{
						cropCustomTreatments = [];
					}
					const seedTreatment: ISelectableTreatment[] = setupTreatments(MaterialAvailability, cropCustomTreatments);

					const quoteRowItem: IProductQuoteRowItem =
					{
						AmtRequired: amtRequired,
						AdjAppliedAcres: 0,
						AppliedAcres: Math.round(ql.AppliedAcres * 10) / 10,
						BrandName: BrandName,
						CropId: CropId,
						Cubits: ql.QuantityQB ?? 0,
						HybridName: ql.HybridName,
						Id: ql.HybridId,
						QuoteLineId: ql.Id,
						PaperBags: ql.QuantityPB ?? 0,
						Rate: ql.Rate ?? 0,
						RM: Number.parseFloat(foundSeed.RelativeMaturity),
						SeedTreatments: seedTreatment,
						TotalBags: calculateTotalBags(ql.QuantityQB, ql.QuantityPB, ql.QuantityTB, ql.QuantityTO, CropId),
						Totebags: ql.QuantityTO ?? 0,
						Trubulk: ql.QuantityTB ?? 0
					};

					// If this is a custom treatment the ID is used instead of the name in the key
					const treatmentKey = ql.CustomTreatmentId ? ql.CustomTreatmentId : ql.Treatment.toLocaleLowerCase();

					// We know this Hybrid has more than one treatment, so make sure we set it as selected
					if (quoteRowItem.SeedTreatments.length > 1)
					{
						const copiedItem = { ...quoteRowItem };

						// Get the single treatment from the Quote Line
						copiedItem.SeedTreatments = quoteRowItem.SeedTreatments.filter(st => 
							st.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase() || st.CustomTreatmentId === treatmentKey);
						copiedItem.SeedTreatments[0].Selected = true;

						quoteRowItem.SeedTreatments.find(st => st.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase()
							|| st.CustomTreatmentId === treatmentKey).Selected = true;

						// Make a child row from the quote line treatment
						quoteRowItem.ChildrenTreatmentRows = {};
						quoteRowItem.ChildrenTreatmentRows[ql.HybridId + treatmentKey] = copiedItem;
					}

					if (newQuoteLinesToSeeds[key])
					{
						const currentNewQuoteLines = newQuoteLinesToSeeds[key];
						// If the hybrid is already in the list, this means we have another version of it with a different treatment
						// and must add it as a child
						const foundIndex: number = currentNewQuoteLines.findIndex(cnq => cnq.Id === ql.HybridId);
						if (foundIndex > -1)
						{
							// Do we have any children rows yet?
							if (!currentNewQuoteLines[foundIndex].ChildrenTreatmentRows)
							{
								currentNewQuoteLines[foundIndex].ChildrenTreatmentRows = {};
							}

							// Select the treatment in the treatment list
							currentNewQuoteLines[foundIndex].SeedTreatments.find(st =>
								st.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase() || st.CustomTreatmentId === treatmentKey).Selected = true;
							
							currentNewQuoteLines[foundIndex].ChildrenTreatmentRows[ql.HybridId + treatmentKey] =
								quoteRowItem.ChildrenTreatmentRows[ql.HybridId + treatmentKey];

							// Update the total bags value on the parent row
							currentNewQuoteLines[foundIndex].TotalBags = Object.values(currentNewQuoteLines[foundIndex].ChildrenTreatmentRows)
								.reduce((sum, row) => sum + row.TotalBags, 0);

							// Update the total applied acres value on the parent row
							currentNewQuoteLines[foundIndex].AppliedAcres = Object.values(currentNewQuoteLines[foundIndex].ChildrenTreatmentRows)
								.reduce((sum, row) => sum + row.AppliedAcres, 0);

							// Update the parent row rate to be a weighted average of the children row rates
							const totalRates = Object.values(currentNewQuoteLines[foundIndex].ChildrenTreatmentRows)
								.reduce((sum, row) => sum + (row.AppliedAcres * row.Rate), 0);
							currentNewQuoteLines[foundIndex].Rate = Math.round(totalRates / currentNewQuoteLines[foundIndex].AppliedAcres);

							currentNewQuoteLines[foundIndex].AmtRequired =
								Math.ceil((currentNewQuoteLines[foundIndex].Rate * currentNewQuoteLines[foundIndex].AppliedAcres) / seedsPerBag);

							newQuoteLinesToSeeds[key] = currentNewQuoteLines;
						}
						else
						{
							newQuoteLinesToSeeds[key].push(quoteRowItem);
						}
					}
					else
					{
						newQuoteLinesToSeeds[key] = [quoteRowItem];
					}
				}
			});
			setQuoteLinesToSeeds({ ...newQuoteLinesToSeeds });
		}
	}, [IsLoadingSeedsForGrower, IsLoadingQuotes, isResetQuote, currentQuote, quoteRetrieved, Seeds]);

	// Deletes a single treatment row (not a child treatment row)
	const onDelete = (hybridId: string, treatment: ISelectableTreatment) =>
	{
		const currentQuoteLinesToSeeds = quoteLinesToSeeds;
		const allSeeds = Seeds.flatMap(s => s.BrandApplications.flatMap(ba => ba.Hybrids));
		const foundSeed = allSeeds.find(s => s.Id === hybridId);

		if (foundSeed)
		{
			const { CropId, BrandName } = foundSeed;
			const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
			const key: string = CropId + brandApplication;
			const currentLinesToUpdate: IProductQuoteRowItem[] = currentQuoteLinesToSeeds[key];
			currentQuoteLinesToSeeds[key] = currentLinesToUpdate.filter(cl => cl.Id !== hybridId);

			setQuoteLinesToSeeds({ ...currentQuoteLinesToSeeds });
		}
	};

	const resetQuote = () =>
	{
		setIsResetQuote(true);
		setOpenResetModal(false);
		setQuoteLinesToSeeds({});
	};

	const saveOrSend = async (send: boolean) =>
	{
		setSaveSendmodalTitle(send ? 'Send' : 'Save');

		// If the user is GHX and they have Enogen seeds ask them to see if they did their Enogen contract before sending
		const enogenProductIndex = Object.keys(quoteLinesToSeeds).findIndex(k => k.endsWith('Enogen'));
		if (isGHX && enogenProductIndex > -1 && send)
		{
			// Calculate the number of Enogen product bags
			const key = Object.keys(quoteLinesToSeeds)[enogenProductIndex];
			const row = quoteLinesToSeeds[key];
			const allBagValues = Object.values(row).flatMap(x => x.TotalBags);
			const sumBags = allBagValues.reduce((sum, next) => sum + next, 0);

			const isEnogenContractValid = await fetchEnogenContractStatus(sumBags);
			if (isEnogenContractValid == null)
			{
				setOpenSendModal(false);
				setOpenEnogenContractModal(false);
			}
			else if (isEnogenContractValid)
			{
				setOpenSendModal(true);
			}
			else
			{
				setOpenEnogenContractModal(true);
			}
		}
		else
		{
			setOpenSendModal(true);
		}
	};

	const fetchEnogenContractStatus = async (totalBags: number) =>
	{
		const contractStatus = (await GetEnogenContractCheck({ cropYear: CurrentCropYear, totalBags: totalBags.toString() }));
		if (contractStatus.error)
		{
			return null;
		}
		return contractStatus.payload as boolean;
	};

	const sendQuote = (send: boolean) =>
	{
		const convertedLines: IQuoteRequestLineUpdate[] = [];
		const allLines = Object.values(quoteLinesToSeeds).flatMap(qlts => qlts);
		allLines.forEach((ql: IProductQuoteRowItem) =>
		{
			// If more than one seed treatment, check children
			if (ql.SeedTreatments.length > 1)
			{
				if (ql.ChildrenTreatmentRows)
				{
					const allChildren = Object.values(ql.ChildrenTreatmentRows);
					allChildren.forEach(ctr =>
					{
						// Search the currentQuote for this HybridId/Treatment combination to see if it is new or not
						const existingHybridCombo = currentQuote.QuoteLines.find(quoteLine =>
							quoteLine.HybridId === ctr.Id && quoteLine.Treatment.toLowerCase() === ctr.SeedTreatments[0].Treatment.toLowerCase());
						const updatedLine: IQuoteRequestLineUpdate =
						{
							AppliedAcres: ctr.AdjAppliedAcres !== 0 ? Math.round(ctr.AdjAppliedAcres * 10) / 10 : Math.round(ctr.AppliedAcres * 10) / 10,
							CustomTreatmentId: ctr.SeedTreatments[0].CustomTreatmentId,
							HybridId: ctr.Id,
							Id: existingHybridCombo ? ctr.QuoteLineId : null,
							QuantityPB: ctr.PaperBags,
							QuantityQB: ctr.Cubits,
							QuantityTB: ctr.Trubulk,
							QuantityTO: ctr.Totebags,
							Rate: ctr.Rate,
							Treatment: ctr.SeedTreatments[0].Treatment
						};

						convertedLines.push(updatedLine);
					});
				}
			}
			else
			{				
				// Search the currentQuote for this HybridId/Treatment combination to see if it is new or not
				const existingHybridCombo = currentQuote.QuoteLines.find(quoteLine =>
					quoteLine.HybridId === ql.Id && quoteLine.Treatment === ql.SeedTreatments[0].Treatment);

				const updatedLine: IQuoteRequestLineUpdate =
				{
					AppliedAcres: ql.AdjAppliedAcres !== 0 ? Math.round(ql.AdjAppliedAcres * 10) / 10 : Math.round(ql.AppliedAcres * 10) / 10,
					CustomTreatmentId: ql.SeedTreatments[0].CustomTreatmentId,
					HybridId: ql.Id,
					Id: existingHybridCombo ? ql.QuoteLineId : null,
					QuantityPB: ql.PaperBags,
					QuantityQB: ql.Cubits,
					QuantityTB: ql.Trubulk,
					QuantityTO: ql.Totebags,
					Rate: ql.Rate,
					Treatment: ql.SeedTreatments[0].Treatment
				};

				convertedLines.push(updatedLine);
			}
		});

		const quoteRequest: ISendQuoteRequest =
		{
			isGHX: isGHX,
			SendQuote: send,
			ShipFrom: addressSelection,
			QuoteId: currentQuote.Id,
			QuoteRequestLineUpdates: convertedLines
		};

		setOpenSendModal(false);
		SendQuote(quoteRequest);
	};

	const addTreatmentRow = (hybridId: string, treatment: ISelectableTreatment) =>
	{
		const currentQuoteLinesToSeeds = quoteLinesToSeeds;
		const allSeeds = Seeds.flatMap(s => s.BrandApplications.flatMap(ba => ba.Hybrids));
		const foundSeed = allSeeds.find(s => s.Id === hybridId);

		if (foundSeed)
		{
			const { CropId, BrandName } = foundSeed;
			const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
			const key: string = CropId + brandApplication;
			const currentLinesToUpdate: IProductQuoteRowItem[] = currentQuoteLinesToSeeds[key];
			const foundIndex: number = currentLinesToUpdate.findIndex(cl => cl.Id === hybridId && cl.SeedTreatments.length > 1);
			const foundProductRowItem = currentLinesToUpdate[foundIndex];
			const { AdjAppliedAcres, AppliedAcres, HybridName, Id, QuoteLineId, Rate } = foundProductRowItem;
			const cropConfig: ICropConfig = CropConfig()[CropId];
			const { seedsPerBag } = cropConfig;
			const amtRequired: number = Math.ceil((Rate * (Math.round(AppliedAcres * 10) / 10)) / seedsPerBag);

			// If this is a custom treatment the ID is used instead of the name in the key
			const treatmentKey = treatment.CustomTreatmentId ? treatment.CustomTreatmentId : treatment.Treatment.toLocaleLowerCase();
			foundProductRowItem.SeedTreatments.find(t => t.CustomTreatmentId ?
				t.CustomTreatmentId === treatmentKey : t.Treatment.toLocaleLowerCase() === treatmentKey).Selected = true;

			const quoteRowItem: IProductQuoteRowItem =
			{
				AmtRequired: amtRequired,
				AdjAppliedAcres: AdjAppliedAcres ? Math.round(AdjAppliedAcres * 10) / 10 : 0,
				AppliedAcres: Math.round(AppliedAcres * 10) / 10,
				BrandName: BrandName,
				CropId: CropId,
				Cubits: 0,
				HybridName: HybridName,
				Id: Id,
				QuoteLineId: QuoteLineId,
				PaperBags: 0,
				Rate: Rate ?? 0,
				RM: Number.parseFloat(foundSeed.RelativeMaturity),
				SeedTreatments: [treatment],
				TotalBags: 0,
				Totebags: 0,
				Trubulk: 0
			};

			if (!foundProductRowItem.ChildrenTreatmentRows)
			{
				foundProductRowItem.ChildrenTreatmentRows = {};
			}
			foundProductRowItem.ChildrenTreatmentRows[hybridId + treatmentKey] = quoteRowItem;
			currentLinesToUpdate[foundIndex] = foundProductRowItem;
			currentQuoteLinesToSeeds[key] = currentLinesToUpdate;

			setQuoteLinesToSeeds({ ...currentQuoteLinesToSeeds });
		}
	};

	const removeTreatmentRow = (hybridId: string, treatment: ISelectableTreatment) =>
	{
		const currentQuoteLinesToSeeds = quoteLinesToSeeds;
		const allSeeds = Seeds.flatMap(s => s.BrandApplications.flatMap(ba => ba.Hybrids));
		const foundSeed = allSeeds.find(s => s.Id === hybridId);

		if (foundSeed)
		{
			const { CropId, BrandName } = foundSeed;
			const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
			const key: string = CropId + brandApplication;
			const currentLinesToUpdate: IProductQuoteRowItem[] = currentQuoteLinesToSeeds[key];
			const foundIndex: number = currentLinesToUpdate.findIndex(cl => cl.Id === hybridId && cl.SeedTreatments.length > 1);
			const foundProductRowItem = currentLinesToUpdate[foundIndex];

			// If this is a custom treatment the ID is used instead of the name in the key
			const treatmentKey = treatment.CustomTreatmentId ? treatment.CustomTreatmentId : treatment.Treatment.toLocaleLowerCase();
			foundProductRowItem.SeedTreatments.find(t => t.CustomTreatmentId ?
				t.CustomTreatmentId === treatmentKey : t.Treatment.toLocaleLowerCase() === treatmentKey).Selected = false;

			if (foundProductRowItem.ChildrenTreatmentRows)
			{
				// Remove the key/value from the children dictionary
				delete foundProductRowItem.ChildrenTreatmentRows[hybridId + treatmentKey];
				foundProductRowItem.TotalBags = Object.values(foundProductRowItem.ChildrenTreatmentRows).reduce((sum, row) => sum + row.TotalBags, 0);
			}

			setQuoteLinesToSeeds({ ...currentQuoteLinesToSeeds });
		}
	};

	const updateValues = (params: IUpdateHybridTreatmentParams) =>
	{
		if (!params)
		{
			return;
		}

		const { BrandName, CropId, CustomTreatmentId, HybridId, TreatmentName, UpdateItem } = params;
		const cropConfig: ICropConfig = CropConfig()[CropId];
		const { seedsPerBag } = cropConfig;
		let isChildItem: boolean = false;

		const currentQuoteLinesToSeeds = quoteLinesToSeeds;
		const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
		const key: string = CropId + brandApplication;

		// If this is a custom treatment the ID is used instead of the name in the key
		const treatmentKey = CustomTreatmentId ? CustomTreatmentId : TreatmentName.toLocaleLowerCase();

		const currentLinesToUpdate: IProductQuoteRowItem[] = currentQuoteLinesToSeeds[key];
		const foundIndex: number = currentLinesToUpdate.findIndex(cl => cl.Id === HybridId);
		let foundProductRowItem = currentLinesToUpdate[foundIndex];

		// Are we updating a child or a parent?
		if (foundProductRowItem.ChildrenTreatmentRows)
		{
			foundProductRowItem = foundProductRowItem.ChildrenTreatmentRows[HybridId + treatmentKey];
			isChildItem = true;
		}

		const lineData: IProductQuoteRowItem = {
			...foundProductRowItem,
			...UpdateItem
		};

		if (UpdateItem.PaperBags >= 0 || UpdateItem.Cubits >= 0 || UpdateItem.Totebags >= 0 || UpdateItem.Trubulk >= 0)
		{
			// Make sure we have no decimals entered from the user
			lineData.Cubits = lineData.Cubits ? Math.trunc(lineData.Cubits) : 0;
			lineData.PaperBags = lineData.PaperBags ? Math.trunc(lineData.PaperBags) : 0;
			lineData.Totebags = lineData.Totebags ? Math.trunc(lineData.Totebags) : 0;
			lineData.Trubulk = lineData.Trubulk ? Math.trunc(lineData.Trubulk) : 0;

			lineData.TotalBags = calculateTotalBags(lineData.Cubits, lineData.PaperBags, lineData.Trubulk, lineData.Totebags, CropId);
		}

		if (isChildItem)
		{
			currentLinesToUpdate[foundIndex].ChildrenTreatmentRows[HybridId + treatmentKey] = lineData;

			// Set the adjusted applied acres if the user has exceeded the amount required --
			// For a child/parent rowset the parent total bags is the sum of all of the children row total bags
			currentLinesToUpdate[foundIndex].TotalBags = Object.values(currentLinesToUpdate[foundIndex].ChildrenTreatmentRows)
				.reduce((sum, row) => sum + row.TotalBags, 0);

			if (currentLinesToUpdate[foundIndex].TotalBags !== currentLinesToUpdate[foundIndex].AmtRequired)
			{
				// Update the parent row for the rolling totals
				const totalBags = currentLinesToUpdate[foundIndex].TotalBags;
				const rate = currentLinesToUpdate[foundIndex].Rate;
				const calculatedAdjAppliedAcres = (totalBags * seedsPerBag) / rate;
				currentLinesToUpdate[foundIndex].AdjAppliedAcres = Math.round(calculatedAdjAppliedAcres * 10) / 10;

				// Update the child row
				const childTotalBags = currentLinesToUpdate[foundIndex].ChildrenTreatmentRows[HybridId + treatmentKey].TotalBags;
				const calculatedChildAdjAppliedAcres = (childTotalBags * seedsPerBag) / rate;
				currentLinesToUpdate[foundIndex].ChildrenTreatmentRows[HybridId + treatmentKey].AdjAppliedAcres = Math.round(calculatedChildAdjAppliedAcres * 10) / 10;
			}
			else
			{
				currentLinesToUpdate[foundIndex].AdjAppliedAcres = 0;
				currentLinesToUpdate[foundIndex].ChildrenTreatmentRows[HybridId + treatmentKey].AdjAppliedAcres = 0;
			}
		}
		else
		{
			currentLinesToUpdate[foundIndex] = lineData;

			// Set the adjusted applied acres if the user has exceeded the amount required
			if (currentLinesToUpdate[foundIndex].TotalBags !== currentLinesToUpdate[foundIndex].AmtRequired)
			{
				const totalBags = currentLinesToUpdate[foundIndex].TotalBags;
				const rate = currentLinesToUpdate[foundIndex].Rate;
				currentLinesToUpdate[foundIndex].AdjAppliedAcres = Math.round(((totalBags * seedsPerBag) / rate) * 10) / 10;
			}
			else
			{
				currentLinesToUpdate[foundIndex].AdjAppliedAcres = 0;
			}
		}

		currentQuoteLinesToSeeds[key] = currentLinesToUpdate;
		setQuoteLinesToSeeds({ ...currentQuoteLinesToSeeds });
	};

	const launchNewProductGamePlan = () =>
	{
		history.push('/fieldactivities/productplan');
	};

	const displayEnogenFlowOnClick = useCallback(() =>
	{
		DisplayEnogenFlow({
			accessToken: AccessToken,
			apiBaseUrl: document.location.host,
			enogenFlowBaseUrl: EnogenRootUrl,
			selectedGrowerId: selectedGrower.Id,
			userId: CurrentUserId
		});
	}, [AccessToken, document.location.host, selectedGrower, CurrentUserId]);

	useEffect(() =>
	{
		if (quoteLinesToSeeds)
		{
			const disableSave = productsDoNotExistOrEmptyQuantity();
			if (disableSave)
			{
				setDisableSaveButton(true);
			}
			else
			{
				setDisableSaveButton(false);
			}
		}
	},[quoteLinesToSeeds]);

	const productsDoNotExistOrEmptyQuantity = (): boolean =>
	{
		let numProducts: number = 0;
		Object.keys(quoteLinesToSeeds).forEach(key =>
		{
			numProducts += quoteLinesToSeeds[key].length;
		});

		const productsExist: boolean = numProducts > 0;

		let productMissingQuantity: boolean = false;

		if (productsExist)
		{
			const allLines:IProductQuoteRowItem[] = [];
			// Check that each product has a quantity
			Object.keys(quoteLinesToSeeds).forEach(key =>
			{
				const allLinesForKey = quoteLinesToSeeds[key].flatMap(q => q);
				allLines.push(...allLinesForKey);

				// Look for children lines
				quoteLinesToSeeds[key].map(q =>
				{
					if (q.ChildrenTreatmentRows && Object.keys(q.ChildrenTreatmentRows).length > 0)
					{
						allLines.push(...Object.values(q.ChildrenTreatmentRows));
					}
				});
			});

			productMissingQuantity = allLines.some(l => 
				// Row is a single line with a treatment
				(l.Cubits === 0 && l.PaperBags === 0 && l.Totebags === 0 && l.Trubulk === 0 && l.SeedTreatments.length === 1) ||
				// Row is a parent that should have children, if it has no children we have no quantities
				(l.SeedTreatments.length > 0 && (l.ChildrenTreatmentRows && Object.keys(l.ChildrenTreatmentRows).length === 0))
			);

			if (productMissingQuantity)
			{
				setDisabledSaveTooltip('Ensure that all product lines have a quantity ');
			}
			return productMissingQuantity;
		}

		if (!productsExist)
		{
			setDisabledSaveTooltip('Add seeds to '); // intentially left partial because the ShoppingCartHeader fills the rest in
		}

		return !productsExist;
	};

	return (
		<div style={{ width: '100%', height: '100%', zIndex: 998, paddingLeft: 70, backgroundColor: theme.colors.backgroundLM }}>
			<ShoppingCartHeader
				createType={sendType}
				currentRecommendation={currentRecommendation}
				isGHX={isGHX}
				selectedAddress={addressSelection}
				selectAddress={setAddressSelection}
				selectedGrower={selectedGrower}
				saveOrSend={saveOrSend}
				setOpenResetModal={setOpenResetModal}
				setCurrentRecommendation={setCurrentRecommendation}
				addresses={ShipFromAddresses}
				disableSaveAndConvert={disableSaveButton}
				disabledSaveTooltip={disabledSaveTooltip}
			/>
			<StyledQuoteContainer style={{ height: isGHX ? '85%' : '94%'}}>
				<div style={{ display: 'flex', flexDirection: 'column', height: '75%', marginTop: 20, marginBottom: 20 }}>
					<TableHeader className='padding-small' headers={currentRecommendation.cropName === 'Soybean' ? soyHeaderColumns : cornHeaderColumns}
						columnSpacing={currentRecommendation.cropName === 'Soybean' ? soyHeaderSpacing : cornHeaderSpacing}
					/>
					{
						!!quoteLinesToSeeds && quoteLinesToSeeds[currentRecommendation.cropId + currentRecommendation.brandApplication] &&
						<QuoteStyledTableRows >
							{/**
							 * Single Treament Hybrids - these have only 1 Treatment option available -- usually Corn/Enogen
							 */}
							{SingleTreatmentRows({
								data: quoteLinesToSeeds[currentRecommendation.cropId + currentRecommendation.brandApplication]
									.filter(ql => ql.SeedTreatments.length === 1).sort(dynamicSort('RM')),
								onClickDelete: onDelete,
								updateValues: updateValues,
								isParentRow: false,
								isChildRow: false,
								theme: theme,
							}).map(
								(row: TableRowType<IProductQuoteRowItem>) =>
									<StyledTableRow
										key={`product-quote-id-${row.data.Id}`}
										className={`product-quote-id-${row.data.Id}`}
										Id={row.data.Id}
										items={row.items}
										columnSpacing={currentRecommendation.cropName === 'Soybean' ? soySpacing : cornSpacing}
										hideExpand
									/>
							)}
							{/**
							 * Multiple Treatment Hybrids - Has a select Treatment option button and 'children' Treatment rows -- usually Soy
							 */}
							{quoteLinesToSeeds[currentRecommendation.cropId + currentRecommendation.brandApplication].filter(ql => ql.SeedTreatments.length > 1)
								.sort(dynamicSort('RM'))
								.map(ql => 
								{
									return <div key={ql.Id + 'Hybrid'} className='SelectedHybrid' style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: 10
									}}>
										{SingleTreatmentRows({
											data: [ql],
											onClickDelete: onDelete,
											updateValues: updateValues,
											addNewTreatmentItem: addTreatmentRow,
											removeTreatmentItem: removeTreatmentRow,
											isParentRow: true,
											isChildRow: false,
											theme: theme,
										}).map(
											(row: TableRowType<IProductQuoteRowItem>) =>
												<StyledTableRow
													key={`product-quote-id-parentRow-${row.data.Id}`}
													className={`product-quote-id-parentRow-${row.data.Id}`}
													style={{ marginBottom: 0 }}
													Id={row.data.Id}
													items={row.items}
													columnSpacing={currentRecommendation.cropName === 'Soybean' ? soySpacing : cornSpacing}
													hideExpand
												/>
										)}
										{
											ql.ChildrenTreatmentRows &&
											SingleTreatmentRows({
												data: Object.values(ql.ChildrenTreatmentRows),
												onClickDelete: removeTreatmentRow,
												updateValues: updateValues,
												isParentRow: false,
												isChildRow: true,
												theme: theme,
											}).map((row: TableRowType<IProductQuoteRowItem>) =>
												<StyledTableRow
													key={`product-quote-id-${row.data.Id}-${row.data.SeedTreatments[0].CustomTreatmentId
														? row.data.SeedTreatments[0].CustomTreatmentId
														: row.data.SeedTreatments[0].Treatment}`}
													className={`product-quote-id-${row.data.Id}-${row.data.SeedTreatments[0].CustomTreatmentId
														? row.data.SeedTreatments[0].CustomTreatmentId
														: row.data.SeedTreatments[0].Treatment}`}
													style={{ marginBottom: 0, marginTop: '0.1%' }}
													Id={row.data.Id}
													items={row.items}
													columnSpacing={currentRecommendation.cropName === 'Soybean' ? soyChildSpacing : cornSpacing}
													hideExpand
												/>
											)
										}
									</div>;
								})}
						</QuoteStyledTableRows>
					}
				</div>
			</StyledQuoteContainer>
			<StyledModal
				className='send-and-save-modal'
				title={`${saveSendmodalTitle} ${sendType}`}
				open={openSendModal}
				onCancel={() => setOpenSendModal(false)}
			>
				<span>{`${saveSendmodalTitle} this ${sendType.toLocaleLowerCase()}?`}</span>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
					<Button
						className='send-and-save-modal-cancel-button'
						variant='outlined'
						style={{ marginRight: '15px', minWidth: 100 }}
						onClick={() => setOpenSendModal(false)}
					>
						Cancel
					</Button>
					<Button
						className='send-and-save-modal-send-button'
						variant='dark'
						style={{ minWidth: 100 }}
						onClick={() => sendQuote(saveSendmodalTitle === 'Send' ? true : false)}
					>
						{saveSendmodalTitle}
					</Button>
				</div>
			</StyledModal>
			<StyledModal
				className='check-enogen-contract-modal'
				open={openEnogenContractModal}
				onCancel={() => setOpenEnogenContractModal(false)}
			>
				<span>{'You must create/update your Enogen contract to create an order.'}</span>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
					<Button
						className='enogen-contract-open-button'
						variant='outlined'
						style={{ marginRight: '15px', minWidth: 100 }}
						onClick={() => { displayEnogenFlowOnClick(); setOpenEnogenContractModal(false); }}
					>
						Open Enogen Contract
					</Button>
					<Button
						className='enogen-contract-cancel-button'
						variant='dark'
						style={{ minWidth: 100 }}
						onClick={() => { setOpenEnogenContractModal(false); }}
					>
						Cancel
					</Button>
				</div>
			</StyledModal>
			<IFrameModal title="Enogen Contracting" width={1200} open={!!EnogenFlowUrl}
				onCancel={() => HideEnogenFlow()}
				footer={null}>
				<iframe src={EnogenFlowUrl} />
			</IFrameModal>
			<StyledModal className='reset-modal' title={`Reset ${sendType}`} open={openResetModal} onCancel={() => setOpenResetModal(false)}>
				<span>{`Reset ${sendType} to last saved state? All un-saved changes will be lost.`}</span>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
					<Button
						className='reset-modal-cancel-button'
						variant='outlined'
						style={{ marginRight: '15px' }}
						onClick={() => setOpenResetModal(false)}
					>
						Cancel
					</Button>
					<Button className='reset-modal-send-button' variant='dark' onClick={resetQuote}>Reset</Button>
				</div>
			</StyledModal>
			<CoveringLoader
				className={(!IsLoadingQuotes && IsLoadingSeedsForGrower) ? 'loading' : ''}
				style={{backgroundColor: '#ddda', display: 'flex', flexDirection: 'column'}}
			>
				<ScaleLoader color={theme.colors.ghxOrangeLM} loading={true} />
				Loading Seeds
			</CoveringLoader>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	IsLoadingQuotes: state.grower.isLoadingDraftQuotes,
	IsQuoteError: state.grower.isError,
	IsLoadingSeedsForGrower: state.seeds.isLoading,
	IsLoadingGetTreatments: state.customTreatments.isLoadingGetTreatments,
	Seeds: state.seeds.seeds,
	CustomTreatments: state.customTreatments.treatments,
	ShipFromAddresses: state.userInfo.ShipFromAddresses,
	isGHX: hasEffectivePermission(state, UserPermission.CanSeePricing),
	UISelectedPlanId: state.ui.SelectedPlanId,
	EnogenFlowUrl: currentEnogenFlowUrl(state),
	CurrentUserId: getCurrentActingUser(state).UserId,
	EnogenRootUrl: state.config.MapCentricUrl,
	AccessToken: state.auth.userAuthToken,
	CurrentCropYear: state.crops.CropYear,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		GetQuotes: makeDispatch(dispatch, getDraftQuotes),
		GetSeedsForGrower: makeDispatch(dispatch, getSeedsForGrower),
		GetEnogenContractCheck: makeDispatch(dispatch, getEnogenContractCheck),
		SendQuote: makeDispatch(dispatch, sendQuote),
		GetCustomTreatments: makeDispatch(dispatch, getCustomTreatments),
		GetShipFromAddresses: makeDispatch(dispatch, getShipFromAddresses),
		SetSelectedPlanId: makeDispatch(dispatch, setSelectedPlanId),
		HideEnogenFlow: makeDispatch(dispatch, hideEnogenFlow),
		DisplayEnogenFlow: makeDispatch(dispatch, displayEnogenFlow),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const ProductShoppingCart = connector(ProductQuoteComponent);
