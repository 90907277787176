import { createGlobalStyle } from 'styled-components';
import Roboto100 from './fonts/Roboto/roboto-v27-latin-100.woff';
import Roboto100_2 from './fonts/Roboto/roboto-v27-latin-100.woff2';
import Roboto300 from './fonts/Roboto/roboto-v27-latin-300.woff';
import Roboto300_2 from './fonts/Roboto/roboto-v27-latin-300.woff2';
import Roboto400 from './fonts/Roboto/roboto-v27-latin-regular.woff';
import Roboto400_2 from './fonts/Roboto/roboto-v27-latin-regular.woff2';
import Roboto400_i from './fonts/Roboto/roboto-v27-latin-italic.woff';
import Roboto400_i2 from './fonts/Roboto/roboto-v27-latin-italic.woff2';
import Roboto500 from './fonts/Roboto/roboto-v27-latin-500.woff';
import Roboto500_2 from './fonts/Roboto/roboto-v27-latin-500.woff2';
import Roboto700 from './fonts/Roboto/roboto-v27-latin-700.woff';
import Roboto700_2 from './fonts/Roboto/roboto-v27-latin-700.woff2';
import Roboto900 from './fonts/Roboto/roboto-v27-latin-900.woff';
import Roboto900_2 from './fonts/Roboto/roboto-v27-latin-900.woff2';
import RobotoC400 from './fonts/Roboto Condensed/roboto-condensed-v19-latin-regular.woff';
import RobotoC400_2 from './fonts/Roboto Condensed/roboto-condensed-v19-latin-regular.woff2';
import RobotoC400_i from './fonts/Roboto Condensed/roboto-condensed-v19-latin-italic.woff';
import RobotoC400_i2 from './fonts/Roboto Condensed/roboto-condensed-v19-latin-italic.woff2';
import RobotoC700 from './fonts/Roboto Condensed/roboto-condensed-v19-latin-700.woff';
import RobotoC700_2 from './fonts/Roboto Condensed/roboto-condensed-v19-latin-700.woff2';
import RobotoC700_i from './fonts/Roboto Condensed/roboto-condensed-v19-latin-700italic.woff';
import RobotoC700_i2 from './fonts/Roboto Condensed/roboto-condensed-v19-latin-700italic.woff2';

export const GlobalStyle = createGlobalStyle<{noBodyOverFlow?: boolean}>`
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 100;
		src: local(''),
			url(${Roboto100_2}) format('woff2'),
			url(${Roboto100}) format('woff');
	}
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 300;
		src: local(''),
			url(${Roboto300_2}) format('woff2'),
			url(${Roboto300}) format('woff');
	}
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		src: local(''),
			url(${Roboto400_2}) format('woff2'),
			url(${Roboto400}) format('woff');
	}
	@font-face {
		font-family: 'Roboto';
		font-style: italic;
		font-weight: 400;
		src: local(''),
			url(${Roboto400_i2}) format('woff2'),
			url(${Roboto400_i}) format('woff');
	}
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		src: local(''),
			url(${Roboto500_2}) format('woff2'),
			url(${Roboto500}) format('woff');
	}
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		src: local(''),
			url(${Roboto700_2}) format('woff2'),
			url(${Roboto700}) format('woff');
	}
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 900;
		src: local(''),
			url(${Roboto900_2}) format('woff2'),
			url(${Roboto900}) format('woff');
	}
	@font-face {
		font-family: 'Roboto Condensed';
		font-style: normal;
		font-weight: 400;
		src: local(''),
			url(${RobotoC400_2}) format('woff2'),
			url(${RobotoC400}) format('woff');
	}
	@font-face {
		font-family: 'Roboto Condensed';
		font-style: italic;
		font-weight: 400;
		src: local(''),
			url(${RobotoC400_i2}) format('woff2'),
			url(${RobotoC400_i}) format('woff');
	}
	@font-face {
		font-family: 'Roboto Condensed';
		font-style: normal;
		font-weight: 700;
		src: local(''),
			url(${RobotoC700_2}) format('woff2'),
			url(${RobotoC700}) format('woff');
	}
	@font-face {
		font-family: 'Roboto Condensed';
		font-style: italic;
		font-weight: 700;
		src: local(''),
			url(${RobotoC700_i2}) format('woff2'),
			url(${RobotoC700_i}) format('woff');
	}
	body {
		margin: 0;
		font-family: 'Roboto', 'Roboto Condensed';
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		overflow: ${props => props.noBodyOverFlow ? 'auto' : 'hidden'};
	}
	a {
		color: '#E7E7E7';
	}
	html {
		box-sizing: border-box;
	}
	#root {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		height: 100%;
		width: 100%;
		justify-content: center;
		overflow: hidden;
		user-select: none;
	}
	*,
	*:before,
	*:after {
		box-sizing: inherit;
		font-family: inherit;
	}
	
	.mapboxgl-ctrl-geocoder {
		height: 29px;
		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
	}
	.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon-search {
		top: 0;
		left: 5px;
		height: 100%;
		fill: rgba(0, 0, 0, 0.87);
	}
	.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input {
		height: 29px;
	}
	.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
		width: 29px !important;
		min-width: 29px;
	}
	.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
		top: 4px;
	}
	.ant-dropdown {
		z-index: 1080;
	}
	.ant-picker-focused {
		border-color: black;
	}
`;