import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../Store';
import { ISoilDataResponse } from '../../Models/Responses/SoilDataResponse';
import { FeatureCollection, Geometry } from '@turf/turf';
import { IFieldResponse } from '../../Models/Responses/FieldResponse';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export const getSoilData = createTracedAsyncThunk<ISoilDataResponse, FeatureCollection<Geometry, IFieldResponse>, { dispatch: AppDispatch, state: RootState }>(
	'soilData/get',
	// Nothing needs to be passed here so set the request to null
	async (context, request: FeatureCollection<Geometry, IFieldResponse>, thunkAPI) => 
	{
		try
		{
			const response = await fetch('https://soil-data-api.fields.ghxseed.com/api/2/soildata/geojson?includeNccpi=true', {
				method: 'POST',
				body: JSON.stringify(request),
				headers: {
					// Service api key
					Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJlLWx1bWluYXRlIiwiaXNzIjoiZGV4dGVyIiwibmFtZSI6InNlcnZpY2UifQ.77D5ykzBUzwW88CPJNBVlRyVE2n2ux_ztFKGdePJvps',
					'Content-Type': 'application/json'
				}
			});

			if (!response.ok)
			{
				const parsedResponse: ISoilDataResponse = await response.json();
				thunkAPI.rejectWithValue(parsedResponse.message);
				return; 
			}

			const parsedResponse: ISoilDataResponse = await response.json();
			return parsedResponse;
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface ISoilDataState
{
	isLoading: boolean;
	isError: boolean;
	errorMessage: string;
}

export const initialState: ISoilDataState = {
	isLoading: false,
	isError: false,
	errorMessage: undefined,
};

export const SoilDataSlice = createSlice({
	name: 'soildata',
	initialState,
	reducers: {
		clearState: (state) => 
		{
			state = initialState;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;

			return state;
		},
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getSoilData.pending, (state) =>
		{
			state.isLoading = true;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getSoilData.fulfilled, (state) =>
		{
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getSoilData.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the Soil Data Boundaries. Please try again.';
				}
				else
				{
					state.errorMessage = action.payload as string;
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the Soil Data Boundaries. Please try again.';
			}
		});
	}
});

export const { clearState, clearError } = SoilDataSlice.actions;