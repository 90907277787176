import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { StackContextManager, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { merge, W3CTraceContextPropagator } from '@opentelemetry/core';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { trace, Tracer, SpanKind } from '@opentelemetry/api';
import { RootState } from '../logic/store/Store';
import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { globalSession, IScopedSession } from './session';
import { EventUserTags } from './EventTagNames';

// Track where we should be sending the traces -- locally we don't send them at all ATM.
const ingestUrl = process.env.REACT_APP_INGEST_URL ? `${process.env.REACT_APP_INGEST_URL}/v1/traces` : undefined;

// Create the provider and register our context tracker and header propagator
const provider = new WebTracerProvider({
	resource: {
		merge: (other) => { return merge({}, this, other); },
		attributes: {
			'service.name':document.location.host
		}
	}
});

provider.register({
	contextManager: new StackContextManager(),
	propagator: new W3CTraceContextPropagator(),
});

// Store our tracer internally
const _tracer = trace.getTracer('default');

// And export a variable so we can unassign it when we don't want to trace
// (eg if we didn't get a url or don't yet have an authorization token)
export let tracer: Tracer | undefined = undefined;

// Export a method to set the authorization for tracing
export const setTraceBearerToken = (token: string) => 
{ 
	tracer = undefined;
	if(ingestUrl?.length && token)
	{
		// Since we have all the valid info we need, assign the authorization and export the tracer.
		
		// Create our exporter for OTLP HTTP API
		const exporter = new OTLPTraceExporter({ 
			url: ingestUrl,
			headers: {
				'Authorization': `Bearer ${token}`
			}
		});


		// Batch outputs so we don't endlessly spam the server
		provider.addSpanProcessor(new BatchSpanProcessor(exporter, {
			scheduledDelayMillis: 15000, // every 15 seconds.
		}));

		tracer = _tracer;
	}
};

// Given a span, apply some attributes to track it.  This will let us query for 'requests from UserId:<guid> etc.'
function applyScopeAttributes(session: IScopedSession, state:RootState) 
{
	session.setTag(EventUserTags.ManagedUserGhxFieldsId, state.auth?.selectedUser?.UserId);
	session.setTag(EventUserTags.ManagedUserEmail, state.auth?.selectedUser?.UserEmail);
	session.setTag(EventUserTags.ManagedUserFoundationId, state.auth?.selectedUser?.FoundationId);
	session.setTag(EventUserTags.ManagedUserName, state.auth?.selectedUser?.UserName);
}



/**
 * This is a wrapper around createAsyncThunk; its whole purpose is to wrap the execution in a span and provide the context
 * of that span as a parameter so that the thunk can use it to track internal activity (currently just API requests);
 * 
 * @param typePrefix pass-through name for the thunk
 * @param payloadCreator The payload creator as before, but with an extra parameter for the 'context'.
 * @param options pass-through options for the thunk
 */
export const createTracedAsyncThunk = <TResponse, TRequest, TOptions>(
	typePrefix: string,	
	payloadCreator: (context: IScopedSession, request: TRequest, api: any) => ReturnType<AsyncThunkPayloadCreator<TResponse, TRequest, TOptions>>,
	options?: any) =>	
{	
	const result = createAsyncThunk<TResponse, TRequest, TOptions>(
		typePrefix,
			(async (request: TRequest, api) => 
			{ 
				const rootSession = globalSession;
				try
				{

					// We don't automaticall set success here because this could be returning a reject
					return await rootSession.trace(typePrefix, SpanKind.INTERNAL, async (scopedSession) => 
					{
						applyScopeAttributes(scopedSession, api.getState() as RootState);
						return await payloadCreator(scopedSession, request, api); 
					});
				} 
				catch(err)
				{
					// Log and re-through
					globalSession.error(err);
					throw err;
				}
			}) as AsyncThunkPayloadCreator<TResponse, TRequest, TOptions>,
			options
	);
	return result;
};