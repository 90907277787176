import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { AppDispatch, RootState } from '../Store';

export interface IClientConfig
{
	MapCentricUrl: string;
	PlantingPlanUrl: string;
	AgriclimeUrl: string;
	MapboxAccessToken: string;
	GeometryEditor: string;
	FeatureFlags: { [key: string]: boolean };
	VRSMaxScriptMaxPercent: number;
	VRSMaxScriptMinPercent: number;
}

export const getApplicationConfig = createTracedAsyncThunk<IClientConfig, null, { dispatch: AppDispatch, state: RootState }>(
	'config/get',
	async (context, request: {}, thunkAPI) =>
	{
		try
		{
			const response = await fetch(`${process.env.REACT_APP_API_HOST??''}/api/4/config`);
			return await response.json();
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export const initialState: IClientConfig = {
	MapCentricUrl: '',
	PlantingPlanUrl: '',
	AgriclimeUrl: '',
	MapboxAccessToken: '',
	GeometryEditor: '',
	FeatureFlags: {},
	VRSMaxScriptMaxPercent: 20,
	VRSMaxScriptMinPercent: -20,
};

export const configSlice = createSlice({
	name: 'config',
	initialState: initialState,
	reducers: {
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getApplicationConfig.fulfilled, (state, { payload }: PayloadAction<IClientConfig>) =>
		{
			return payload;
		});
	}
});

/**
 * A selector to get the feature flag value
 */
export const getFeatureFlag = (currentFeatureFlags: { [key: string]: boolean }, flagToLocate: string): boolean =>
{
	if (currentFeatureFlags)
	{
		return currentFeatureFlags[flagToLocate];
	}
	else
	{
		return false;
	}
};