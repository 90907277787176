import {  } from '@reduxjs/toolkit';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { IDraftQuoteResponse } from '../../Models/Responses/DraftQuoteResponse';
import { getCurrentActingUser } from '../User/AuthSlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export interface IDraftQuotes
{
	Quotes: IDraftQuoteResponse[];
	RequestedGrowerId: string;
}

export const getDraftQuotes = createTracedAsyncThunk<IDraftQuotes, {}, { dispatch: AppDispatch, state: RootState }>(
	'draftquote/get',
	async (context, request: {}, thunkAPI) => 
	{
		try
		{
			const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
			const selectedGrowerId = thunkAPI.getState().ui.SelectedGrowerId;
			
			const response = await api.getAsync<IDraftQuoteResponse[]>(`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/quotes/${selectedGrowerId}`);

			if (response.ErrorCode === null && response.Success) 
			{
				const quoteResponse: IDraftQuotes = {
					Quotes: response.Data,
					RequestedGrowerId: selectedGrowerId
				};
				return quoteResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

export interface ISendQuoteRequest
{
	isGHX: boolean;
	SendQuote: boolean;
	QuoteId: string;
	ShipFrom: string;
	QuoteRequestLineUpdates: IQuoteRequestLineUpdate[];
	SeedAssignmentUpdateRequests?: ISeedAssignmentUpdateRequest[]; // Field Plan Quote only
}

export interface IQuoteRequestLineUpdate
{
	Id?: string; // Do not send if new line, else send the existing QuoteLine Id
	CustomTreatmentId?: string;
	HybridId: string;
	Treatment: string;
	QuantityQB?: number;
	QuantityPB?: number;
	QuantityTB?: number;
	QuantityTO?: number;
	Rate: number;
	AppliedAcres: number;
}

export interface ISeedAssignmentUpdateRequest
{
	Id?: string; // Do not send if new assignment, else send the existing SeedAssignmentId
	CustomTreatmentId?: string;
	FieldId: string;
	Year: number;
	HybridId: string;
	SeedingRate: number;
	AppliedAcres: number;
	Treatment: string;
}

export interface ISingleQuoteResponse
{
	isGHX: boolean;
	Quote: IDraftQuoteResponse;
	RequestedGrowerId: string;
	SentToCropEdge: boolean;
}

export const sendQuote = createTracedAsyncThunk<ISingleQuoteResponse, ISendQuoteRequest, { dispatch: AppDispatch, state: RootState }>(
	'quote/send',
	async (context, request: ISendQuoteRequest, thunkAPI) => 
	{
		try
		{
			const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
			const selectedGrowerId = thunkAPI.getState().ui.SelectedGrowerId;
			const { SendQuote: sendQuote, QuoteId: quoteId, isGHX } = request;
			
			const response = await api.postAsync<IDraftQuoteResponse>(`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/quotes/${quoteId}?send=${sendQuote}`, request);

			if (response.ErrorCode === null && response.Success) 
			{
				const quoteResponse: ISingleQuoteResponse =
				{
					isGHX: isGHX,
					Quote: response.Data,
					RequestedGrowerId: selectedGrowerId,
					SentToCropEdge: sendQuote
				};
				return quoteResponse;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);