import { TypedStartListening } from '@reduxjs/toolkit';
import produce from 'immer';
import { getGrowers } from '../../store/Grower/GrowerThunks';
import { getCropYear } from '../../store/Seeds/CropsSlice';
import { AppDispatch, RootState } from '../../store/Store';
import { setSelectedGrowerId, IUIState, setPostLoginProgress, uiSlice } from '../../store/UI/UISlice';
import { authSlice, getCurrentActingUser, getManageableUsers, loginUser, setManagedUser, ssoExchange, tokenExchange, validateToken } from '../../store/User/AuthSlice';
import type { EffectListener } from '../AppListener';

/**
 * Manage the application startup process. Ensure that all necessary data is loaded after authentication.
 */
export const addPostLoginListener = (startListening: TypedStartListening<RootState, AppDispatch>) => 
{
	startListening({
		predicate: (action, state, oldState) => 
		{
			// Trigger on any of the actions that indicate a successful login.
			// We only expect one of these to trigger once per login.
			return [
				ssoExchange.fulfilled.type, 
				loginUser.fulfilled.type, 
				tokenExchange.fulfilled.type,
				validateToken.fulfilled.type
			].includes(action.type) && state.ui.PostLoginProgress.status === 'none';
		},
		effect: async (action, listener) => 
		{

			// We only run one at a time.
			listener.cancelActiveListeners();
			listener.unsubscribe();

			updateProgress(listener, p => p.status = 'start');

			try 
			{
				// Get all the data we need to start the app.
				await Promise.all([
					listener.dispatch(getManageableUsers()),
					listener.dispatch(getCropYear()),
				]);

				updateProgress(listener, p => p.status = 'fetching_users');

				const loggedInUser = listener.getState().userInfo;
				
				const initialUserFoundationId = listener.getState().ui.InitialUserFoundationId;
				if (initialUserFoundationId) 
				{
					const initialUser = listener.getState().auth.manageableUsers
						.find(u => u.FoundationId === initialUserFoundationId);

					if (initialUser) 
					{
						listener.dispatch(setManagedUser(initialUser));
					}
					else if(initialUserFoundationId === loggedInUser.FoundationId) 
					{
						// The selected seller is the logged in user and there's no manageable users. This is unusual but not an error condition.
					}
					else 
					{
						updateProgress(listener, p => p.errors.push('initial_user_not_found'));
					}
				}

				const currentCropYear = listener.getState().crops.CropYear;
				const years = [(Number.parseInt(currentCropYear) - 1).toString(), currentCropYear];

				updateProgress(listener, p => p.status = 'fetching_growers');

				await listener.dispatch(getGrowers({
					UpdateFromCropEdge: true,
					GrowerYears: years,
					UserId: getCurrentActingUser(listener.getState()).UserId
				}));

				const initialGrowerFoundationId = listener.getState().ui.InitialGrowerFoundationId;
				let initialGrowerFound = false;
				if (initialGrowerFoundationId) 
				{
					const initialGrower = listener.getState().grower.Growers
						.find(g => g.FoundationId === initialGrowerFoundationId);

					if (initialGrower) 
					{
						initialGrowerFound = true;
						listener.dispatch(setSelectedGrowerId(initialGrower.Id));
					}
					else 
					{
						updateProgress(listener, p => p.errors.push('initial_grower_not_found'));
					}
				}

				let landingRoute = '/dashboard'+window.location.search;
				if(initialGrowerFound)
				{
					landingRoute = '/fieldactivities';
				}
				else if (location.search.includes('enogen') || location.search.includes('sso'))
				{
					// Strip out the query string.
					landingRoute = '/dashboard';
				}
				
				updateProgress(listener, p => 
				{
					p.landingPage = landingRoute;
					p.status = 'done';
				});
			}
			catch (e) 
			{
				updateProgress(listener, p => 
				{
					// This is mostly to remove any sso token from query params.
					p.landingPage = '/login';
					p.status = 'error';
					p.fatalError = e.message;
				});

				// For some reason cannot persitor.purge but we do want to make sure the login is cleared.
				listener.dispatch(authSlice.actions.clearState());
			}

			// Resubscribe.
			listener.subscribe();
		}
	});
};

/** Convenience function to merge an update to the progress with the current progress. */
function updateProgress(
	listener: EffectListener,
	update: (progress: IUIState['PostLoginProgress']) => void
) 
{
	listener.dispatch(setPostLoginProgress(produce(listener.getState().ui.PostLoginProgress, draft => 
	{
		update(draft);
	})));
}