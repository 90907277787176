import { createSlice } from '@reduxjs/toolkit';
import { ICompetitorObject } from '../../Models/Responses/CompetitorResponse';
import { ISeedResponse } from '../../Models/Responses/SeedResponse';
import { getSeedsForGrower, getEnogenContractCheck, getCompetitorData } from './SeedsActions';

export interface ISeedListState
{
	isError: boolean;
	isLoading: boolean;
	errorMessage: string;
	// All _available_ seeds (some availability, even if red)
	seeds: ISeedResponse[];
	// ALL seeds even if not sold in this region or are discontinued
	allSeeds: ISeedResponse[];
	competitors: { [key: string]: ICompetitorObject[] };
	isEnogenContractValid: boolean;
}

export const initialState: ISeedListState = {
	isError: false,
	isLoading: false,
	errorMessage: undefined,
	seeds: undefined,
	allSeeds: undefined,
	competitors: {},
	isEnogenContractValid: false,
};

export const seedsSlice = createSlice({
	name: 'seeds',
	initialState,
	reducers: {
		clearState: (state) => 
		{
			state.seeds = undefined;
			state.allSeeds = undefined;
			state.isError = false;
			state.isLoading = false;
			state.errorMessage = undefined;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		},
		clearSeeds: (state) =>
		{
			state.seeds = undefined;
			state.allSeeds = undefined;
			return state;
		}
	},
	extraReducers: (builder) =>
	{
		builder.addCase(getEnogenContractCheck.pending, (state, action) =>
		{
			state.isLoading = true;
		});
		builder.addCase(getEnogenContractCheck.fulfilled, (state, action) =>
		{
			state.isEnogenContractValid = action.payload;
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getEnogenContractCheck.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;

			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the Enogen contract. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = (action.payload as string);
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the Enogen contract. Please refresh and try again.';
			}

		});
		builder.addCase(getSeedsForGrower.pending, (state, action) =>
		{
			state.isLoading = true;
		});
		builder.addCase(getSeedsForGrower.fulfilled, (state, action) =>
		{
			state.allSeeds = action.payload.seeds;
			state.seeds = action.payload.seeds.map((entry) => ({
				CropId: entry.CropId,
				BrandApplications: entry.BrandApplications.map((ba) => ({
					BrandApplication: ba.BrandApplication,
					Hybrids: ba.Hybrids.filter(h => h.Availability !== 'None').map(h => ({
						...h,
						MaterialAvailability: h.MaterialAvailability.filter(m => m.Availability !== 'None')
					}))
				}))
			}));
			
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getSeedsForGrower.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			state.seeds = [];
			state.allSeeds = [];

			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of Seeds. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = (action.payload as string);
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the list of Seeds. Please refresh and try again.';
			}

		});
		builder.addCase(getCompetitorData.pending, (state, action) =>
		{
			
		});
		builder.addCase(getCompetitorData.fulfilled, (state, action) =>
		{
			state.competitors = action.payload;
			state.isError = false;
			state.errorMessage = undefined;
		});
		builder.addCase(getCompetitorData.rejected, (state, action) =>
		{
			state.isError = true;
			state.competitors = {};

			if (action.payload)
			{
				if ((action.payload as string).indexOf('network') > -1)
				{
					state.errorMessage = 'There was a problem contacting the server to get the list of Competitors. Please refresh and try again.';
				}
				else
				{
					state.errorMessage = (action.payload as string);
				}
			}
			else
			{
				state.errorMessage = 'There was a problem getting the list of Seeds. Please refresh and try again.';
			}

		});
	},
});

export const { clearState, clearError, clearSeeds } = seedsSlice.actions;