import {  } from '@reduxjs/toolkit';
import { Api } from '../../Api/Api';
import { AppDispatch, RootState } from '../Store';
import { IGrowerListRequest } from '../../Models/Requests/GrowerRequest';
import { IGrowerResponse, IContactResponse } from '../../Models/Responses/GrowerResponse';
import { getCurrentActingUser } from '../User/AuthSlice';
import { createTracedAsyncThunk } from '../../../tracing/trace';

export const getGrowers = createTracedAsyncThunk<IGrowerResponse[], IGrowerListRequest, { dispatch: AppDispatch, state: RootState }>(
	'grower/get',
	async (context, request: IGrowerListRequest, thunkAPI) => 
	{
		try
		{
			const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
			let queryParams = request.UpdateFromCropEdge || request.GrowerYears?.length ? '?' : '';
			if (request.UpdateFromCropEdge && request.GrowerYears?.length)
			{
				queryParams += `updateFromCropEdge=${request.UpdateFromCropEdge}&jsonGrowerYears=[${request.GrowerYears.toString()}]`;
			}
			else if (request.UpdateFromCropEdge)
			{
				queryParams += `updateFromCropEdge=${request.UpdateFromCropEdge}`;
			}
			else if (request.GrowerYears?.length)
			{
				queryParams += `jsonGrowerYears=[${request.GrowerYears.toString()}]`;
			}
			const response = await api.getAsync<IGrowerResponse[]>(`users/${request.UserId}/growers${queryParams}`);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);


export const getContacts = createTracedAsyncThunk<IContactResponse[], { growerId: string }, { dispatch: AppDispatch, state: RootState }>(
	'grower/contact/get',
	async (context, request, thunkAPI) => 
	{
		try
		{
			const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
			const response = await api.getAsync<IContactResponse[]>(`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/growers/${request.growerId}/contacts`);

			if (response.ErrorCode === null && response.Success) 
			{
				return response.Data;
			}
			else 
			{
				return thunkAPI.rejectWithValue(response.ErrorMessage);
			}
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

interface IInvitationResponse {
  invite_id: string;
  email: string;
  role: string;
  status: string;
  created: string;
  modified: string;
  recipient_name: string;
}

export const inviteContact = createTracedAsyncThunk<
	IInvitationResponse, 
	{ growerId: string, contactId: string, name: string, email: string }, 
	{ dispatch: AppDispatch, state: RootState }
	>(
		'grower/contact/invite',
		async (context, request, thunkAPI) => 
		{
			try
			{
				const api = new Api('/api/4', thunkAPI.getState().auth.userAuthToken, context);
				const response = await api.postAsync<IInvitationResponse>(`users/${getCurrentActingUser(thunkAPI.getState()).UserId}/growers/${request.growerId}/contacts/${request.contactId}/invitations`,
					{
						Name: request.name,
						Email: request.email
					});

				if (response.ErrorCode === null && response.Success) 
				{
					return response.Data;
				}
				else 
				{
					return thunkAPI.rejectWithValue(response.ErrorMessage);
				}
			}
			// Likely a NetError thrown from the Api class
			catch (e)
			{
				return thunkAPI.rejectWithValue(e.message);
			}
		}
	);