import React, { useState, useEffect } from 'react';
import { ReactComponent as PlusIcon } from '../../../../assets/images/PlusIcon.svg';
import { ICropBrandAssociation } from '../CropBrandAssociation';
import { ISeriesWithHybrid } from '../../../../logic/store/Seeds/SeedsActions';
import { AppDispatch, RootState } from '../../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { makeDispatch } from '../../../../logic/Utility/Utils';
import { addSeriesToEditingState, IPlanEditState } from '../../../../logic/store/Plans/ProductGamePlanSlice';
import { ScaleLoader } from 'react-spinners';
import { ConvertBrandApplicationToCropType } from '../../../../logic/Models/Responses/ProductGamePlan';
import { themeGet } from '@styled-system/theme-get';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as PullTabOpen } from '../../Icons/PullTabOpen.svg';
import { ReactComponent as PullTabClosed } from '../../Icons/PullTabClosed.svg';
import { ReactComponent as GreenAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Green.svg';
import { ReactComponent as YellowAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Yellow.svg';
import { ReactComponent as RedAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Red.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/images/AvailabilityIcons/Info.svg';
import { Modal } from 'antd';
import { SeriesAvailability } from '../SeriesAvailability';
import { ISeedFilter } from './SeedFilterModal';

const sidebarWidth = 70;
const containerOpenWidth = 320;
const containerClosedWidth = 20;


const ProductListContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: ${containerClosedWidth}px;
	background-color: ${themeGet('colors.backgroundLM')};
	transition: min-width 0.3s linear, visibility 0s 0.3s linear;
	z-index: 99;
	height: 96%;
`;

const PullTabButton = styled.div`
	position: fixed;
	cursor: pointer;
	transition: left 0.3s linear, visibility 0s 0.3s linear;
`;

const ProductListContent = styled.div`
	display: flex;
	flex-direction: column;
	transition: visibility 0s 0.3s linear;
	flex: 1 1 auto;
	overflow: hidden;
`;

const ProductListHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 28px 18px 12px 26px;
	padding: 12px 0px;
	font-family: ${themeGet('fonts.heading')};
	font-size: ${themeGet('fontSizes.small')};
	font-weight: ${themeGet('fontWeights.bold')};
	border-top: 1px solid ${themeGet('colors.lightestGrey')};
	
	.infoIcon {
		margin-left: 4px;
		
		path, circle.fill {
			fill: ${themeGet('colors.darkGrey')};
			stroke: ${themeGet('colors.darkGrey')};
		}
	}

	circle {
		stroke: ${themeGet('colors.darkGrey')};
	}
`;

const ProductListSeries = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	margin: 0px 10px 0px 30px;
	padding-right: 20px;
`;

const SeriesRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 50px;
	min-height: 50px;
	background-color: ${themeGet('colors.white')};
	border: 1px solid ${themeGet('colors.lightestGrey')};
	border-radius: 5px;
	margin-bottom: 8px;
	align-items: center;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
`;

const SeriesData = styled.div`
	color: ${themeGet('colors.darkGrey')};
	font-family: ${themeGet('fonts.base')};
	font-size: ${themeGet('fontSizes.extraSmall')};
	font-weight: ${themeGet('fontWeights.bold')};
	padding: 0px 12px;
`;

const SeriesAction = styled.div`
	height: 100%;
    display: flex;
    align-items: center;
    padding-right: 12px;
    cursor: pointer;
`;

interface IPlanProductListProps
{
	editingState: IPlanEditState;
	recommendation: ICropBrandAssociation;
	seriesList: ISeriesWithHybrid[];
	planId: string;
	defaultTreatmentName?: string;
}

const AvailabilityLegend = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: 10px;
	padding-right: 10px;
`;

/**
 * The list of selected products from within the product plan editing section
 */
function PlanProductList(props: IPlanProductListProps & PropsFromRedux)
{
	const { planId, recommendation, seriesList, editingState, defaultTreatmentName, CustomTreatments, IsLoadingSeeds, OnAddSeries, } = props;

	const theme = useTheme();

	const [visible, setVisible] = useState<boolean>(true);
	const [showAvailability, setShowAvailability] = useState<boolean>(false);
	const [filter, setFilter] = useState<{ key: string, value: Partial<ISeedFilter | undefined> }[]>([]);
	const [filteredSeriesList, setFilteredSeriesList] = useState(seriesList);
	const [filterDefaults, setFilterDefaults] = useState<Partial<ISeedFilter> | undefined>(undefined);

	useEffect(() =>
	{
		if (!filter[recommendation.title])
		{
			setFilteredSeriesList(seriesList);
			setFilterDefaults(
				seriesList.reduce((defaults, series, index) =>
				{
					const valueRM = Number.parseFloat(series.relativeMaturity);
					// if first value, return as min and max
					if (!index)
					{
						return { minRM: valueRM, maxRM: valueRM };
					}
					if (valueRM < defaults.minRM)
						defaults.minRM = valueRM;
					else if (valueRM > defaults.maxRM)
						defaults.maxRM = valueRM;
					return defaults;
					// initialize to 0 for typing
				}, { minRM: 0, maxRM: 0 })
			);
		}
		else
		{
			const { minRM, maxRM } = filter[recommendation.title];
			setFilteredSeriesList(seriesList.filter(series =>
			{
				const { relativeMaturity } = series;
				const RM = Number.parseFloat(relativeMaturity);
				let ret = true;
				if (minRM && RM < minRM)
				{
					ret = false;
				}
				if (maxRM && RM > maxRM)
				{
					ret = false;
				}
				return ret;
			}));
		}

	}, [filter, seriesList]);

	return (
		<ProductListContainer className='ProductList' style={{ minWidth: visible ? containerOpenWidth : containerClosedWidth }}>
			<PullTabButton className='PullTabButton'
				onClick={() => setVisible(!visible)}
				style={{ left: visible ? sidebarWidth + containerOpenWidth : sidebarWidth + containerClosedWidth }}
			>
				{visible ? <PullTabOpen /> : <PullTabClosed />}
			</PullTabButton>
			<Modal
				width={225}
				title='Status Legend'
				open={showAvailability}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				onOk={() => setShowAvailability(false)}
				onCancel={() => setShowAvailability(false)}
			>
				<AvailabilityLegend className='AvailabilityLegend'>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<GreenAvailabilityIcon style={{ fill: 'rgb(160, 160, 160)', position: 'relative', top: -1, marginRight: 2 }} />
							/
							<GreenAvailabilityIcon style={{ fill: theme.colors.availabilityGreen, marginLeft: 2, marginRight: 5, position: 'relative', top: -1 }} />
							Available
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<YellowAvailabilityIcon style={{ fill: 'rgb(160, 160, 160)', marginRight: 2 }} />
							/
							<YellowAvailabilityIcon style={{ fill: theme.colors.availabilityYellow, marginLeft: 2, marginRight: 5 }} />
							Limited Availability
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<RedAvailabilityIcon style={{ fill: 'rgb(160, 160, 160)', position: 'relative', top: -1, marginRight: 2 }} />
							/
							<RedAvailabilityIcon style={{ fill: theme.colors.availabilityRed, marginLeft: 2, marginRight: 5, position: 'relative', top: -1 }} />
							Unavailable
						</div>
					</div>
				</AvailabilityLegend>
			</Modal>
			<ProductListContent className='ProductListContent' style={{ visibility: visible ? 'visible' : 'hidden' }}>
				<ProductListHeader className='ProductListHeader' style={{ opacity: visible ? '1' : '0' }}>
					<div>{recommendation.title} Products</div>
					<InfoIcon className='infoIcon' onClick={() => setShowAvailability(true)} style={{ cursor: 'pointer' }} />
				</ProductListHeader>
				{
					IsLoadingSeeds ?
						<div style={{
							flexGrow: 1,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}>
							<ScaleLoader color={theme.colors.primary} loading={IsLoadingSeeds} /> 
						</div>
						: !seriesList.length && !IsLoadingSeeds ?
							<div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								There are no seeds available.
							</div>
							:
							!filteredSeriesList.length ?
								<div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', textAlign: 'center', paddingLeft: 5, paddingRight: 5 }}>
									There are no seeds available within the chosen filter.
								</div>
								:
								<ProductListSeries className='ProductListSeriesContainer'>
									<div className='ProductListSeriesInner'>
										{filteredSeriesList.map((series) => (
											<SeriesRow className='SeriesContainer' key={series.seriesId + 'FilteredSeriesList'}>
												<SeriesAvailability className='SeriesAvailability'>
													{series.availability == 'Green' ? <GreenAvailabilityIcon />
														: series.availability === 'Yellow' ? <YellowAvailabilityIcon />
															: <RedAvailabilityIcon />
													}
												</SeriesAvailability>
												<SeriesData className='SeriesName'>
													{series.seriesName}
												</SeriesData>
												<SeriesData className='SeriesRm'>
										RM: {series.relativeMaturity}
												</SeriesData>
												<div className='Spacer' style={{ flexGrow: 1 }} />
												{(!editingState?.series[series.seriesId]) && <SeriesAction className='SeriesAction'
													onClick={() => OnAddSeries({
														planId,
														cropId: recommendation.cropId,
														cropType: ConvertBrandApplicationToCropType(recommendation.brandApplication),
														series,
														defaultTreatmentName,
														customTreatments: CustomTreatments
													})}
												>
													<PlusIcon width={12} height={12} fill={theme.colors.darkGrey} />
												</SeriesAction>}
											</SeriesRow>
										))}
									</div>
								</ProductListSeries>
				}
			</ProductListContent>
		</ProductListContainer>
	);
}

const mapStateToProps = (state: RootState) => ({
	CustomTreatments: state.customTreatments.treatments,
	IsLoadingSeeds: state.seeds.isLoading
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
	OnAddSeries: makeDispatch(dispatch, addSeriesToEditingState)
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const ConnectedPlanProductList = connector(PlanProductList);
