import React from 'react';
import { TableRowType } from '../../../../components/Table/TableRows';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/Trashcan.svg';
import { ReactComponent as GreenIcon } from '../../../../assets/images/AvailabilityIcons/Green.svg';
import { ReactComponent as RedIcon } from '../../../../assets/images/AvailabilityIcons/Red.svg';
import { ReactComponent as YellowIcon } from '../../../../assets/images/AvailabilityIcons/Yellow.svg';
import { StyledRowCell } from '../../../../components/Table/TableRow';
import { IconButton } from '../../../../components/IconButton/IconButton';
import styled, { useTheme } from 'styled-components';
import { InputNumber, Tooltip } from 'antd';
import { IHybridRowItem, IUpdateHybridRowParams } from './FieldShoppingCart';
import { soyId } from '../../../../logic/store/Seeds/CropsSlice';
import { themeGet } from '@styled-system/theme-get';

const StyledButtonCell = styled(StyledRowCell)`
	height: 100%;
	margin-left: auto;
	margin-right: 0;
	border-left: 1px solid;
	border-left-color: ${themeGet('colors.lightestGrey')};
	svg {
		position: inherit;
	}
`;
const StyledIconButton = styled(IconButton)`
	border-radius: 25px;
	height: 80%;
	@media (hover: none) {
		width: 35px;
	}
	@media (hover: hover) {
		width: 40px;
	}
	path {
		fill: ${themeGet('colors.darkGrey')};
	}
`;

const StyledInputNumber = styled(InputNumber)`
	width: 50px;
	margin: auto;
	.ant-input-number-input {
		padding: 0;
		text-align: center;
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	input
	{
		font-family: 'Roboto';
		font-size: 12px;
		height: 14px;
		border-bottom: 1px black solid;
	}
`;

interface IButtonCellProps extends IHybridRowItem
{
	onClickDelete: (hybridId: string, treatment: string, customTreatmentId?: string) => void;
}
const ButtonCell = (props: IButtonCellProps) =>
{
	const { Id, Treatment, CustomTreatmentId, onClickDelete } = props;

	const theme = useTheme();

	return (
		<StyledButtonCell className='product-quote-button-section'>
			<StyledIconButton className='delete-button' onClick={() => onClickDelete(Id, Treatment, CustomTreatmentId)}
				tooltip='Delete'
				placement='bottom'
				color={theme.colors.redLM}
				hoverbgcolor={theme.colors.lightestGrey}>
				<DeleteIcon />
			</StyledIconButton>
		</StyledButtonCell>
	);
};

interface IParentHybridRows 
{
	data: IHybridRowItem[];
	onClickDelete: (hybridId: string, treatment: string, customTreatmentId?: string) => void;
	updateValues: (params: IUpdateHybridRowParams) => void;
}

export const HybridParentRows = (props: IParentHybridRows): TableRowType<IHybridRowItem>[] =>
{
	const { data, onClickDelete, updateValues } = props;

	const theme = useTheme();

	const determineAvailabilityIcon = (availability: string) =>
	{
		switch(availability)
		{
			case 'Green' :
				return (
					<GreenIcon style={{ fill: theme.colors.availabilityGreen }} />
				);
			case 'Red' :
				return (
					<RedIcon style={{ fill: theme.colors.availabilityRed }} />
				);
			case 'Yellow' :
				return (
					<YellowIcon style={{ fill: theme.colors.availabilityYellow }} />
				);
			default :
				return (
					<YellowIcon style={{ fill: 'transparent' }} />
				);
		}
	};

	return data.map((item: IHybridRowItem, index) =>
	{
		const keyPrefix = `fieldquote-cell-${item.HybridName}-${index}`;

		const paperBagsDisabled = !(item.AvailablePackaging.findIndex(ap => ap.PackageType === 'PB') > -1);
		const cubitsDisabled = !(item.AvailablePackaging.findIndex(ap => ap.PackageType === 'QB') > -1);
		const toteBagDisabled = !(item.AvailablePackaging.findIndex(ap => ap.PackageType === 'TO') > -1);
		const truBulkDisabled = !(item.AvailablePackaging.findIndex(ap => ap.PackageType === 'TB') > -1);

		const updateAndSave = (update: Partial<IHybridRowItem>) => 
		{
			const parameters: IUpdateHybridRowParams =
			{
				BrandName: item.BrandName,
				CropId: item.CropId,
				CustomTreatmentId: item.CustomTreatmentId ? item.CustomTreatmentId : undefined,
				HybridId: item.Id,
				TreatmentName: item.Treatment,
				UpdateItem: update
			};
			updateValues(parameters);
		};

		return {
			data: item,
			items: [
				<div key={`${keyPrefix}-hybrid`}>{item.HybridName}</div>,
				<div key={`${keyPrefix}-seedTreatment`} className={`${keyPrefix}-seedTreatment`} style={{ paddingLeft: 15 }}>{item.Treatment}</div>,
				<Tooltip
					title={paperBagsDisabled ? 'Paperbags not available for this treatment.' : ''}
					placement='top'
					key={`${keyPrefix}-paperbags-tooltip`}
					className={`${keyPrefix}-paperbags-tooltip`}
				>
					{determineAvailabilityIcon(item.AvailablePackaging.find(ap => ap.PackageType === 'PB')?.Availabililty)}
					<span style={{ cursor: paperBagsDisabled ? 'not-allowed' : 'auto', textAlign: 'center', paddingLeft: 10, paddingRight: 10 }}>
						<StyledInputNumber
							disabled={paperBagsDisabled}
							type='number'
							key={`${keyPrefix}-paperbags`}
							className={`${keyPrefix}-paperbags`}
							min={0}
							step={1}
							value={item.QuantityPB}
							bordered={false}
							onPressEnter={(evt) => updateAndSave({ QuantityPB: (evt.target as HTMLInputElement).value ?
								Number((evt.target as HTMLInputElement).value) : item.QuantityPB })}
							onBlur={(evt) => updateAndSave({ QuantityPB: evt.currentTarget.value ?
								Number(evt.currentTarget.value) : item.QuantityPB })}
						/></span></Tooltip>,
				<Tooltip
					title={cubitsDisabled ? 'QBit not available for this treatment.' : ''}
					placement='top'
					key={`${keyPrefix}-cubits-tooltip`}
					className={`${keyPrefix}-cubits-tooltip`}
				>
					{determineAvailabilityIcon(item.AvailablePackaging.find(ap => ap.PackageType === 'QB')?.Availabililty)}
					<span style={{ cursor: cubitsDisabled ? 'not-allowed' : 'auto', textAlign: 'center', paddingLeft: 10, paddingRight: 10 }}>
						<StyledInputNumber
							disabled={cubitsDisabled}
							type='number'
							key={`${keyPrefix}-cubits`}
							className={`${keyPrefix}-cubits`}
							min={0}
							step={1}
							value={item.QuantityQB}
							bordered={false}
							onPressEnter={(evt) => updateAndSave({ QuantityQB: (evt.target as HTMLInputElement).value ?
								Number((evt.target as HTMLInputElement).value) : item.QuantityQB })}
							onBlur={(evt) => updateAndSave({ QuantityQB: evt.currentTarget.value ?
								Number(evt.currentTarget.value) : item.QuantityQB })}
						/></span></Tooltip>,
				item.CropId.toLowerCase() === soyId ?
					<Tooltip
						title={toteBagDisabled ? 'Totebags not available for this treatment.' : ''}
						placement='top'
						key={`${keyPrefix}-totebags-tooltip`}
						className={`${keyPrefix}-totebags-tooltip`}
					>
						{determineAvailabilityIcon(item.AvailablePackaging.find(ap => ap.PackageType === 'TO')?.Availabililty)}
						<span style={{ cursor: toteBagDisabled ? 'not-allowed' : 'auto', textAlign: 'center', paddingLeft: 10, paddingRight: 10 }}>
							<StyledInputNumber
								disabled={toteBagDisabled}
								type='number'
								key={`${keyPrefix}-totebags`}
								className={`${keyPrefix}-totebags`}
								min={0}
								step={1}
								value={item.QuantityTO}
								bordered={false}
								onPressEnter={(evt) => updateAndSave({ QuantityTO: (evt.target as HTMLInputElement).value ?
									Number((evt.target as HTMLInputElement).value) : item.QuantityTO })}
								onBlur={(evt) => updateAndSave({ QuantityTO: evt.currentTarget.value ?
									Number(evt.currentTarget.value) : item.QuantityTO })}
							/>
						</span></Tooltip>
					: React.Fragment,
				item.CropId.toLowerCase() === soyId ?
					<Tooltip
						title={truBulkDisabled ? 'TruBulk not available for this treatment.' : ''}
						placement='top'
						key={`${keyPrefix}-trubulk-tooltip`}
						className={`${keyPrefix}-trubulk-tooltip`}
					>
						{determineAvailabilityIcon(item.AvailablePackaging.find(ap => ap.PackageType === 'TB')?.Availabililty)}
						<span style={{ cursor: truBulkDisabled ? 'not-allowed' : 'auto', textAlign: 'center', paddingLeft: 10, paddingRight: 10 }}>
							<StyledInputNumber
								disabled={truBulkDisabled}
								type='number'
								key={`${keyPrefix}-trubulk`}
								className={`${keyPrefix}-trubulk`}
								min={0}
								step={1}
								value={item.QuantityTB}
								bordered={false}
								onPressEnter={(evt) => updateAndSave({ QuantityTB: (evt.target as HTMLInputElement).value ?
									Number((evt.target as HTMLInputElement).value) : item.QuantityTB })}
								onBlur={(evt) => updateAndSave({ QuantityTB: evt.currentTarget.value ?
									Number(evt.currentTarget.value) : item.QuantityTB })}
							/>
						</span></Tooltip>
					: React.Fragment,
				<div style={{ textAlign: 'center' }} key={`${keyPrefix}-totalamtrequired`} className={`${keyPrefix}-totalamtrequired`}>
					{`${item.TotalBags} / ${item.AmtRequired}`}
				</div>,
				<ButtonCell
					key={`${keyPrefix}-buttons`}
					{...item}
					onClickDelete={onClickDelete}
				/>,
			]
		};
	});
};