export enum UserPermission
{
	CanManageAnyAccount = 'CanImpersonate',
	CanSeePricing = 'Allow:Pricing',
	UseGhxBranding = 'Branding:GHX',
	CanRecord = 'Allow:Record',
	CanSeeTroubleShootingTools = 'Expose troubleshooting tools',
	CanSeeProductPlanRecommendations = 'Allow:ProductPlanRecommendation',
	CropEdgeSSO = 'Allow:CropEdgeSSO',
	GhxCoreSSO = 'Allow:SSO:core',
	CanCreateGrowers = 'User can create growers',
	IsGrower = 'Allow:Grower UI Access',
	CanAccessEnogen = 'Enogen Dealer',
	CanSeeEnogenBrand = 'Allow:Brand:Enogen',
	CanSeeEnogenTheme = 'Theme:Enogen',
	CanSeeCompetitiveAllowance = 'Allow:Competitive Allowance'
}