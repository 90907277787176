import React, { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Button } from '../../../../components/Button/Button';
import { TableHeader } from '../../../../components/Table/TableHeader';
import { cropBrandAssociations, ICropBrandAssociation } from '../../ProductGamePlan/CropBrandAssociation';
import { Divider } from 'antd';
import { AppDispatch, RootState } from '../../../../logic/store/Store';
import { getDraftQuotes, IQuoteRequestLineUpdate, ISeedAssignmentUpdateRequest, ISendQuoteRequest, sendQuote } from '../../../../logic/store/Grower/QuoteThunks';
import { connect, ConnectedProps } from 'react-redux';
import { IGrowerResponse } from '../../../../logic/Models/Responses/GrowerResponse';
import { IDraftQuoteResponse } from '../../../../logic/Models/Responses/DraftQuoteResponse';
import { getSeedsForGrower, getEnogenContractCheck } from '../../../../logic/store/Seeds/SeedsActions';
import { dynamicSort, makeDispatch } from '../../../../logic/Utility/Utils';
import { calculateTotalBags, ISelectableTreatment, setupTreatments } from '../Shared/QuoteUtils';
import { StyledModal } from '../../../../components/StyledModal/StyledModal';
import { CropConfig, ICropConfig, soyId } from '../../../../logic/store/Seeds/CropsSlice';
import { StyledTableRows, TableRowType } from '../../../../components/Table/TableRows';
import { HybridParentRows } from './HybridParentRows';
import { FieldHybridRows } from './FieldQuoteRows';
import { TableRow } from '../../../../components/Table/TableRow';
import { clearGamePlanLaunchUrl } from '../../../../logic/store/Grower/GrowerSlice';
import { getCustomTreatments } from '../../../../logic/store/User/CustomTreatmentsActions';
import { ShoppingCartHeader } from '../Shared/ShoppingCartHeader';
import { getShipFromAddresses } from '../../../../logic/store/User/UserInfoSlice';
import { hasEffectivePermission, getCurrentActingUser } from '../../../../logic/store/User/AuthSlice';
import { UserPermission } from '../../../../logic/Models/Responses/UserPermissions';
import { currentEnogenFlowUrl, displayEnogenFlow, hideEnogenFlow, setSelectedPlanId } from '../../../../logic/store/UI/UISlice';
import { IFrameModal } from '../../../../components/IFrameModal/IFrameModal';
import { CoveringLoader } from '../../../../components/LoadingSpinner/CoveringLoader';
import { ScaleLoader } from 'react-spinners';

const StyledQuoteContainer = styled.div`
	display: flex;
	width: 100%;
	padding-right: 5%;
	padding-left: 5%;
	padding-bottom: 2.5%;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
`;

const QuoteStyledTableRows = styled(StyledTableRows)`
	padding-top: 1.5%;
	padding-bottom: 1.5%;
`;

const StyledTableRow = styled(TableRow)`
	padding: 0 1.5%;
	height: 50px;
	overflow: hidden;
`;

const cornHeaderColumns: string[] =
	[
		'Hybrid',
		'Seed Treatment',
		'Paper Bag(s)',
		'QBit(s)',
		'Total Bags / Amt Required'
	];

// Value for each column width
const cornSpacing = '20% 32% 12% 12% 21%';

const soyHeaderColumns: string[] =
	[
		'Variety',
		'Seed Treatment',
		'Paper Bag(s)',
		'QBit(s)',
		'Tote Bag',
		'TruBulk',
		'Total Bags / Amt Required'
	];

// Value for each column width
const soySpacing = '10% 29% 10% 10% 10% 10% 18%';

const fieldListHeaderColumns: string[] =
	[
		'Field',
		'Product',
		'Seed Treatment',
		'Rate',
		'Applied Acres',
		'Bags Needed'
	];

// Value for each column width
const fieldListHeaderSpacing = '18% 15% 25% 13% 13% 13%';
const fieldListChildSpacing = '10% 8% 40% 13% 13% 13%';

export interface IHybridRow
{
	[key: string]: IHybridRowItem; // key = hybridId + treatment
}

export interface IFarmSection
{
	[key: string]: IFarmItem;	// FarmId is the key
}

export interface IHybridRowItemAvailability
{
	PackageType: string;
	Availabililty: string;
}

export interface IHybridRowItem
{
	AmtRequired: number; // Total of all FieldRows bags needed
	AvailablePackaging: IHybridRowItemAvailability[]; // Packaging available for this Treatment
	BrandName: string;
	CropId: string;
	CustomTreatmentId?: string;
	Id: string; // Hybrid Id
	HybridName: string;
	QuantityPB: number;
	QuantityQB: number;
	QuantityTB: number;
	QuantityTO: number;
	QuoteLineId: string;
	RM: number;
	TotalBags: number;
	Treatment: string;
}

export interface IFarmItem
{
	FarmName: string;
	FarmId: string;

	FieldRows: { [key: string]: IFieldRow }; // key = hybridId + fieldId
}

export interface IFieldRow
{
	AppliedAcres: number;
	BagsNeeded: number;
	BrandName: string;
	CropId: string;
	FarmId: string;
	FieldName: string;
	HybridName: string;
	HybridId: string;
	Id: string; // Field Id
	Rate: number;
	RM: number;
	Selected: boolean;
	Treatments: ISelectableTreatment[];

	ChildRows?: { [key: string]: IFieldRow }; // key = hybridId + treatment
}

export interface IUpdateHybridRowParams
{
	BrandName: string;
	CropId: string;
	CustomTreatmentId: string;
	HybridId: string;
	TreatmentName: string;
	UpdateItem: Partial<IHybridRowItem>;
}

export interface IUpdateFieldRowParams
{
	BrandName: string;
	CropId: string;
	CustomTreatmentId: string;
	FarmId: string;
	FieldId: string;
	HybridId: string;
	TreatmentName: string;
	UpdateItem: Partial<IFieldRow>;
}

interface IFieldQuoteProps extends PropsFromRedux
{
	selectedGrower: IGrowerResponse;
	selectedPlanId: string;
}

const FieldQuoteComponent = (props: IFieldQuoteProps) =>
{
	const {
		AccessToken,
		CurrentCropYear,
		CurrentUserId,
		CustomTreatments,
		EnogenFlowUrl,
		EnogenRootUrl,
		isGHX,
		IsLoadingGetTreatments,
		IsLoadingPlanLaunch,
		IsLoadingQuotes,
		IsLoadingSeedsForGrower,
		IsQuoteError,
		Seeds,
		selectedGrower,
		selectedPlanId,
		ShipFromAddresses,
		UISelectedPlanId,
		ClearLaunchUrl,
		DisplayEnogenFlow,
		GetCustomTreatments,
		GetEnogenContractCheck,
		GetQuotes,
		GetSeedsForGrower,
		GetShipFromAddresses,
		HideEnogenFlow,
		SendQuote,
		SetSelectedPlanId,
	} = props;

	const theme = useTheme();

	const [currentRecommendation, setCurrentRecommendation] = useState<ICropBrandAssociation>(cropBrandAssociations[0]);
	const [currentQuote, setCurrentQuote] = useState<IDraftQuoteResponse>(undefined);
	const [quoteLinesToSeeds, setQuoteLinesToSeeds] = useState<{ [key: string]: IHybridRow }>({});
	const [farmFieldToSeeds, setFarmFieldToSeeds] = useState<{ [key: string]: IFarmSection }>({});
	const [quoteRetrieved, setQuoteRetrieved] = useState<boolean>(false);
	const [openSendModal, setOpenSendModal] = useState<boolean>(false);
	const [openEnogenContractModal, setOpenEnogenContractModal] = useState<boolean>(false);
	const [isResetQuote, setIsResetQuote] = useState<boolean>(false);
	const [openResetModal, setOpenResetModal] = useState<boolean>(false);
	const [saveSendmodalTitle, setSaveSendmodalTitle] = useState<string>('');
	const [addressSelection, setAddressSelection] = useState<string>(undefined);
	const [sendType] = useState<string>(isGHX ? 'Order' : 'Proposal');
	const [disabledSaveTooltip, setDisabledSaveTooltip] = useState<string>('');
	const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

	useEffect(() =>
	{
		if (!quoteRetrieved)
		{
			GetQuotes({});
			if (!Seeds && !IsLoadingSeedsForGrower)
			{
				GetSeedsForGrower();
			}
			GetCustomTreatments();
			GetShipFromAddresses();
			setQuoteRetrieved(true);
		}
	}, [IsLoadingQuotes, IsLoadingSeedsForGrower]);

	useEffect(() =>
	{
		if (!IsLoadingQuotes && quoteRetrieved && selectedGrower && selectedGrower.DraftQuotes)
		{
			// The location-state value of selectedPlanId will vanish after a certin amount of 'sends' or 'saves'
			// try to use the store based value if this happens
			const planId = selectedPlanId ? selectedPlanId : UISelectedPlanId;

			// TODO: Make this more robust, if it does not find a quote pop an error? Show a different view?
			setCurrentQuote(selectedGrower.DraftQuotes.find(q => q.PlanId === planId && q.IsActive));

			// Only store the plan ID if we do not currently have it
			if (!UISelectedPlanId)
			{
				SetSelectedPlanId(selectedPlanId);
			}
		}
	}, [IsLoadingQuotes, quoteRetrieved, selectedGrower, selectedGrower?.DraftQuotes]);

	useEffect(() =>
	{
		if (!IsLoadingSeedsForGrower && !IsLoadingQuotes && !IsLoadingGetTreatments
			&& !IsQuoteError && Seeds && currentQuote && quoteRetrieved || isResetQuote)
		{
			// If this is a reset, be sure to clear the reset flag
			setIsResetQuote(false);

			const allGrowerFields = selectedGrower.Farms.flatMap(fa => fa.Fields);
			const allGrowerFarms = selectedGrower.Farms;
			const allSeeds = Seeds.flatMap(s => s.BrandApplications.flatMap(ba => ba.Hybrids));

			const newFarmFieldToSeeds: { [key: string]: IFarmSection } = {};

			// Field Hybrids
			currentQuote.SeedAssignments.map(sa => 
			{
				const foundSeed = allSeeds.find(s => s.Id === sa.HybridId);
				if (foundSeed)
				{
					const { BrandName, CropId, MaterialAvailability } = foundSeed;
					const cropConfig: ICropConfig = CropConfig()[CropId];
					const { seedsPerBag } = cropConfig;
					const bagsNeeded: number = Math.ceil((sa.Rate * sa.AppliedAcres) / seedsPerBag);
					const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
					const key: string = CropId + brandApplication;
					let cropCustomTreatments = CustomTreatments;
					if (CropId !== soyId)
					{
						cropCustomTreatments = [];
					}
					const seedTreatment: ISelectableTreatment[] = setupTreatments(MaterialAvailability, cropCustomTreatments);

					const farmItems: { [key: string]: IFarmItem } = newFarmFieldToSeeds[key] ? newFarmFieldToSeeds[key] : {};

					// Find the field -- if it is not in the current list of fields, bail out
					const foundField = allGrowerFields.find(f => f.Id === sa.FieldId);
					if (!foundField)
					{
						return;
					}

					// Find the farm -- if it is not in the current list of farms, bail out
					const foundFarm = allGrowerFarms.find(fa => fa.Id === foundField.FarmId);
					if (!foundFarm)
					{
						return;
					}

					const fieldRow: IFieldRow =
					{
						AppliedAcres: sa.AppliedAcres,
						BrandName: BrandName,
						CropId: CropId,
						BagsNeeded: bagsNeeded,
						FarmId: foundFarm.Id,
						FieldName: foundField.Name,
						HybridId: sa.HybridId,
						HybridName: sa.HybridName,
						Id: foundField.Id, // Field Id
						Rate: Math.round(sa.Rate ?? 0),
						RM: Number.parseFloat(foundSeed.RelativeMaturity),
						Selected: true,
						Treatments: seedTreatment
					};

					// If this is a custom treatment the ID is used instead of the name in the key
					const treatmentKey = sa.CustomTreatmentId ? sa.CustomTreatmentId : sa.Treatment.toLocaleLowerCase();

					if (fieldRow.Treatments.length > 1)
					{
						const copiedItem = { ...fieldRow };
						// Get the single treatment from the Quote Line
						copiedItem.Treatments = fieldRow.Treatments.filter(st => 
							st.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase() || st.CustomTreatmentId === treatmentKey);
						copiedItem.Treatments[0].Selected = true;

						fieldRow.Treatments.find(st => st.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase()
							|| st.CustomTreatmentId === treatmentKey).Selected = true;

						// Make a child row from the quote line treatment
						fieldRow.ChildRows = {};
						fieldRow.ChildRows[sa.HybridId + treatmentKey] = copiedItem;
					}

					// Add to the farm
					if (farmItems[foundFarm.Id])
					{
						if (farmItems[foundFarm.Id].FieldRows[foundField.Id + sa.HybridId])
						{
							// We must have a secondary treatment row to add for the field
							// Select the treatment in the treatment list
							farmItems[foundFarm.Id].FieldRows[foundField.Id + sa.HybridId].Treatments.find(st =>
								st.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase() || st.CustomTreatmentId === treatmentKey).Selected = true;
								
							farmItems[foundFarm.Id].FieldRows[foundField.Id + sa.HybridId].ChildRows[sa.HybridId + treatmentKey] =
								fieldRow.ChildRows[sa.HybridId + treatmentKey];
						}
						else
						{
							// Add the field/hybrid row
							farmItems[foundFarm.Id].FieldRows[foundField.Id + sa.HybridId] = fieldRow;
						}
					}
					else
					{
						// Add the farm
						farmItems[foundFarm.Id] = {
							FarmName: foundFarm.Name,
							FarmId: foundFarm.Id,
							FieldRows: { [foundField.Id + sa.HybridId]: fieldRow }
						};
					}

					if (newFarmFieldToSeeds[key])
					{
						newFarmFieldToSeeds[key] = { ...newFarmFieldToSeeds[key], ...farmItems };
					}
					else
					{
						newFarmFieldToSeeds[key] = {};
						newFarmFieldToSeeds[key] = { ...farmItems };
					}
				}
			});

			// Get all the seed assignments from the rows
			const allFarmSections = Object.values(newFarmFieldToSeeds);
			let allFarmItems: IFarmItem[] = [];
			allFarmSections.forEach(fs => allFarmItems = allFarmItems.concat(Object.values(fs)));
			const allFieldItems: IFieldRow[] = allFarmItems.flatMap(fa => Object.values(fa.FieldRows));

			// Use the Seeds list to identify the Quoteline items - they do not have a Crop on them so search
			// them all!
			const newQuoteLinesToSeeds: { [key: string]: IHybridRow } = {};
			currentQuote.QuoteLines.map(ql =>
			{
				const foundSeed = allSeeds.find(s => s.Id === ql.HybridId);

				if (foundSeed)
				{
					const { BrandName, CropId, MaterialAvailability } = foundSeed;
					const cropConfig: ICropConfig = CropConfig()[CropId];
					const { seedsPerBag } = cropConfig;
					const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
					const key: string = CropId + brandApplication;
					let cropCustomTreatments = CustomTreatments;
					if (CropId !== soyId)
					{
						cropCustomTreatments = [];
					}

					// If this is a custom treatment the ID is used instead of the name in the key
					const treatmentKey = ql.CustomTreatmentId ? ql.CustomTreatmentId : ql.Treatment.toLocaleLowerCase();

					const seedTreatment: ISelectableTreatment[] = setupTreatments(MaterialAvailability, cropCustomTreatments);
					const foundFieldHybrids: IFieldRow[] = allFieldItems.filter(f => f.HybridId === ql.HybridId);
					let amtRequired: number = 0;

					foundFieldHybrids.forEach(ff =>
					{
						if (!ff.ChildRows)
						{
							amtRequired += (ff.Rate * ff.AppliedAcres) / seedsPerBag;
						}
						else
						{
							const childHybridRow = ff.ChildRows[ql.HybridId + treatmentKey];
							if (childHybridRow)
							{
								amtRequired += (childHybridRow.Rate * childHybridRow.AppliedAcres) / seedsPerBag;
							}
						}
					});

					amtRequired = Math.ceil(amtRequired);

					const packageAvailability = seedTreatment.find(s => s.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase()
					|| s.CustomTreatmentId === treatmentKey).PackagingAvailability;

					// Parent Hybrids -- displayed at the top of the page
					const parentRowItem: IHybridRowItem =
					{
						AmtRequired: amtRequired ?? 0,
						AvailablePackaging: packageAvailability,
						BrandName: BrandName,
						CropId: CropId,
						CustomTreatmentId: ql.CustomTreatmentId ? ql.CustomTreatmentId : undefined,
						Id: ql.HybridId,
						HybridName: ql.HybridName,
						QuantityPB: ql.QuantityPB ?? 0,
						QuantityQB: ql.QuantityQB ?? 0,
						QuantityTB: ql.QuantityTB ?? 0,
						QuantityTO: ql.QuantityTO ?? 0,
						QuoteLineId: ql.Id,
						RM: Number.parseFloat(foundSeed.RelativeMaturity),
						TotalBags: calculateTotalBags(ql.QuantityQB, ql.QuantityPB, ql.QuantityTB, ql.QuantityTO, CropId),
						Treatment: ql.Treatment
					};

					// See if our crop/brand application exists on the dictionary -- add it if it does not
					if (newQuoteLinesToSeeds[key])
					{
						newQuoteLinesToSeeds[key] = { ...newQuoteLinesToSeeds[key], [parentRowItem.Id + treatmentKey]: parentRowItem };
					}
					else
					{
						newQuoteLinesToSeeds[key] = {};
						newQuoteLinesToSeeds[key] = { [parentRowItem.Id + treatmentKey]: parentRowItem };
					}
				}
			});

			setQuoteLinesToSeeds({ ...newQuoteLinesToSeeds });
			setFarmFieldToSeeds({ ...newFarmFieldToSeeds });
		}
	}, [IsLoadingSeedsForGrower, IsLoadingQuotes, isResetQuote, currentQuote, quoteRetrieved, Seeds]);

	useEffect(() =>
	{
		if (!IsLoadingPlanLaunch && selectedGrower.GamePlanLaunchUrl)
		{
			const launchUrl = selectedGrower.GamePlanLaunchUrl;
			// Clean the url so that on a return it will not continually launch the url over and over
			ClearLaunchUrl(selectedGrower.Id);
			window.location.href = launchUrl;
		}
	}, [IsLoadingPlanLaunch, selectedGrower?.GamePlanLaunchUrl]);

	// Deletes a field row
	const onDeleteFieldRow = (farmId: string, fieldId: string, hybridId: string) =>
	{
		const currentFarmFieldToSeeds = farmFieldToSeeds;
		const currentQuoteLinesToSeeds = quoteLinesToSeeds;
		const allSeeds = Seeds.flatMap(s => s.BrandApplications.flatMap(ba => ba.Hybrids));
		const foundSeed = allSeeds.find(s => s.Id === hybridId);

		if (foundSeed)
		{
			const { CropId, BrandName } = foundSeed;
			const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
			const key: string = CropId + brandApplication;
			const cropConfig: ICropConfig = CropConfig()[CropId];
			const { seedsPerBag } = cropConfig;

			// Get all the seed assignments from the rows -- need these to recalculate the AmtRequired on the
			// Hybrid/Parent row
			const allFarmSections = Object.values(farmFieldToSeeds);
			let allFarmItems: IFarmItem[] = [];
			allFarmSections.forEach(fs => allFarmItems = allFarmItems.concat(Object.values(fs)));
			const allFieldItems: IFieldRow[] = allFarmItems.flatMap(fa => Object.values(fa.FieldRows));

			const fieldToDelete = currentFarmFieldToSeeds[key][farmId].FieldRows[fieldId + hybridId];

			// Whether or not this hybrid was the last of it's kind in the field list
			const otherHybridFieldsExist = allFieldItems.filter(f => f.Id !== fieldId).some(f => f.HybridId === hybridId);

			if (!otherHybridFieldsExist)
			{
				// If this is the last of this specific Hybrid in the list of Fields, delete the parent rows
				const parentRowsKeys = Object.keys(currentQuoteLinesToSeeds[key]);
				parentRowsKeys.forEach(pk =>
				{
					if (pk.startsWith(hybridId))
					{
						delete currentQuoteLinesToSeeds[key][pk];
					}
				});

				// Delete the field
				delete currentFarmFieldToSeeds[key][farmId].FieldRows[fieldId + hybridId];
			}
			else if (fieldToDelete.ChildRows)
			{
				const childRows = Object.values(fieldToDelete.ChildRows);
				childRows.forEach(cr =>
				{
					// If this is a custom treatment the ID is used instead of the name in the key
					const treatmentKey = cr.Treatments[0].CustomTreatmentId ? cr.Treatments[0].CustomTreatmentId : cr.Treatments[0].Treatment.toLocaleLowerCase();

					const rowBagsNeeded: number = Math.ceil((cr.Rate * cr.AppliedAcres) / seedsPerBag);

					const currentLineToUpdate: IHybridRowItem = currentQuoteLinesToSeeds[key][hybridId + treatmentKey];
					if (currentLineToUpdate.AmtRequired > 0)
					{
						currentLineToUpdate.AmtRequired -= rowBagsNeeded;
					}
				});

				// Delete the field
				delete currentFarmFieldToSeeds[key][farmId].FieldRows[fieldId + hybridId];
			}
			else
			{
				// If this is a custom treatment the ID is used instead of the name in the key
				const treatmentKey = fieldToDelete.Treatments[0].CustomTreatmentId
					? fieldToDelete.Treatments[0].CustomTreatmentId : fieldToDelete.Treatments[0].Treatment.toLocaleLowerCase();

				const rowBagsNeeded: number = Math.ceil((fieldToDelete.Rate * fieldToDelete.AppliedAcres) / seedsPerBag);

				const currentLineToUpdate: IHybridRowItem = currentQuoteLinesToSeeds[key][hybridId + treatmentKey];
				if (currentLineToUpdate.AmtRequired > 0)
				{
					currentLineToUpdate.AmtRequired -= rowBagsNeeded;
				}

				// Delete the field
				delete currentFarmFieldToSeeds[key][farmId].FieldRows[fieldId + hybridId];
			}

			setQuoteLinesToSeeds({ ...currentQuoteLinesToSeeds });

			setFarmFieldToSeeds({ ...currentFarmFieldToSeeds });
		}
	};

	const resetQuote = () =>
	{
		setIsResetQuote(true);
		setOpenResetModal(false);
		setQuoteLinesToSeeds({});
	};

	const saveOrSend = async (send: boolean) =>
	{
		setSaveSendmodalTitle(send ? 'Send' : 'Save');

		// If the user is GHX and they have Enogen seeds ask them to see if they did their Enogen contract before sending
		const enogenProductIndex = Object.keys(quoteLinesToSeeds).findIndex(k => k.endsWith('Enogen'));
		if (isGHX && enogenProductIndex > -1 && send)
		{
			// Calculate the number of Enogen product bags
			const key = Object.keys(quoteLinesToSeeds)[enogenProductIndex];
			const row = quoteLinesToSeeds[key];
			const allBagValues = Object.values(row).flatMap(x => x.TotalBags);
			const sumBags = allBagValues.reduce((sum, next) => sum + next, 0);

			const isEnogenContractValid = await fetchEnogenContractStatus(sumBags);
			
			if (isEnogenContractValid === null)
			{
				setOpenSendModal(false);
				setOpenEnogenContractModal(false);
			}
			else if (isEnogenContractValid)
			{
				setOpenSendModal(true);
			}
			else
			{
				setOpenEnogenContractModal(true);
			}
		}
		else
		{
			setOpenSendModal(true);
		}
	};

	const fetchEnogenContractStatus = async (totalBags: number) =>
	{
		const contractStatus = (await GetEnogenContractCheck({ cropYear: CurrentCropYear, totalBags: totalBags.toString() }));
		if (contractStatus.error)
		{
			return null;
		}
		return contractStatus.payload as boolean;
	};

	const sendQuote = (send: boolean) =>
	{
		const convertedLines: IQuoteRequestLineUpdate[] = [];
		const convertedSeedAssignments: ISeedAssignmentUpdateRequest[] = [];

		// Get all the seed assignments from the rows
		const allFarmSections = Object.values(farmFieldToSeeds);
		let allFarmItems: IFarmItem[] = [];
		allFarmSections.forEach(fs => allFarmItems = allFarmItems.concat(Object.values(fs)));
		const allFieldItems: IFieldRow[] = allFarmItems.flatMap(fa => Object.values(fa.FieldRows));

		// Get all the quote lines from the rows
		const allHybridRows = Object.values(quoteLinesToSeeds);
		let allHybridRowItems: IHybridRowItem[] = [];
		allHybridRows.forEach(hr => allHybridRowItems = allHybridRowItems.concat(Object.values(hr)));

		// Loop through the Hybrid Rows and make the QuoteLineRequests
		allHybridRowItems.forEach((ql: IHybridRowItem) =>
		{
			// If this is a custom treatment the ID is used instead of the name in the key
			const treatmentKey = ql.CustomTreatmentId ? ql.CustomTreatmentId : ql.Treatment.toLocaleLowerCase();

			// Search the currentQuote for this HybridId/Treatment combination to see if it is new or not
			const existingHybridCombo = currentQuote.QuoteLines.find(quoteLine => 
				quoteLine.HybridId === ql.Id && (quoteLine.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase()
					|| quoteLine.CustomTreatmentId === treatmentKey));

			const updatedLine: IQuoteRequestLineUpdate =
			{
				AppliedAcres: existingHybridCombo ? existingHybridCombo.AppliedAcres : 0,
				CustomTreatmentId: ql.CustomTreatmentId,
				HybridId: ql.Id,
				Id: existingHybridCombo ? existingHybridCombo.Id : null,
				QuantityPB: ql.QuantityPB ?? 0,
				QuantityQB: ql.QuantityQB ?? 0,
				QuantityTB: ql.QuantityTB ?? 0,
				QuantityTO: ql.QuantityTO ?? 0,
				Rate: existingHybridCombo ? Math.round(existingHybridCombo.Rate) : 0,
				Treatment: ql.Treatment
			};

			convertedLines.push(updatedLine);
		});

		allFieldItems.forEach((fieldItem: IFieldRow) =>
		{
			// If more than one seed treatment, check children
			if (fieldItem.Treatments.length > 1)
			{
				if (fieldItem.ChildRows)
				{
					const allChildren = Object.values(fieldItem.ChildRows);
					allChildren.forEach(ctr =>
					{
						// If this is a custom treatment the ID is used instead of the name in the key
						const treatmentKey = ctr.Treatments[0].CustomTreatmentId ? ctr.Treatments[0].CustomTreatmentId : ctr.Treatments[0].Treatment.toLocaleLowerCase();

						// Find the existing field/hybrid combo
						const existingFieldWithHybrid = currentQuote.SeedAssignments.find(sa =>
							sa.HybridId === ctr.HybridId && sa.FieldId === ctr.Id);

						// Search the currentQuote for this HybridId/Treatment combination to see if it is new or not
						const existingFieldHybridTreatment = currentQuote.SeedAssignments.find(sa =>
							sa.HybridId === ctr.HybridId && sa.FieldId === ctr.Id &&
							(sa.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase() || sa.CustomTreatmentId === treatmentKey));
						
						const updatedAssignment: ISeedAssignmentUpdateRequest =
						{
							AppliedAcres: ctr.AppliedAcres ?? 0,
							CustomTreatmentId: ctr.Treatments[0].CustomTreatmentId,
							FieldId: existingFieldWithHybrid.FieldId,
							HybridId: ctr.HybridId,
							Id: existingFieldHybridTreatment ? existingFieldHybridTreatment.Id : null,
							SeedingRate: Math.round(ctr.Rate ?? 0),
							Treatment: ctr.Treatments[0].Treatment,
							Year: existingFieldWithHybrid.Year,
						};

						convertedSeedAssignments.push(updatedAssignment);
					});
				}
			}
			else
			{
				// If this is a custom treatment the ID is used instead of the name in the key
				const treatmentKey = fieldItem.Treatments[0].CustomTreatmentId ? fieldItem.Treatments[0].CustomTreatmentId : fieldItem.Treatments[0].Treatment.toLocaleLowerCase();

				// Search the currentQuote for this Field/Hybrid/Treatment combination to see if it is new or not
				const existingFieldHybridTreatment = currentQuote.SeedAssignments.find(sa =>
					sa.HybridId === fieldItem.HybridId && sa.FieldId === fieldItem.Id &&
					(sa.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase() || sa.CustomTreatmentId === treatmentKey));

				const updatedAssignment: ISeedAssignmentUpdateRequest =
				{
					AppliedAcres: fieldItem.AppliedAcres,
					CustomTreatmentId: fieldItem.Treatments[0].CustomTreatmentId,
					FieldId: existingFieldHybridTreatment ? existingFieldHybridTreatment.FieldId : null,
					HybridId: fieldItem.Id,
					Id: existingFieldHybridTreatment ? existingFieldHybridTreatment.Id : null,
					SeedingRate: Math.round(fieldItem.Rate ?? 0),
					Treatment: fieldItem.Treatments[0].Treatment,
					Year: existingFieldHybridTreatment ? existingFieldHybridTreatment.Year : null
				};

				convertedSeedAssignments.push(updatedAssignment);
			}
		});

		const quoteRequest: ISendQuoteRequest =
		{
			isGHX: isGHX,
			SendQuote: send,
			ShipFrom: addressSelection,
			QuoteId: currentQuote.Id,
			QuoteRequestLineUpdates: convertedLines,
			SeedAssignmentUpdateRequests: convertedSeedAssignments,
		};

		setOpenSendModal(false);
		SendQuote(quoteRequest);
	};

	const addTreatmentRow = (farmId: string, fieldId: string, hybridId: string, treatment: ISelectableTreatment) =>
	{
		const currentQuoteLinesToSeeds = quoteLinesToSeeds;
		const currentFarmFieldToSeeds = farmFieldToSeeds;
		const allSeeds = Seeds.flatMap(s => s.BrandApplications.flatMap(ba => ba.Hybrids));
		const foundSeed = allSeeds.find(s => s.Id === hybridId);

		if (foundSeed)
		{
			const { BrandName, CropId, MaterialAvailability, Name } = foundSeed;
			const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
			const key: string = CropId + brandApplication;
			const cropConfig: ICropConfig = CropConfig()[CropId];
			const { seedsPerBag } = cropConfig;

			const foundCurrQuoteLines = currentQuoteLinesToSeeds[key];
			const foundFieldItem = currentFarmFieldToSeeds[key][farmId].FieldRows[fieldId + hybridId];

			// No acres yet since this is a new treatment line
			const bagsNeeded: number = Math.ceil((foundFieldItem.Rate * 0) / seedsPerBag);

			// If this is a custom treatment the ID is used instead of the name in the key
			const treatmentKey = treatment.CustomTreatmentId ? treatment.CustomTreatmentId : treatment.Treatment.toLocaleLowerCase();

			// Mark the treatment as selected
			foundFieldItem.Treatments.find(t => t.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase() || t.CustomTreatmentId === treatmentKey).Selected = true;

			if (!foundFieldItem.ChildRows)
			{
				foundFieldItem.ChildRows = {};
			}

			const fieldRow: IFieldRow =
			{
				AppliedAcres: 0,
				BagsNeeded: bagsNeeded,
				BrandName: BrandName,
				CropId: CropId,
				FarmId: farmId,
				FieldName: foundFieldItem.FieldName,
				HybridId: hybridId,
				HybridName: foundFieldItem.HybridName,
				Id: foundFieldItem.Id, // Field Id
				Rate: Math.round(foundFieldItem.Rate ?? 0),
				RM: Number.parseFloat(foundSeed.RelativeMaturity),
				Selected: true,
				Treatments: [treatment]
			};

			foundFieldItem.ChildRows[hybridId + treatmentKey] = fieldRow;
			setFarmFieldToSeeds({ ...currentFarmFieldToSeeds });

			// If the hybrid/treatment combo does not currently exist, add it
			if (!foundCurrQuoteLines[foundSeed.Id + treatmentKey])
			{
				const seedTreatment: ISelectableTreatment[] = setupTreatments(MaterialAvailability, CustomTreatments);
				const packageAvailability = seedTreatment.find(s => s.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase()
				|| s.CustomTreatmentId === treatmentKey).PackagingAvailability;
				foundCurrQuoteLines[foundSeed.Id + treatmentKey] =
				{
					AmtRequired: 0,
					AvailablePackaging: packageAvailability,
					BrandName: BrandName,
					CropId: CropId,
					CustomTreatmentId: treatment.CustomTreatmentId,
					Id: foundSeed.Id,
					HybridName: Name,
					QuantityPB: 0,
					QuantityQB: 0,
					QuantityTB: 0,
					QuantityTO: 0,
					QuoteLineId: null,
					RM: Number.parseFloat(foundSeed.RelativeMaturity),
					TotalBags: 0,
					Treatment: treatment.Treatment
				};

				setQuoteLinesToSeeds({ ...currentQuoteLinesToSeeds });
			}
		}
	};

	const removeTreatmentRow = (farmId: string, fieldId: string, hybridId: string, treatment: ISelectableTreatment) =>
	{
		const currentFarmFieldToSeeds = farmFieldToSeeds;
		const currentQuoteLinesToSeeds = quoteLinesToSeeds;
		const allSeeds = Seeds.flatMap(s => s.BrandApplications.flatMap(ba => ba.Hybrids));
		const foundSeed = allSeeds.find(s => s.Id === hybridId);

		if (foundSeed)
		{
			const { CropId, BrandName } = foundSeed;
			const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
			const key: string = CropId + brandApplication;
			const cropConfig: ICropConfig = CropConfig()[CropId];
			const { seedsPerBag } = cropConfig;

			// If this is a custom treatment the ID is used instead of the name in the key
			const treatmentKey = treatment.CustomTreatmentId ? treatment.CustomTreatmentId : treatment.Treatment.toLocaleLowerCase();

			// Get all the seed assignments from the rows -- need these to recalculate the AmtRequired on the
			// Hybrid/Parent row
			const allFarmSections = Object.values(farmFieldToSeeds);
			let allFarmItems: IFarmItem[] = [];
			allFarmSections.forEach(fs => allFarmItems = allFarmItems.concat(Object.values(fs)));
			const allFieldItems: IFieldRow[] = allFarmItems.flatMap(fa => Object.values(fa.FieldRows));

			let allFieldsBagsNeeded = 0;
			// Filter all Fields down to the specific Hybrid -- but leave out the field we're updating
			const filteredFieldsByHybrid = allFieldItems.filter(f => f.HybridId === hybridId && f.Id !== fieldId);
			filteredFieldsByHybrid.forEach(ff =>
			{
				// Multi-Treatment, calculate the child rows
				if (ff.Treatments.length > 1)
				{
					const childrenRows = Object.values(ff.ChildRows);

					// Filter to the specific Hybrid/treatment combo
					childrenRows.filter(cr => cr.HybridId === hybridId &&
						(cr.Treatments[0].CustomTreatmentId === treatmentKey || cr.Treatments[0].Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase()))
						.forEach(cr =>
						{
							const bagsNeeded: number = Math.ceil((cr.Rate * cr.AppliedAcres) / seedsPerBag);
							allFieldsBagsNeeded += bagsNeeded;
						});
				}
				else
				{
					// Single Treatment
					const bagsNeeded: number = Math.ceil((ff.Rate * ff.AppliedAcres) / seedsPerBag);
					allFieldsBagsNeeded += bagsNeeded;
				}
			});

			const currentLineToUpdate: IHybridRowItem = currentQuoteLinesToSeeds[key][hybridId + treatmentKey];
			const foundFieldItem: IFieldRow = currentFarmFieldToSeeds[key][farmId].FieldRows[fieldId + hybridId];
			foundFieldItem.Treatments.find(t => t.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase() || t.CustomTreatmentId === treatmentKey).Selected = false;

			// Whether or not this hybrid was the last of it's kind in the field list
			const otherHybridFieldsExist = allFieldItems.some(f => f.HybridId === hybridId &&
				f.Treatments.some(t => (t.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase() || t.CustomTreatmentId === treatmentKey) && t.Selected));

			if (!otherHybridFieldsExist)
			{
				// If this is the last of this specific Hybrid for this Treatment, delete the parent rows
				delete currentQuoteLinesToSeeds[key][hybridId + treatmentKey];
			}
			else
			{
				// Add the new total along with any other row totals to the hybrid parent row
				currentLineToUpdate.AmtRequired = allFieldsBagsNeeded;
			}

			if (foundFieldItem.ChildRows)
			{
				// Remove the key/value from the children dictionary
				delete foundFieldItem.ChildRows[hybridId + treatmentKey];
			}

			setQuoteLinesToSeeds({ ...currentQuoteLinesToSeeds });

			setFarmFieldToSeeds({ ...currentFarmFieldToSeeds });
		}
	};

	const updateHybridParentValues = (params: IUpdateHybridRowParams) =>
	{
		if (!params)
		{
			return;
		}

		const { BrandName, CropId, CustomTreatmentId, HybridId, TreatmentName, UpdateItem } = params;
		const currentQuoteLinesToSeeds = quoteLinesToSeeds;
		const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
		const key: string = CropId + brandApplication;

		// If this is a custom treatment the ID is used instead of the name in the key
		const treatmentKey = CustomTreatmentId ? CustomTreatmentId : TreatmentName.toLocaleLowerCase();

		const currentLinesToUpdate: IHybridRowItem = currentQuoteLinesToSeeds[key][HybridId + treatmentKey];

		const lineData: IHybridRowItem = {
			...currentLinesToUpdate,
			...UpdateItem
		};

		if (UpdateItem.QuantityPB >= 0 || UpdateItem.QuantityQB >= 0 || UpdateItem.QuantityTB >= 0 || UpdateItem.QuantityTO >= 0)
		{
			// Make sure we have no decimals entered from the user
			lineData.QuantityQB = lineData.QuantityQB ? Math.trunc(lineData.QuantityQB) : 0;
			lineData.QuantityPB = lineData.QuantityPB ? Math.trunc(lineData.QuantityPB) : 0;
			lineData.QuantityTB = lineData.QuantityTB ? Math.trunc(lineData.QuantityTB) : 0;
			lineData.QuantityTO = lineData.QuantityTO ? Math.trunc(lineData.QuantityTO) : 0;

			lineData.TotalBags = calculateTotalBags(lineData.QuantityQB, lineData.QuantityPB, lineData.QuantityTB, lineData.QuantityTO, CropId);
		}

		currentQuoteLinesToSeeds[key][HybridId + treatmentKey] = lineData;
		setQuoteLinesToSeeds({ ...currentQuoteLinesToSeeds });
	};

	const updateFieldRowValues = (params: IUpdateFieldRowParams) =>
	{
		if (!params)
		{
			return;
		}

		const { BrandName, CropId, CustomTreatmentId, FarmId, FieldId, HybridId, TreatmentName, UpdateItem } = params;
		const currentQuoteLinesToSeeds = quoteLinesToSeeds;
		const currentFieldLines = farmFieldToSeeds;
		const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
		const key: string = CropId + brandApplication;
		const cropConfig: ICropConfig = CropConfig()[CropId];
		const { seedsPerBag } = cropConfig;
		let isChildItem: boolean = false;

		// If this is a custom treatment the ID is used instead of the name in the key
		const treatmentKey = CustomTreatmentId ? CustomTreatmentId : TreatmentName.toLocaleLowerCase();

		// Get all the seed assignments from the rows -- need these to recalculate the AmtRequired on the
		// Hybrid/Parent row
		const allFarmSections = Object.values(farmFieldToSeeds);
		let allFarmItems: IFarmItem[] = [];
		allFarmSections.forEach(fs => allFarmItems = allFarmItems.concat(Object.values(fs)));
		const allFieldItems: IFieldRow[] = allFarmItems.flatMap(fa => Object.values(fa.FieldRows));

		let allFieldsBagsNeeded = 0;
		// Filter all Fields down to the specific Hybrid -- but leave out the field we're updating
		const filteredFieldsByHybrid = allFieldItems.filter(f => f.HybridId === HybridId && f.Id !== FieldId);
		filteredFieldsByHybrid.forEach(ff =>
		{
			// Multi-Treatment, calculate the child rows
			if (ff.Treatments.length > 1)
			{
				const childrenRows = Object.values(ff.ChildRows);

				// Filter to the specific Hybrid/treatment combo
				childrenRows.filter(cr => cr.HybridId === HybridId &&
					(cr.Treatments[0].CustomTreatmentId === treatmentKey || cr.Treatments[0].Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase()))
					.forEach(cr =>
					{
						const bagsNeeded: number = Math.ceil((cr.Rate * cr.AppliedAcres) / seedsPerBag);
						allFieldsBagsNeeded += bagsNeeded;
					});
			}
			else
			{
				// Single Treatment
				const bagsNeeded: number = Math.ceil((ff.Rate * ff.AppliedAcres) / seedsPerBag);
				allFieldsBagsNeeded += bagsNeeded;
			}
		});

		const currentLineToUpdate: IHybridRowItem = currentQuoteLinesToSeeds[key][HybridId + treatmentKey];
		let currentFieldToUpdate: IFieldRow = currentFieldLines[key][FarmId].FieldRows[FieldId + HybridId];

		// Are we updating a child or a parent?
		if (currentFieldToUpdate.ChildRows)
		{
			currentFieldToUpdate = currentFieldToUpdate.ChildRows[HybridId + treatmentKey];
			isChildItem = true;
		}

		const fieldData: IFieldRow = {
			...currentFieldToUpdate,
			...UpdateItem
		};

		// Make sure the rate is rounded to a whole number.  This is put here to be defensive
		// against all possible inputs (user, calculation, etc)
		const newRate = Math.round(fieldData.Rate ?? 0);
		fieldData.Rate = newRate;

		const newBagsNeeded: number = Math.ceil((fieldData.Rate * fieldData.AppliedAcres) / seedsPerBag);
		fieldData.BagsNeeded = newBagsNeeded;

		// Add the new total along with any other row totals to the hybrid parent row
		currentLineToUpdate.AmtRequired = allFieldsBagsNeeded + newBagsNeeded;

		setQuoteLinesToSeeds({ ...currentQuoteLinesToSeeds });

		if (isChildItem)
		{
			currentFieldLines[key][FarmId].FieldRows[FieldId + HybridId].ChildRows[HybridId + treatmentKey] = fieldData;
		}
		else
		{
			currentFieldLines[key][FarmId].FieldRows[FieldId + HybridId] = fieldData;
		}
		setFarmFieldToSeeds({ ...currentFieldLines });
	};

	// Deletes a single hybrid parent row
	const onDeleteParentRow = (hybridId: string, treatment: string, customTreatmentId?: string) =>
	{
		const currentQuoteLinesToSeeds = quoteLinesToSeeds;
		const currentFieldLines = farmFieldToSeeds;
		const allSeeds = Seeds.flatMap(s => s.BrandApplications.flatMap(ba => ba.Hybrids));
		const foundSeed = allSeeds.find(s => s.Id === hybridId);

		if (foundSeed)
		{
			const { CropId, BrandName } = foundSeed;
			const brandApplication = BrandName === 'Enogen' ? 'Enogen' : 'Any';
			const key: string = CropId + brandApplication;

			// If this is a custom treatment the ID is used instead of the name in the key
			const treatmentKey = customTreatmentId ? customTreatmentId : treatment.toLocaleLowerCase();

			delete currentQuoteLinesToSeeds[key][hybridId + treatmentKey];

			const currentFarmSection: IFarmSection = currentFieldLines[key];
			const farms = Object.values(currentFarmSection);
			farms.forEach(fa =>
			{
				const fields = Object.values(fa.FieldRows);
				fields.forEach(f =>
				{
					if (f.HybridId === hybridId)
					{
						// Multi-hybrid field - delete just the child row
						if (f.ChildRows)
						{
							if (f.ChildRows[hybridId + treatmentKey])
							{
								delete f.ChildRows[hybridId + treatmentKey];
								f.Treatments.find(t => t.CustomTreatmentId === treatmentKey
									|| t.Treatment.toLocaleLowerCase() === treatmentKey.toLocaleLowerCase()).Selected = false;
							}
							// Check to see if the ChildRows is empty now
							if (!f.ChildRows || Object.values(f.ChildRows).length === 0)
							{
								// Delete the parent field
								delete fa.FieldRows[f.Id + hybridId];
							}
						}
						else
						{
							// Delete just the field
							delete fa.FieldRows[f.Id + hybridId];
						}
					}

					// Delete the farm if there are no more fields left
					if (!fa.FieldRows || Object.values(fa.FieldRows).length === 0)
					{
						delete currentFieldLines[key][fa.FarmId];
					}
				});
			});

			setQuoteLinesToSeeds({ ...currentQuoteLinesToSeeds });
			setFarmFieldToSeeds({ ...currentFieldLines });
		}
	};

	const displayEnogenFlowOnClick = useCallback(() =>
	{
		DisplayEnogenFlow({
			accessToken: AccessToken,
			apiBaseUrl: document.location.host,
			enogenFlowBaseUrl: EnogenRootUrl,
			selectedGrowerId: selectedGrower.Id,
			userId: CurrentUserId
		});
	}, [AccessToken, document.location.host, selectedGrower, CurrentUserId]);

	useEffect(() =>
	{
		if (quoteLinesToSeeds)
		{
			const disableSave = productsDoNotExistOrEmptyQuantity();
			if (disableSave)
			{
				setDisableSaveButton(true);
			}
			else
			{
				setDisableSaveButton(false);
			}
		}
	},[quoteLinesToSeeds]);

	const productsDoNotExistOrEmptyQuantity = (): boolean =>
	{
		let numProducts: number = 0;
		Object.keys(quoteLinesToSeeds).forEach(key =>
		{
			const row = quoteLinesToSeeds[key];
			numProducts += Object.keys(row).length;
		});

		const productsExist: boolean = numProducts > 0;

		let productMissingQuantity: boolean = false;

		if (productsExist)
		{
			const allLines: IHybridRowItem[] = [];
			// Check that each product has a quantity
			Object.keys(quoteLinesToSeeds).forEach(key =>
			{
				const allLinesForKey = Object.values(quoteLinesToSeeds[key]).flatMap(row => row);
				allLines.push(...allLinesForKey);
			});

			productMissingQuantity = allLines.some(l => l.QuantityPB === 0 && l.QuantityQB === 0 && l.QuantityTB === 0 && l.QuantityTO === 0);

			if (productMissingQuantity)
			{
				setDisabledSaveTooltip('Ensure that all product lines have a quantity ');
			}
			return productMissingQuantity;
		}

		if (!productsExist)
		{
			setDisabledSaveTooltip('Add seeds to '); // intentially left partial because the ShoppingCartHeader fills the rest in
		}

		return !productsExist;
	};

	return (
		<div style={{ width: '100%', height: '100%', zIndex: 998, paddingLeft: 70, backgroundColor: theme.colors.backgroundLM, display: 'flex', flexDirection: 'column' }}>
			<ShoppingCartHeader
				createType={sendType}
				currentRecommendation={currentRecommendation}
				isGHX={isGHX}
				selectedAddress={addressSelection}
				selectAddress={setAddressSelection}
				selectedGrower={selectedGrower}
				saveOrSend={saveOrSend}
				setOpenResetModal={setOpenResetModal}
				setCurrentRecommendation={setCurrentRecommendation}
				addresses={ShipFromAddresses}
				disableSaveAndConvert={disableSaveButton}
				disabledSaveTooltip={disabledSaveTooltip}
			/>
			<StyledQuoteContainer>
				<div style={{ marginTop: 20, overflowY: 'auto' }}>
					<TableHeader headerHeight='38px' className='padding-small' headers={currentRecommendation.cropName === 'Soybean' ? soyHeaderColumns : cornHeaderColumns}
						columnSpacing={currentRecommendation.cropName === 'Soybean' ? soySpacing : cornSpacing}
					/>
					{/* Hybrid Parent Rows */}
					{
						!!quoteLinesToSeeds && quoteLinesToSeeds[currentRecommendation.cropId + currentRecommendation.brandApplication] &&
						<QuoteStyledTableRows>
							{/**
							 * Parent Hybrids - these are listed per Hybrid/Treatment
							 */}
							{HybridParentRows({
								data: Object.values(quoteLinesToSeeds[currentRecommendation.cropId + currentRecommendation.brandApplication])
									.sort(dynamicSort('RM')),
								onClickDelete: onDeleteParentRow,
								updateValues: updateHybridParentValues
							}).map(
								(row: TableRowType<IHybridRowItem>) =>
									<StyledTableRow
										key={`field-quote-id-${row.data.Id}-${row.data.CustomTreatmentId
											? row.data.CustomTreatmentId : row.data.Treatment}`}
										className={`field-quote-id-${row.data.Id}-${row.data.CustomTreatmentId
											? row.data.CustomTreatmentId : row.data.Treatment}`}
										Id={row.data.Id}
										items={row.items}
										columnSpacing={currentRecommendation.cropName === 'Soybean' ? soySpacing : cornSpacing}
										hideExpand
									/>
							)}
						</QuoteStyledTableRows>
					}

					<Divider style={{ borderColor: theme.colors.darkGrey, margin: 0 }} />
					{/* Farm and Fields Sections */}
					{
						!!farmFieldToSeeds && farmFieldToSeeds[currentRecommendation.cropId + currentRecommendation.brandApplication] &&
						<div style={{ marginTop: 20 }}>
							{Object.values(farmFieldToSeeds[currentRecommendation.cropId + currentRecommendation.brandApplication])
								.map((farmItem: IFarmItem) =>
									<div key={farmItem.FarmId}>
										<div
											style={{
												fontWeight: theme.fontWeights.bold,
												fontSize: theme.fontSizes.large,
												fontFamily: theme.fonts.heading,
												marginTop: 15,
												marginBottom: 15
											}}
										>{farmItem.FarmName}</div>
										<div>
											<TableHeader headerHeight='38px' className='padding-small' headers={fieldListHeaderColumns}
												columnSpacing={fieldListHeaderSpacing}
											/>
											<div>
												{FieldHybridRows({
													data: Object.values(farmItem.FieldRows).sort(dynamicSort('FieldName')),
													isChildRow: false,
													onClickDelete: onDeleteFieldRow,
													addNewTreatmentItem: addTreatmentRow,
													removeTreatmentItem: removeTreatmentRow,
													updateValues: updateFieldRowValues,
												}).map(
													(row: TableRowType<IFieldRow>) =>
														<QuoteStyledTableRows key={`fieldrow-parent-quote-id-${row.data.HybridName}-${row.data.Id}`}>
															<StyledTableRow
																key={`fieldrow-quote-id-${row.data.HybridName}-${row.data.Id}`}
																className={`fieldrow-quote-id-${row.data.HybridName}-${row.data.Id}`}
																style={{ marginBottom: 0 }}
																Id={row.data.Id}
																items={row.items}
																columnSpacing={fieldListHeaderSpacing}
																hideExpand
															/>
															{/* Child Rows For Multi-Treatment */}
															{
																row.data.ChildRows && Object.values(row.data.ChildRows).map((fieldRow: IFieldRow) =>
																	FieldHybridRows({
																		data: [fieldRow],
																		isChildRow: true,
																		onClickDelete: removeTreatmentRow,
																		addNewTreatmentItem: addTreatmentRow,
																		removeTreatmentItem: removeTreatmentRow,
																		updateValues: updateFieldRowValues,
																	}).map(
																		(row: TableRowType<IFieldRow>) =>
																			<StyledTableRow
																				key={`fieldrow-quote-id-${row.data.HybridName}-${row.data.Id}-${row.data.Treatments[0].CustomTreatmentId
																					? row.data.Treatments[0].CustomTreatmentId
																					: row.data.Treatments[0].Treatment}`}
																				className={`fieldrow-quote-id-${row.data.HybridName}-${row.data.Id}-${row.data.Treatments[0].CustomTreatmentId
																					? row.data.Treatments[0].CustomTreatmentId
																					: row.data.Treatments[0].Treatment}`}
																				style={{ marginBottom: 0, marginTop: '0.1%' }}
																				Id={row.data.Id}
																				items={row.items}
																				columnSpacing={fieldListChildSpacing}
																				hideExpand
																			/>
																	))
															}
														</QuoteStyledTableRows>
												)}

											</div>
										</div>
									</div>
								)
							}
						</div>
					}
				</div>
			</StyledQuoteContainer>
			<StyledModal
				className='send-and-save-modal'
				title={`${saveSendmodalTitle} ${sendType}`}
				open={openSendModal}
				onCancel={() => setOpenSendModal(false)}
			>
				<span>{`${saveSendmodalTitle} this ${sendType.toLocaleLowerCase()}?`}</span>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
					<Button
						className='send-and-save-modal-cancel-button'
						variant='outlined'
						style={{ marginRight: '15px', minWidth: 100 }}
						onClick={() => setOpenSendModal(false)}
					>
						Cancel
					</Button>
					<Button
						className='send-and-save-modal-send-button'
						variant='dark'
						style={{ minWidth: 100 }}
						onClick={() => sendQuote(saveSendmodalTitle === 'Send' ? true : false)}
					>
						{saveSendmodalTitle}
					</Button>
				</div>
			</StyledModal>
			<StyledModal
				className='check-enogen-contract-modal'
				open={openEnogenContractModal}
				onCancel={() => setOpenEnogenContractModal(false)}
			>
				<span>{'You must create/update your Enogen contract to create an order.'}</span>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
					<Button
						className='enogen-contract-open-button'
						variant='outlined'
						style={{ marginRight: '15px', minWidth: 100 }}
						onClick={() => { displayEnogenFlowOnClick(); setOpenEnogenContractModal(false); }}
					>
						Open Enogen Contract
					</Button>
					<Button
						className='enogen-contract-cancel-button'
						variant='dark'
						style={{ minWidth: 100 }}
						onClick={() => { setOpenEnogenContractModal(false); }}
					>
						Cancel
					</Button>
				</div>
			</StyledModal>
			<IFrameModal title="Enogen Contracting" width={1200} open={!!EnogenFlowUrl}
				onCancel={() => HideEnogenFlow()}
				footer={null}>
				<iframe src={EnogenFlowUrl} />
			</IFrameModal>
			<StyledModal className='reset-modal' title={`Reset ${sendType}`} open={openResetModal} onCancel={() => setOpenResetModal(false)}>
				<span>{`Reset ${sendType} to last saved state? All un-saved changes will be lost.`}</span>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
					<Button
						className='reset-modal-cancel-button'
						variant='outlined'
						style={{ marginRight: '15px' }}
						onClick={() => setOpenResetModal(false)}
					>
						Cancel
					</Button>
					<Button className='reset-modal-send-button' variant='dark' onClick={resetQuote}>Reset</Button>
				</div>
			</StyledModal>
			<CoveringLoader
				className={(!IsLoadingQuotes && IsLoadingSeedsForGrower) ? 'loading' : ''}
				style={{backgroundColor: '#ddda', display: 'flex', flexDirection: 'column'}}
			>
				<ScaleLoader color={theme.colors.ghxOrangeLM} loading={true} />
				Loading Seeds
			</CoveringLoader>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	CustomTreatments: state.customTreatments.treatments,
	IsLoadingGetTreatments: state.customTreatments.isLoadingGetTreatments,
	IsLoadingPlanLaunch: state.grower.isLoadingPlanLaunch,
	IsLoadingQuotes: state.grower.isLoadingDraftQuotes,
	IsLoadingSeedsForGrower: state.seeds.isLoading,
	IsQuoteError: state.grower.isError,
	Seeds: state.seeds.seeds,
	ShipFromAddresses: state.userInfo.ShipFromAddresses,
	isGHX: hasEffectivePermission(state, UserPermission.CanSeePricing),
	UISelectedPlanId: state.ui.SelectedPlanId,
	EnogenFlowUrl: currentEnogenFlowUrl(state),
	CurrentUserId: getCurrentActingUser(state).UserId,
	EnogenRootUrl: state.config.MapCentricUrl,
	AccessToken: state.auth.userAuthToken,
	CurrentCropYear: state.crops.CropYear,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		ClearLaunchUrl: makeDispatch(dispatch, clearGamePlanLaunchUrl),
		GetCustomTreatments: makeDispatch(dispatch, getCustomTreatments),
		GetQuotes: makeDispatch(dispatch, getDraftQuotes),
		GetSeedsForGrower: makeDispatch(dispatch, getSeedsForGrower),
		GetEnogenContractCheck: makeDispatch(dispatch, getEnogenContractCheck),
		SendQuote: makeDispatch(dispatch, sendQuote),
		GetShipFromAddresses: makeDispatch(dispatch, getShipFromAddresses),
		SetSelectedPlanId: makeDispatch(dispatch, setSelectedPlanId),
		HideEnogenFlow: makeDispatch(dispatch, hideEnogenFlow),
		DisplayEnogenFlow: makeDispatch(dispatch, displayEnogenFlow),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const FieldQuote = connector(FieldQuoteComponent);