import React from 'react';
import { TableRowType } from '../../../../components/Table/TableRows';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/Trashcan.svg';
import { StyledRowCell } from '../../../../components/Table/TableRow';
import { IconButton } from '../../../../components/IconButton/IconButton';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { IProductQuoteRowItem, IUpdateHybridTreatmentParams } from './ProductShoppingCart';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { InputNumber, Popover, Tooltip } from 'antd';
import { soyId } from '../../../../logic/store/Seeds/CropsSlice';
import { Button } from '../../../../components/Button/Button';
import { ReactComponent as PlusIcon } from '../../../../assets/images/PlusIcon.svg';
import { dynamicSort } from '../../../../logic/Utility/Utils';
import { ISelectableTreatment } from '../Shared/QuoteUtils';
import { ReactComponent as GreenIcon } from '../../../../assets/images/AvailabilityIcons/Green.svg';
import { ReactComponent as RedIcon } from '../../../../assets/images/AvailabilityIcons/Red.svg';
import { ReactComponent as YellowIcon } from '../../../../assets/images/AvailabilityIcons/Yellow.svg';
import { themeGet } from '@styled-system/theme-get';

const StyledButtonCell = styled(StyledRowCell)`
	height: 100%;
	margin-left: auto;
	margin-right: 0;
	padding-left: 5%;
	@media (hover: none) {
		padding-left: 15%;
	}
	border-left: 1px solid;
	border-left-color: ${themeGet('colors.lightestGrey')};
	svg {
		position: inherit;
	}
`;

const StyledTooltip = styled(Tooltip)`
	text-align: center;
`;

const StyledIconButton = styled(IconButton)`
	border-radius: 25px;
	height: 80%;
	@media (hover: none) {
		width: 35px;
	}
	@media (hover: hover) {
		width: 40px;
	}
	path {
		fill: ${themeGet('colors.darkGrey')};
	}
`;

const StyledInputNumber = styled(InputNumber)`
	width: 40px;
	margin: auto;
	.ant-input-number-input {
		padding: 0;
		text-align: center;
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	input
	{
		font-family: 'Roboto';
		font-size: 12px;
		height: 14px;
		border-bottom: 1px black solid;
	}
`;

interface IButtonCellProps extends IProductQuoteRowItem
{
	onClickDelete: (hybridId: string, treatment: ISelectableTreatment) => void;
}
const ButtonCell = (props: IButtonCellProps) =>
{
	const { Id, SeedTreatments, onClickDelete } = props;

	const theme = useTheme();

	const treatment = SeedTreatments[0];
	return (
		<StyledButtonCell className='product-quote-button-section'>
			<StyledIconButton className={`${Id}-${treatment}-delete-button`} onClick={() => onClickDelete(Id, treatment)}
				tooltip='Delete'
				placement='bottom'
				color={theme.colors.redLM}
				hoverbgcolor={theme.colors.lightestGrey}>
				<DeleteIcon />
			</StyledIconButton>
		</StyledButtonCell>
	);
};

export interface ISeedTreatmentBlockProps
{
	hybridRowItem: IProductQuoteRowItem;
	keyPrefix: string;
	addNewItem: (hybridId: string, treatment: ISelectableTreatment) => void;
	removeItem: (hybridId: string, treatment: ISelectableTreatment) => void;
}

const StyledCheckboxRow = styled.div`
	margin-top: 12px;
	margin-bottom: 12px;

	label {
		font-family: ${themeGet('fonts.base')};
		font-size: ${themeGet('fontSizes.extraSmall')};
		font-weight: ${themeGet('fontWeights.normal')};
	}
`;

const SeedTreatmentBlock = (props: ISeedTreatmentBlockProps) =>
{
	const { hybridRowItem, keyPrefix, addNewItem, removeItem } = props;

	const theme = useTheme();

	function toggleSelection(treatment: ISelectableTreatment, checked: boolean)
	{
		if (checked)
		{
			treatment.Selected = true;
			addNewItem(hybridRowItem.Id, treatment);
		}
		else
		{
			removeItem(hybridRowItem.Id, treatment);
		}
	}

	const isChildRow = hybridRowItem.SeedTreatments && hybridRowItem.SeedTreatments.length === 1;

	function createCheckboxRows(props: ISelectableTreatment[]): JSX.Element[]
	{
		const rows = props.sort(dynamicSort('Treatment')).map(st =>
		{
			return (
				<StyledCheckboxRow
					key={st.Treatment + hybridRowItem.Id}
					className={`AddHybridTreatmentRow-${hybridRowItem.Id}`}
				>
					<Checkbox
						style={{ display: !(st.CustomIsDeleted && !st.Selected) ? 'flex' : 'none' }}
						checked={st.Selected}
						onChange={(e) => toggleSelection(st, e.target.checked)}
					>
						{st.Treatment}
					</Checkbox>

				</StyledCheckboxRow>
			);
		});

		return rows;
	}

	return (
		isChildRow ?
			<div>{hybridRowItem.SeedTreatments ? hybridRowItem.SeedTreatments[0].Treatment : 'Unavailable'}</div>
			:
			<Popover
				key={`${keyPrefix}-treatmentpopover`}
				className={`${keyPrefix}-treatmentpopover`}
				placement='bottom'
				trigger='click'
				content={createCheckboxRows(hybridRowItem.SeedTreatments)}>
				<Button style={{
					width: '90%',
					height: 32,
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center'
				}} variant='outlined' key={`${keyPrefix}-treatmentaddbutton`} className={`${keyPrefix}-treatmentaddbutton`}>
					<span style={{ paddingLeft: 9 }}>Treatment(s)</span>
					<span style={{ paddingRight: 9, display: 'flex', flexDirection: 'row', alignItems: 'center' }}><PlusIcon fill={theme.colors.darkGrey} /></span>
				</Button>
			</Popover>
	);
};

interface ISingleTreatmentQuoteRow 
{
	data: IProductQuoteRowItem[];
	theme: DefaultTheme;
	onClickDelete: (hybridId: string, treatment: ISelectableTreatment) => void;
	updateValues: (params: IUpdateHybridTreatmentParams) => void;
	isParentRow: boolean;
	isChildRow: boolean;
	addNewTreatmentItem?: (hybridId: string, treatment: ISelectableTreatment) => void;
	removeTreatmentItem?: (hybridId: string, treatment: ISelectableTreatment) => void;
}

export const SingleTreatmentRows = (props: ISingleTreatmentQuoteRow): TableRowType<IProductQuoteRowItem>[] =>
{
	const { data, isParentRow, isChildRow, theme, onClickDelete, updateValues, addNewTreatmentItem, removeTreatmentItem } = props;

	return data.sort((a, b) => a.SeedTreatments[0].Treatment > b.SeedTreatments[0].Treatment ? 1 : -1)
		.map((item: IProductQuoteRowItem, index) =>
		{
			const keyPrefix = `productquote-cell-${item.HybridName}-${index}`;

			const paperBagsDisabled = isParentRow ? false : !(item.SeedTreatments[0].PackagingAvailability.findIndex(pa => pa.PackageType === 'PB') > -1);
			const cubitsDisabled = isParentRow ? false : !(item.SeedTreatments[0].PackagingAvailability.findIndex(pa => pa.PackageType === 'QB') > -1);
			const toteBagDisabled = isParentRow ? false : !(item.SeedTreatments[0].PackagingAvailability.findIndex(pa => pa.PackageType === 'TO') > -1);
			const truBulkDisabled = isParentRow ? false : !(item.SeedTreatments[0].PackagingAvailability.findIndex(pa => pa.PackageType === 'TB') > -1);

			const paperBagsAvailability = item.SeedTreatments[0].PackagingAvailability.find(pa => pa.PackageType === 'PB')?.Availabililty;
			const cubitsAvailability = item.SeedTreatments[0].PackagingAvailability.find(pa => pa.PackageType === 'QB')?.Availabililty;
			const toteBagAvailability = item.SeedTreatments[0].PackagingAvailability.find(pa => pa.PackageType === 'TO')?.Availabililty;
			const truBulkAvailability = item.SeedTreatments[0].PackagingAvailability.find(pa => pa.PackageType === 'TB')?.Availabililty;

			const updateAndSave = (update: Partial<IProductQuoteRowItem>) => 
			{
				const parameters: IUpdateHybridTreatmentParams =
				{
					BrandName: item.BrandName,
					CropId: item.CropId,
					CustomTreatmentId: item.SeedTreatments[0].CustomTreatmentId,
					HybridId: item.Id,
					TreatmentName: item.SeedTreatments[0].Treatment,
					UpdateItem: update
				};
				updateValues(parameters);
			};

			const determineAvailabilityIcon = (availability: string) =>
			{
				switch(availability)
				{
					case 'Green' :
						return (
							<GreenIcon style={{ fill: theme.colors.availabilityGreen }} />
						);
					case 'Red' :
						return (
							<RedIcon style={{ fill: theme.colors.availabilityRed }} />
						);
					case 'Yellow' :
						return (
							<YellowIcon style={{ fill: theme.colors.availabilityYellow }} />
						);
					default :
						return (
							<YellowIcon style={{ fill: 'transparent' }} />
						);
				}
			};

			const isSingleRow = !isParentRow && !isChildRow;

			return {
				data: item,
				items: [
					<div key={`${keyPrefix}-hybrid`} className={`${keyPrefix}-hybridName`} style={{ visibility: !isParentRow && !isSingleRow ? 'hidden' : 'visible' }}>
						{item.HybridName}
					</div>,
					<div key={`${keyPrefix}-seedTreatment`} className={`${keyPrefix}-seedTreatment`} style={{ paddingLeft: 15 }}>
						<SeedTreatmentBlock keyPrefix={`${keyPrefix}-seedTreatment`} hybridRowItem={item} addNewItem={addNewTreatmentItem} removeItem={removeTreatmentItem} />
					</div>,
					<div
						key={`${keyPrefix}-rate`}
						className={`${keyPrefix}-rate`}
						style={{ textAlign: 'center', visibility: isParentRow ? 'hidden' : 'visible' }}
					>
						{item.Rate}
					</div>,
					<div
						key={`${keyPrefix}-appliedacres`}
						className={`${keyPrefix}-appliedacres`}
						style={{ textAlign: 'center' }}
					>
						{item.TotalBags !== item.AmtRequired && item.AdjAppliedAcres !== 0 ?
							Math.round((item.AdjAppliedAcres + Number.EPSILON) * 10) / 10 : Math.round((item.AppliedAcres + Number.EPSILON) * 10) / 10}
					</div>,
					<StyledTooltip
						title={paperBagsDisabled ? 'Paperbags not available for this treatment.' : ''}
						placement='top'
						key={`${keyPrefix}-paperbags-tooltip`}
						className={`${keyPrefix}-paperbags-tooltip`}
					>
						{!isParentRow && !paperBagsDisabled && determineAvailabilityIcon(paperBagsAvailability)}
						<span style={{ cursor: paperBagsDisabled ? 'not-allowed' : 'auto', textAlign: 'center', paddingLeft: 10 }}>
							<StyledInputNumber
								style={{ visibility: isParentRow ? 'hidden' : 'visible' }}
								disabled={paperBagsDisabled}
								type='number'
								key={`${keyPrefix}-paperbags`}
								className={`${keyPrefix}-paperbags`}
								min={0}
								step={1}
								value={item.PaperBags}
								bordered={false}
								onPressEnter={(evt) => updateAndSave({ PaperBags: (evt.target as HTMLInputElement).value ?
									Number((evt.target as HTMLInputElement).value) : item.PaperBags })}
								onBlur={(evt) => updateAndSave({ PaperBags: evt.currentTarget.value ?
									Number(evt.currentTarget.value) : item.PaperBags })}
							/></span></StyledTooltip>,
					<StyledTooltip
						title={cubitsDisabled ? 'QBits not available for this treatment.' : ''}
						placement='top'
						key={`${keyPrefix}-cubits-tooltip`}
						className={`${keyPrefix}-cubits-tooltip`}
					>
						{!isParentRow && !isSingleRow && !cubitsDisabled && determineAvailabilityIcon(cubitsAvailability)}
						<span style={{ cursor: cubitsDisabled ? 'not-allowed' : 'auto', textAlign: 'center', paddingLeft: 10 }}>
							<StyledInputNumber
								style={{ visibility: isParentRow ? 'hidden' : 'visible' }}
								disabled={cubitsDisabled}
								type='number'
								key={`${keyPrefix}-cubits`}
								className={`${keyPrefix}-cubits`}
								min={0}
								step={1}
								value={item.Cubits}
								bordered={false}
								onPressEnter={(evt) => updateAndSave({ Cubits: (evt.target as HTMLInputElement).value ?
									Number((evt.target as HTMLInputElement).value) : item.Cubits })}
								onBlur={(evt) => updateAndSave({ Cubits: evt.currentTarget.value ?
									Number(evt.currentTarget.value) : item.Cubits })}
							/></span></StyledTooltip>,
					item.CropId.toLowerCase() === soyId ?
						<StyledTooltip
							title={toteBagDisabled ? 'Totebags not available for this treatment.' : ''}
							placement='top'
							key={`${keyPrefix}-totebags-tooltip`}
							className={`${keyPrefix}-totebags-tooltip`}
						>
							{!isParentRow && !isSingleRow && !toteBagDisabled && determineAvailabilityIcon(toteBagAvailability)}
							<span style={{ cursor: toteBagDisabled ? 'not-allowed' : 'auto', textAlign: 'center', paddingLeft: 10 }}>
								<StyledInputNumber
									style={{ visibility: isParentRow ? 'hidden' : 'visible' }}
									disabled={toteBagDisabled}
									type='number'
									key={`${keyPrefix}-totebags`}
									className={`${keyPrefix}-totebags`}
									min={0}
									step={1}
									value={item.Totebags}
									bordered={false}
									onPressEnter={(evt) => updateAndSave({ Totebags: (evt.target as HTMLInputElement).value ?
										Number((evt.target as HTMLInputElement).value) : item.Totebags })}
									onBlur={(evt) => updateAndSave({ Totebags: evt.currentTarget.value ?
										Number(evt.currentTarget.value) : item.Totebags })}
								/>
							</span></StyledTooltip>
						: React.Fragment,
					item.CropId.toLowerCase() === soyId ?
						<StyledTooltip
							title={truBulkDisabled ? 'TruBulk not available for this treatment.' : ''}
							placement='top'
							key={`${keyPrefix}-trubulk-tooltip`}
							className={`${keyPrefix}-trubulk-tooltip`}
						>
							{!isParentRow && !isSingleRow && !truBulkDisabled && determineAvailabilityIcon(truBulkAvailability)}
							<span style={{ cursor: truBulkDisabled ? 'not-allowed' : 'auto', textAlign: 'center', paddingLeft: 10 }}>
								<StyledInputNumber
									style={{ visibility: isParentRow ? 'hidden' : 'visible' }}
									disabled={truBulkDisabled}
									type='number'
									key={`${keyPrefix}-trubulk`}
									className={`${keyPrefix}-trubulk`}
									min={0}
									step={1}
									value={item.Trubulk}
									bordered={false}
									onPressEnter={(evt) => updateAndSave({ Trubulk: (evt.target as HTMLInputElement).value ?
										Number((evt.target as HTMLInputElement).value) : item.Trubulk })}
									onBlur={(evt) => updateAndSave({ Trubulk: evt.currentTarget.value ?
										Number(evt.currentTarget.value) : item.Trubulk })}
								/>
							</span></StyledTooltip>
						: React.Fragment,
					<div style={{ textAlign: 'center' }} key={`${keyPrefix}-totalamtrequired`} className={`${keyPrefix}-totalamtrequired`}>
						{isChildRow ? item.TotalBags : `${item.TotalBags} / ${item.AmtRequired}`}
					</div>,
					<ButtonCell key={`${keyPrefix}-buttons`} {...item} onClickDelete={onClickDelete} />,
				]
			};
		});
};