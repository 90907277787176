import React from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { IOrderResponse, IOrderLine } from '../../../../logic/Models/Responses/OrderResponse';
import { ProductPlanCropType } from '../../../../logic/Models/Responses/ProductGamePlan';
import { AppDispatch, RootState } from '../../../../logic/store/Store';
import { makeDispatch } from '../../../../logic/Utility/Utils';
import { connect, ConnectedProps } from 'react-redux';
import { addSeriesToEditingState, addProductLineToEditingState } from '../../../../logic/store/Plans/ProductGamePlanSlice';
import { SeriesAvailability } from '../SeriesAvailability';
import { ReactComponent as GreenAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Green.svg';
import { ReactComponent as YellowAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Yellow.svg';
import { ReactComponent as RedAvailabilityIcon } from '../../../../assets/images/AvailabilityIcons/Red.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/images/PlusIcon.svg';
import { IHybridResponse } from '../../../../logic/Models/Responses/SeedResponse';

const SeriesRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 50px;
	min-height: 50px;
	background-color: ${themeGet('colors.white')};
	border: 1px solid ${themeGet('colors.lightestGrey')};
	border-radius: 5px;
	margin-bottom: 8px;
	align-items: center;
	font-family: ${themeGet('fonts.base')};
	font-size: ${themeGet('fontSizes.extraSmall')};
`;

const SeriesData = styled.div<{ disabled?: boolean }>`
	height: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	color: ${props => props.disabled ? themeGet('colors.lightGrey') : themeGet('colors.darkGrey')};
`;

const SeriesAction = styled.div<{ disabled?: boolean }>`
	height: 100%;
	min-width: 150px;
    display: flex;
    align-items: center;
    padding: 17px 24px;
    cursor: pointer;
	font-weight: ${themeGet('fontSizes.bold')};
	border-left: thin solid ${themeGet('colors.lightestGrey')};
	color: ${props => props.disabled ? themeGet('colors.lightGrey') : 'black'};
	cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

interface IOrderHybridRowProps
{
	planId: string;
	cropId: string;
	cropType: ProductPlanCropType;
	orderSeed: IOrderLine;
	hybrids: IHybridResponse[];
	disabled?: boolean;
}

const mapStateToProps = (state: RootState, ownState: IOrderHybridRowProps) => ({
	localEditingState: state.productGamePlan.productGamePlans[ownState.planId]?.localEditingState,
	seriesLookup: state.productGamePlan.seriesLookup,
	customTreatments: state.customTreatments.treatments.filter(ct => !ct.IsDeleted)
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
	addSeries: makeDispatch(dispatch, addSeriesToEditingState),
	addProduct: makeDispatch(dispatch, addProductLineToEditingState)
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const OrderHybridRow = (props: IOrderHybridRowProps & PropsFromRedux) =>
{
	const { planId, cropId, cropType, orderSeed, localEditingState, seriesLookup, hybrids, disabled, customTreatments, addSeries, addProduct } = props;

	const theme = useTheme();

	const series = seriesLookup[orderSeed.CurrentEquivalent ? orderSeed.CurrentEquivalent.SeriesId : orderSeed.SeriesId];
	const product = hybrids.find(h => orderSeed.CurrentEquivalent ? h.Id === orderSeed.CurrentEquivalent.HybridId : h.Id === orderSeed.HybridId);

	if(!localEditingState || !cropId || !cropType || !planId)
	{
		// If we don't have data, just display a blank space
		return <></>;
	}

	const disabledOrIncluded = disabled || !!localEditingState?.series[series.seriesId];

	return (
		<SeriesRow className='OrderRow' key={`OrderProduct${orderSeed.Id}`}>
			<SeriesAvailability className='SeriesAvailability' disabled={disabledOrIncluded}>
				{/* Currently order projections from the back-end do not include availability data. Restore this if that changes. ~mburroughs 7/29/21 */}
				{/* {orderSeed.availability == 'Green' ? <GreenAvailabilityIcon />
					: orderSeed.availability === 'Yellow' ? <YellowAvailabilityIcon />
						: <RedAvailabilityIcon />
				} */}
				<RedAvailabilityIcon />
			</SeriesAvailability>
			<div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, paddingLeft: 30 }}>
				<SeriesData className='HybridName' style={{ width: '30%' }} disabled={disabledOrIncluded}>
					{orderSeed.HybridName}
				</SeriesData>
				<SeriesData className='HybridRm' style={{ width: '20%' }} disabled={disabledOrIncluded}>
					RM: {orderSeed.RM}
				</SeriesData>
				<SeriesData className='OrderUnits' style={{ width: '25%', fontWeight: theme.fontWeights.normal }} disabled={disabledOrIncluded}>
					Units: {orderSeed.Quantity}
				</SeriesData>
				<SeriesData className='OrderPackage' style={{ width: '25%', fontWeight: theme.fontWeights.normal }} disabled={disabledOrIncluded}>
					Package Type: {orderSeed.Packaging}
				</SeriesData>
			</div>
			<SeriesAction className='SeriesAction' disabled={disabledOrIncluded}
				onClick={() => 
				{
					addSeries({ planId, cropId, cropType, series, customTreatments });
					addProduct({ planId, cropId, cropType, product, customTreatments });
				}}
			>
				Add to Catalog <PlusIcon style={{ marginLeft: 10 }} fill={disabledOrIncluded ? theme.colors.disabledGrey : theme.colors.darkGrey} width={12} height={12} />
			</SeriesAction>
		</SeriesRow>
	);
};

export const ConnectedOrderHybridRow = connector(OrderHybridRow);