import React, { useEffect, useState } from 'react';
import { IFieldResponse } from '../../Models/Responses/FieldResponse';
import { PortalPopUp } from './PortalPopUp';
import { ReactComponent as AcresSvg } from '../Icons/Acres.svg';
import { ReactComponent as SoilSvg } from '../Icons/Soil.svg';
import { ReactComponent as SelectBoundary } from '../Icons/SelectBoundary.svg';
import { Button } from '../../../components/Button/Button';
import styled, { useTheme } from 'styled-components';
import { dynamicSort } from '../../Utility/Utils';

export interface IFieldSoilData
{
	Acres: string;
	Color: string;
	MapUnit: string;
	OverallNccpi: string;
	PercentOfTotal: string;
	SoilType: string;
}

const SeedLabelRow = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 2%;
`;

const SeedHybridRow = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: 4%;
	padding-right: 2%;
`;

const SeedHybridParentRow = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: 2%;
	padding-right: 2%;
	padding-bottom: 2px;
`;

const ColoredDot = styled.div<{ color: string }>`
	&:before {
		content:"";
		width:10px;
		height:10px;
		border-radius:50%;
		background: ${props => props.color};
		display: flex;
		margin-top: 2px;
	}
`;

interface IMapPopUpProps
{
	reference: HTMLDivElement | undefined;
	field: IFieldResponse;
	farmName: string;
	showSoilMapButton: boolean;
	fieldSoilData: IFieldSoilData[];
	loadSoilMap: (field: IFieldResponse) => Promise<void>;
	showFieldDetails: () => void;
}

interface IFieldSeedRow
{
	Acres: number;
	Treatment: string;
}

export const MapPopUp = (props: IMapPopUpProps) =>
{
	const { reference, field, farmName, showSoilMapButton, fieldSoilData, loadSoilMap, showFieldDetails } = props;
	const [fieldSeeds, setFieldSeeds] = useState<{ [key: string]: IFieldSeedRow[] }>({});

	const theme = useTheme();

	useEffect(() =>
	{
		if (field && field.Seeds)
		{
			const currentFieldSeeds = {};
			field.Seeds.forEach(fieldSeed =>
			{
				// Sort the seeds
				if (!currentFieldSeeds[fieldSeed.HybridName])
				{
					currentFieldSeeds[fieldSeed.HybridName] = [{ Acres: fieldSeed.Area, Treatment: fieldSeed.Treatment }];
				}
				else
				{
					const foundIndex = currentFieldSeeds[fieldSeed.HybridName].findIndex(t => t.Treatment === fieldSeed.Treatment);
					if (foundIndex > -1)
					{
						return;
					}
					else
					{
						currentFieldSeeds[fieldSeed.HybridName] =
							[...currentFieldSeeds[fieldSeed.HybridName], { Acres: fieldSeed.Area, Treatment: fieldSeed.Treatment }];
					}
				}

			});
			setFieldSeeds({ ...currentFieldSeeds });
		}
	}, [field]);	

	return (
		field ?
			<PortalPopUp className='MapPopUp_Container' reference={reference}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						padding: '5%'
					}}
				>
					<span
						className='MapPopUp_FarmName'
						style={{
							fontSize: theme.fontSizes.small,
							fontWeight: theme.fontWeights.bold,
							paddingBottom: '2%'
						}}
					>
						{farmName}
					</span>
					<span
						className='MapPopUp_FieldName'
						style={{
							fontSize: theme.fontSizes.xLarge,
							fontWeight: theme.fontWeights.bold,
							paddingBottom: '2%'
						}}
					>
						{field.Name}
					</span>
					<span className='MapPopUp_Acreage' style={{ fontSize: theme.fontSizes.small, paddingBottom: '4%' }}>
						{
							showSoilMapButton ?
								<div><AcresSvg style={{ marginRight: 5, position: 'relative', top: 3 }} />{field.Area.toFixed(1)} ac</div>
								:
								<div>Soil Information</div>
						}
					</span>
					<div>
						<Button
							className='MapPopUp_SoilMapButton'
							variant='light'
							style={{ display: showSoilMapButton ? 'flex' : 'none', alignItems: 'center' }}
							onClick={async() => await loadSoilMap(field)}
						>
							<SoilSvg style={{ position: 'relative', marginRight: 5, fill: theme.colors.primary }} />
							<span style={{
								fontWeight: theme.fontWeights.normal,
								fontSize: theme.fontSizes.small,
								color: theme.colors.primary,
							}}>
								Soil Map
							</span>
						</Button>
						<Button
							className='MapPopUp_FieldDetailsButton'
							variant='light'
							style={{ display: showSoilMapButton ? 'none' : 'flex', alignItems: 'center' }}
							onClick={showFieldDetails}
						>
							<SelectBoundary style={{ position: 'relative', marginRight: 5, fill: theme.colors.primary }} />
							<span style={{
								fontWeight: theme.fontWeights.normal,
								fontSize: theme.fontSizes.small,
								color: theme.colors.primary,
							}}>
								Field Details
							</span>
						</Button>
					</div>
					{showSoilMapButton ?
						<div className='MapPopUp_SeedContainer'
							style={{
								border: '1px solid',
								borderColor: theme.colors.darkGrey,
								marginTop: '3%',
								width: '100%',
								padding: '5%'
							}}
						>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<SeedLabelRow>
									<div>
										Current Crop
									</div>
									<div className='FieldCurrentCrop' style={{ marginLeft: 'auto' }}>
										{field.CurrentCrop}
									</div>
								</SeedLabelRow>
								<SeedLabelRow>
									<div>
										Planting Date
									</div>
									<div className='FieldCurrentCrop' style={{ marginLeft: 'auto' }}>
										{field.PlantingDate ? new Date(field.PlantingDate).toLocaleDateString(): 'N/A'}
									</div>
								</SeedLabelRow>
								{
									fieldSeeds && field.Seeds?.length > 0 && <SeedLabelRow>
										<div style={{ fontWeight: theme.fontWeights.bold }}>
											Hybrid/Variety
										</div>
										<div style={{ marginLeft: 'auto', fontWeight: theme.fontWeights.bold }}>
											Acres
										</div>
									</SeedLabelRow>
								}
								{fieldSeeds && Object.keys(fieldSeeds).sort().map((hybridName) =>
									<div key={`${field.Id}-${hybridName}`} style={{ paddingBottom: 5 }}>
										<SeedHybridParentRow
											className={`HybridParentRow_${field.Id}-${hybridName}`}
											style={{ backgroundColor: theme.colors.lightestGrey }}
										>
											<div
												style={{ fontWeight: theme.fontWeights.bold }}
												className={`HybridName_${field.Id}-${hybridName}`}
											>
												{hybridName}
											</div>
											<div
												style={{ fontWeight: theme.fontWeights.bold, marginLeft: 'auto' }}
												className={`TotalAcres_${field.Id}-${hybridName}`}
											>
												{fieldSeeds[hybridName].reduce((sum, fieldDetail) => sum + fieldDetail.Acres, 0).toFixed(2)}
											</div>
										</SeedHybridParentRow>										
										{fieldSeeds[hybridName].sort(dynamicSort('Treatment')).map((seedDetails) =>
											<SeedHybridRow
												key={`${field.Id}-${hybridName}-${seedDetails.Treatment}`}
												className={`Field_${field.Id}-${hybridName}-${seedDetails.Treatment}_SeedData`}
											>
												<div className={`SeedTreatmentName_${seedDetails.Treatment}`}>
													{seedDetails.Treatment}
												</div>
												<div className={`SeedTreatmentArea_${seedDetails.Treatment}`} style={{ marginLeft: 'auto' }}>
													{seedDetails.Acres.toFixed(2)}
												</div>
											</SeedHybridRow>
										)}
									</div>
								)}
							</div>
						</div>
						:
						<React.Fragment />
					}
					{
						!showSoilMapButton &&
						<div className='MapPopUp_SoilDataContainer'
							style={{
								marginTop: '3%',
								width: '100%',
							}}
						>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div
									className='SoilDataTable_HeaderRow'
									style={{
										display: 'flex',
										flexDirection: 'row',
										fontWeight: theme.fontWeights.bold,
										paddingBottom: 5
									}}>
									<div style={{ width: 62 }}>
										NCCPI
									</div>
									<div style={{ width: 80 }}>
										Soil Zone
									</div>
									<div style={{ width: 50, textAlign: 'right' }}>
										Acres
									</div>
									<div style={{ marginLeft: 'auto' }}>
										% of Total
									</div>
								</div>
								<div
									className='SoilDataTable_DataRows'
									style={{ display: 'flex', flexDirection: 'column', maxHeight: 200, overflowY: 'auto' }}
								>
									{fieldSoilData.sort(dynamicSort('PercentOfTotal', 'descending')).map(fsd =>
										<div
											key={fsd.MapUnit}
											className={`SoilDataRow_${fsd.MapUnit}`}
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
												paddingBottom: 2
											}}
										>
											<div
												className='SoilData_Color_Overall'
												style={{ display: 'flex', flexDirection: 'row', width: 60 }}
											>
												<ColoredDot className='Color' color={fsd.Color} />
												<div className='Overall' style={{ paddingLeft: 4 }}>{fsd.OverallNccpi}</div>
											</div>
											<div className='SoilData_SoilType' style={{ width: 80 }}>{fsd.SoilType}</div>
											<div className='SoilData_Acres' style={{ width: 40, textAlign: 'right' }}>{fsd.Acres}</div>
											<div className='SoilData_PercentOfTotal' style={{ width: 60, textAlign: 'right' }}>{fsd.PercentOfTotal}%</div>
										</div>
									)}
								</div>
							</div>
						</div>
					}
					{/* Removed for now until previous crop/yield data is sent */}
					{/* <div className='MapPopUp_OtherDetailsContainer'
						style={{
							border: '1px solid',
							borderColor: theme.colors.darkGrey,
							marginTop: '3%',
							width: '100%',
							padding: '5%',
							marginBottom: '5%'
						}}
					>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2%' }}>
								<div>
									Previous Crop
								</div>
								<div style={{ marginLeft: 'auto' }}>
									Soybean
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2%' }}>
								<div>
									Predicted Yield Corn
								</div>
								<div style={{ marginLeft: 'auto' }}>
									3,800
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2%' }}>
								<div>
									Event Count
								</div>
								<div style={{ marginLeft: 'auto' }}>
									341
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2%' }}>
								<div>
									Growth Stage
								</div>
								<div style={{ marginLeft: 'auto' }}>
									---
								</div>
							</div>
						</div>
					</div> */}
				</div>
				{/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
					<div style={{ borderBottom: '1px solid', borderColor: theme.colors.darkGrey, width: '100%' }} />
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%', marginTop: '5%' }}>
						<Button style={{ width: '45%' }} variant='outlined'>Edit</Button>
						<Button style={{ width: '45%' }} variant='outlined'>Machine Data</Button>
					</div>
				</div> */}
			</PortalPopUp>
			:
			<React.Fragment />
	);
};