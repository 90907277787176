import * as amplitude from '@amplitude/analytics-browser';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EventNames, EventNavigationNames } from '../../tracing/EventNames';
import { EventSelectionTags, EventStructureTags, EventTagNames, EventTags, EventUserTags } from '../../tracing/EventTagNames';
import { IScopedSession } from '../../tracing/session';
import { RootState } from '../store/Store';
import { useStable } from './useStable';

/**
 * You can provide top-level properties here if it makes sense, or provide dynamic properties
 * on the callback of emit at point-of-execution.
 * 
 * Usage:
 * 
 * const MyComponent = ( { onaccept, onclose }) => {
 *  const [counter, setCounter] = useState(0); // example of some value you may like to track in the event properties
 * 	const trackAcceptEvent = useAmplitudeTracking('acceptevent', { counter });
 *  const trackCloseEvent = useAmplitudeTracking('closeevent', { counter })
 * 
 *  return <div>
 *     <button onClick={() => { trackAcceptEvent({ timestamp: new Date() }; onaccept(); }}>Accept</button>
 *     <button onClick={() => { trackCloseEvent({ timestamp: new Date() }); onclose(); }}>Close</button>
 * 	</div>;
 * }
 */

/**
 * Create an emitter function that can be used to raise any event. 
 * 
 * Useful when an existing callback needs to conditionally raise events.  
 * eg ```
 *   const emit = useDynamicEvent(session);
 *   const func = useCallback((toggle) => { if(toggle) emit('Event.True'); else emit('Event.false'); }, []);
 * ```
 * 
 * @param session The session these events should be raised to.
 * @param properties (optional) properties to assign to any event triggered by the emiter.
 * @returns An emitter function that requires the event name and optionally properties to assign to that event only.
 */
export function useDynamicEvent(session: IScopedSession, properties?: EventTags)
{
	const rootProperties = useDefaultTrackingState();
	const componentProperties = useStable(properties);
	const emit = useCallback((name: string, additionalProperties?:EventTags) => 
	{
		const payload = Object.freeze({
			...rootProperties,
			...componentProperties,
			...additionalProperties
		});
		session.event(name, payload);
	}, [rootProperties, componentProperties, session]);
	return emit;
}

/**
 * Create an emitter function that raises a specific event. 
 * 
 * Useful as an event handler, eg in `onClick={myEmitter}`
 * 
 * @param session The session these events should be raised to.
 * @param name The name of the event that will be raised.
 * @param properties (optional) properties to assign to any event triggered by the emiter.
 * @returns An emitter function that will raise this event and optionally takes properties to assign to that event only.
 */
export function useSimpleEvent(session: IScopedSession, name: EventNames, properties?: EventTags)
{
	const rootProperties = useDefaultTrackingState();
	const componentProperties = useStable(properties);
	const emit = useCallback(() => 
	{
		const payload = Object.freeze({
			...rootProperties,
			...componentProperties
		});
		session.event(name, payload);
	}, [rootProperties, componentProperties, session, name]);
	return emit;
}

/**
 * Create a navigation event emitter function. Just shorthand for useSimpleEvent with specific properties.
 * 
 * @param session The session these events should be raised to.
 * @param name The name of the *destination*
 * @param url The url of the destination
 * @param properties (optional) properties to assign to any event triggered by the emiter.
 * @returns An emitter function that will raise this event and optionally takes properties to assign to that event only.
 */
export function useNavigationEvent(session: IScopedSession, name: string, url?: string, properties?: EventTags)
{
	return useSimpleEvent(session, EventNavigationNames.To, {
		[EventStructureTags.DestinationName]: name,
		[EventStructureTags.DestinationUrl]: url,
		...properties
	});
}

/**
 * Create a stable pass-through function that can inline-replace a method and fire an event when the function is
 * called.  
 * 
 * Eg: ```
 *   const existingFunc = useCallback((param:string) => {...etc...}, []);
 *   const wrapperToEmitEvent = useAmplitudePassthrough(session, 'MyEventName', existingFunc, []);
 *   ...
 *   wrapperToEmitEvent('param is passed through');
 * ```
 * 
 * @param session 
 * @param name 
 * @param passThrough 
 * @param dependencies 
 * @param properties 
 * @returns 
 */
export function useAmplitudePassthrough<T extends (...params:unknown[]) => unknown>(
	session: IScopedSession,
	name: EventNames, 
	passThrough: T,
	dependencies: unknown[],
	properties?: EventTags, 
)
{
	const rootProperties = useDefaultTrackingState();
	const componentProperties = useStable(properties);

	const emit = useCallback((...params: unknown[]) => 
	{
		const payload = Object.freeze({
			...rootProperties,
			...componentProperties,
		});
		session.event(name, payload);
		return passThrough(...params);
	}, [
		session,
		name, 
		componentProperties,
		rootProperties,
		...dependencies
	]);

	return emit;
}

/**
 * A hook that provides a stable object tracking common redux data that we include on our events.
 */
export const useDefaultTrackingState: () => EventTags = () => 
{
	const userAuth = useSelector((state: RootState) => state.auth);

	const {SelectedGrowerId, SelectedPlanId, SelectedYear} = useSelector((state:RootState) => state.ui);
	const stableProperties = useStable<EventTags>(
		{
			[EventUserTags.ManagedUserGhxFieldsId]: userAuth.selectedUser?.UserId,
			[EventUserTags.ManagedUserEmail]: userAuth.selectedUser?.UserEmail,
			[EventUserTags.ManagedUserFoundationId]: userAuth.selectedUser?.FoundationId,
			[EventUserTags.ManagedUserName]: userAuth.selectedUser?.UserName,
			[EventUserTags.ManagedUserPermissions]: userAuth.selectedUser?.Permissions.join(','),
			[EventSelectionTags.GrowerId]: SelectedGrowerId,
			[EventSelectionTags.PlanId]: SelectedPlanId,
			[EventSelectionTags.Year]: SelectedYear,
		});

	return stableProperties;
};
