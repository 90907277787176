import React from 'react';
import { IProductLine } from '../../../../logic/Models/Requests/ProductPlanRequest';
import styled from 'styled-components';
import { CropConfig, soyId } from '../../../../logic/store/Seeds/CropsSlice';
import { AppDispatch, RootState } from '../../../../logic/store/Store';
import { UserPermission } from '../../../../logic/Models/Responses/UserPermissions';
import { connect, ConnectedProps } from 'react-redux';
import { hasEffectivePermission } from '../../../../logic/store/User/AuthSlice';
import { themeGet } from '@styled-system/theme-get';

const StyledSummaryRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: stretch;
	
	.FixedArea {
		width: 200px;
	}

	.FlexArea {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		align-items: center;
		flex-wrap: wrap;
		background-color: ${themeGet('colors.darkGrey')};
		border-radius: 0 0 6px 6px;

		>div {
			font-size: ${themeGet('fontSizes.extraSmall')};
			font-family: ${themeGet('fonts.base')};
			color: ${themeGet('colors.white')};
			flex-grow: 0;
			flex-shrink: 0;
			width: 120px;
			flex-grow: 1;
			display:flex;
			flex-direction: row;
			justify-content: center;
		}
	}
`;

interface IComponentProps extends PropsFromRedux 
{
	cropId: string;
	hybrids: IProductLine[];
}

const mapStateToProps = (state: RootState) => ({
	allowPricing: hasEffectivePermission(state, UserPermission.CanSeePricing)
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


/**
 * This is the thin black summary row that appears after multiple hybrids are listed
 */
export const ProductSummaryRow = (props: IComponentProps) =>
{
	const { hybrids, cropId, allowPricing } = props;
	const cropConfig = CropConfig()[cropId];

	// Aggregate all values (weighted by area for averages)
	const values = hybrids.reduce((collector, hybrid) =>
	{
		if (!hybrid.AppliedArea)
		{
			return collector;
		}
		const priceRate = hybrid.PriceRate + (hybrid.Treatment?.Price ?? 0);

		const isSoy = cropId === soyId;
		const cost = (!isSoy)
			? priceRate
			: hybrid.TotalPrice && hybrid.AppliedArea
				? hybrid.TotalPrice / hybrid.AppliedArea
				: 0;

		return {
			yield: collector.yield + ((hybrid.YieldTarget ?? 0) * hybrid.AppliedArea),
			seedingRate: collector.seedingRate + ((hybrid.SeedingRate ?? 0) * hybrid.AppliedArea),
			cost: collector.cost + (cost * hybrid.AppliedArea),
			quantity: collector.quantity + (hybrid.Quantity ?? 0),
			totalPrice: collector.totalPrice + (hybrid.TotalPrice ?? 0),
			area: collector.area + (hybrid.AppliedArea ?? 0),
			aggregatedPricingArea: collector.aggregatedPricingArea + (priceRate ? hybrid.AppliedArea : 0),
			unit: hybrid.PriceUnit
		};
	}, {
		yield: 0,
		seedingRate: 0,
		cost: 0,
		quantity: 0,
		totalPrice: 0,
		area: 0,
		aggregatedPricingArea: 0,
		unit: ''
	});

	// This structure mimics HybridProductRow so that the columns more or less align
	return <StyledSummaryRow className='ProductSummaryRow'>
		<div className='FixedArea' />
		<div className='FlexArea'>
			{
				cropConfig?.hasYieldTarget &&
				<div className='HybridYieldTarget'>
					{!values.area ? '' : (values.yield / values.area).toFixed(0)} Target Yield
				</div>
			}
			{allowPricing && <div className='HybridCostPerAcre'>
				{
					(values.aggregatedPricingArea && values.cost)
						? <>
							{(values.cost / values.aggregatedPricingArea).toFixed(2)} Cost/Ac
						</>
						: <>
							---
						</>
				}
			</div>
			}
			<div className='HybridCost'>
				{values.area.toFixed(1)} Acres
			</div>
			<div className='HybridQuantity'>
				{values.quantity} Bags
			</div>
			<div className='HybridSeedRate'>
				Seeds/Ac: {!values.area ? '' : (values.seedingRate / values.area).toFixed(0)}
			</div>
			{allowPricing &&
				<div className='HybridTotalPrice'>
					$ Total: ${values.totalPrice.toFixed(2)}
				</div>
			}
		</div>
	</StyledSummaryRow>;
};

export const ConnectedProductSummaryRow = connector(ProductSummaryRow);