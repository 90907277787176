import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { clearSelectedGrowerIdState } from '../../../logic/store/UI/UISlice';
import { addFileToList, clearState as clearFileState, clearFileUploadList } from '../../../logic/store/MachineFileData/FileSlice';
import { ReactComponent as Ellipse } from '../../../assets/images/Ellipse.svg';
import { ReactComponent as LeftCaret } from '../DashboardTable/Icons/LeftCaret.svg';
import { ReactComponent as UploadIcon } from './Icons/Upload.svg';
import { ReactComponent as ErrorIcon } from './Icons/Error.svg';
import { makeDispatch } from '../../../logic/Utility/Utils';
import { Upload, UploadProps } from 'antd';
import { getDownloadUrlForFile, getUploadedFilesForUser, getUploadUrlForFile, uploadFileToUrl } from '../../../logic/store/MachineFileData/FileThunks';
import { IFileListingRowItem } from './FileListingRow';
import { FileListingTable } from './FileListingTable';
import { FileUploadModal } from './FileUploadModal';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { Button } from '../../../components/Button/Button';
import { RcFile } from 'antd/lib/upload';
import _ from 'lodash';
import { themeGet } from '@styled-system/theme-get';

const { Dragger } = Upload;

const FileUploadMainContainer = styled.div`
	height: 100%;
`;

const BackToGrowerListContainer = styled.div`
	display: flex;
	height: 52px;
	padding: 10px 25px;
	align-items: center;
`;

const GrowersListHeaderTitle = styled.span`
	color: ${themeGet('colors.lightGrey')};
	font-family: ${themeGet('fonts.heading')};
`;

const GrowerNameHeaderTitle = styled.span`
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.large')};
	font-family: ${themeGet('fonts.heading')};
	width: 60%;
`;

const PageTitle = styled.div`
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.xLarge')};
	font-family: ${themeGet('fonts.heading')};
`;

const FileUploadInnerContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	padding-right: 5%;
	padding-left: 5%;
	flex-direction: column;
`;

interface IFileUploadProps extends PropsFromRedux
{
}

const FileUploadComponent = (props: IFileUploadProps) =>
{
	const {
		FileList,
		FileUploadList,
		Growers,
		SelectedGrowerId,
		AddFileToUploadList,
		ClearFileState,
		ClearFileUploadList,
		ClearSelectedGrowerId,
		DownloadFile,
		GetFileListingForUser,
		GetFileUploadUrl,
		UploadFileToUrl,
	} = props;

	const theme = useTheme();

	const [fileListRows, setFileListRows] = useState<IFileListingRowItem[]>([]);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
	const [errorModalMessage, setErrorModalMessage] = useState<string>('');
	const [inProgressPromises, setInProgressPromises] = useState<any[]>([]); // Type on a dispatch thunk is too complicated

	const expectedFileTypes = ['application/x-zip-compressed','application/zip','zip','application/x-zip'];

	const selectedGrower = Growers.find(g => g.Id === SelectedGrowerId);

	useEffect(() =>
	{
		ClearFileState();
		setShowErrorModal(false);
		setShowUploadModal(false);
		GetFileListingForUser();
	},[]);

	useEffect(() =>
	{
		if (FileList)
		{
			const rowItems: IFileListingRowItem[] = FileList.map(file =>
			{
				let fileName = 'Unknown File Name';
				if (file.FileUri)
				{
					/* The filename is 3 _'s into the file uri */
					const splitByUnderscore = decodeURI(file.FileUri).split('_');
					if (splitByUnderscore.length >= 3)
					{
						fileName = splitByUnderscore[2];
						if (splitByUnderscore.length > 3)
						{
							for (let i = 3; i < splitByUnderscore.length; i++)
							{
								fileName = fileName + '_' + splitByUnderscore[i];
							}
						}
					}
				}

				const rowItem: IFileListingRowItem =
				{
					Id: file.FileId,
					JobId: file.JobId,
					FileName: fileName,
					UploadedDate: file.Created,
					UserName: file.EluminateUserName,
					Status: file.Status,
					downloadFile: downloadFile,
				};
				return rowItem;
			});

			setFileListRows(rowItems);
		}
	}, [FileList]);

	const downloadFile = (jobId: string) =>
	{
		DownloadFile({ JobId: jobId });
	};

	const backToGrowerList = () =>
	{
		ClearSelectedGrowerId();
		ClearFileState();
	};

	/**
	 * Types exported from RC files to allow us to use the customRequest in AntDesign's uploader...
	 */
	interface IUploadProgressEvent extends Partial<ProgressEvent> {
		percent?: number;
	}

	type UploadRequestMethod = 'POST' | 'PUT' | 'PATCH' | 'post' | 'put' | 'patch';
	type BeforeUploadFileType = File | Blob | boolean | string;
	type UploadRequestHeader = Record<string, string>;

	interface IUploadRequestError extends Error {
		status?: number;
		method?: UploadRequestMethod;
		url?: string;
	}

	interface IUploadRequestOption<T = any> {
		onProgress?: (event: IUploadProgressEvent) => void;
		onError?: (event: IUploadRequestError | ProgressEvent, body?: T) => void;
		onSuccess?: (body: T, xhr?: XMLHttpRequest) => void;
		data?: Record<string, unknown>;
		filename?: string;
		file: Exclude<BeforeUploadFileType, File | boolean> | RcFile;
		withCredentials?: boolean;
		action: string;
		headers?: UploadRequestHeader;
		method: UploadRequestMethod;
	}
	/**
	 * End RC Types
	 */

	// To keep the upload component from throwing an error since we are uploading in a different manner
	// The types here are a nightmare, ant-design is not properly exporting the type for the customRequest
	const dummyRequest = (options: IUploadRequestOption<any>) =>
	{
		setTimeout(() => { options.onSuccess('ok');}, 0);
	};

	const beforeUpload = async (file: RcFile, FileList?: RcFile[]) =>
	{
		if (FileList && FileList.length > 0)
		{
			FileList.forEach(f => validateFile(f));
		}
		else
		{
			validateFile(file);
		}
	};

	const validateFile = (file: RcFile) =>
	{
		const fileExtSplit = file.name.split('.');
		const fileExt = fileExtSplit[file.name.length - 1];
		const isZip = expectedFileTypes.includes(file.type) && fileExt !== '.zip';
		if (!isZip)
		{
			setErrorModalMessage(`${file.name} is not a zip file.`);
			setShowErrorModal(true);
			return isZip || Upload.LIST_IGNORE;
		}

		AddFileToUploadList(file);
	};

	// As we update the list, kick off the appropriate actions
	useEffect(() =>
	{
		if (FileUploadList && FileUploadList.length > 0)
		{
			setShowUploadModal(true);
			FileUploadList.forEach(fileTracker =>
			{
				// Check if we need to get a file's upload url
				if (!fileTracker.IsDone && !fileTracker.IsError && !fileTracker.IsLoading && !fileTracker.FileUploadUrlResponse)
				{
					// Get the URL and authorization data to Upload with
					GetFileUploadUrl({ FileName: fileTracker.File.name });
				}
				
				if (!fileTracker.IsDone && !fileTracker.IsError && !fileTracker.IsLoading && !!fileTracker.FileUploadUrlResponse)
				{
					// Keep track of the promises we've kicked off so we can potentially cancel them later if the user chooses to close the modal
					const promise = UploadFileToUrl(fileTracker.File);
					setInProgressPromises([...inProgressPromises, promise]);
				}
			});
		}
	},[FileUploadList]);

	const uploadProps: UploadProps =
	{
		beforeUpload: beforeUpload,
		showUploadList: false,
		multiple: true,
		customRequest: dummyRequest,
		onDrop(e)
		{
			if (e && e.dataTransfer.files)
			{
				for(let i = 0; i < e.dataTransfer.files.length; ++i)
				{
					validateFile(e.dataTransfer.files[i] as RcFile);
				}
			}
		},
	};

	// On cancelling the upload modal, abort any promises that can be aborted, then clear our state
	const onCancelUploadModal = (done: boolean) =>
	{
		if (inProgressPromises && inProgressPromises.length > 0)
		{
			inProgressPromises.forEach(p =>
			{
				p.abort();
			});
		}
		ClearFileUploadList();
		setInProgressPromises([]);
		setShowUploadModal(false);

		// If this is a done button click, we want to refresh the file list
		if (done)
		{
			GetFileListingForUser();
		}
	};

	return (
		<FileUploadMainContainer className='FileUploadMainContainer'>
			<BackToGrowerListContainer className='backTo_GrowerList_Container'>
				<Link to='/dashboard' className='linkTo_GrowerList' onClick={backToGrowerList}>
					<LeftCaret style={{ marginRight: '20px' }} />
					<GrowersListHeaderTitle>
						Growers List
					</GrowersListHeaderTitle>
				</Link>
				<Ellipse style={{ margin: '0 10px' }} />
				<GrowerNameHeaderTitle className='FileUpload_Title_GrowerName'>
					{selectedGrower.Name} Machine Data
				</GrowerNameHeaderTitle>
			</BackToGrowerListContainer>
			<FileUploadInnerContainer>
				<div className='FileUpload_Section_Dragger' style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
					<PageTitle className='FileUpload_Section_Dragger_Title'>
						File Upload
					</PageTitle>
					<Dragger {...uploadProps}>
						<p>
							<UploadIcon />
						</p>
						<div style={{ fontSize: theme.fontSizes.xLarge, fontWeight: theme.fontWeights.bold }}>Click or drag a .zip file to this area to upload</div>
						<div style={{ fontSize: theme.fontSizes.normal }}>Support for a single or bulk upload</div>
					</Dragger>
				</div>
				<div
					className='FileUpload_Section_History'
					style={{
						display: 'flex',
						flexDirection: 'column',
						flex: '1 1 auto',
						overflow: 'hidden',
						paddingBottom: 64
					}}>
					<PageTitle className='FileUpload_Section_History_Title'>
						History
					</PageTitle>
					{
						!!fileListRows &&
						<FileListingTable rowData={fileListRows} />
					}
				</div>
			</FileUploadInnerContainer>
			<StyledModal
				onCancel={() => setShowErrorModal(false)}
				open={showErrorModal}
				title='Uploading Error'
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<ErrorIcon /><span style={{ marginLeft: 10 }}>{errorModalMessage}</span>
				</div>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
					<Button
						className='returnToUploadFiles-button'
						variant='dark'
						onClick={() => setShowErrorModal(false)}
					>Return to Upload Files</Button>
				</div>
			</StyledModal>
			<FileUploadModal fileUploadList={FileUploadList} visible={showUploadModal} onCancel={onCancelUploadModal} />
		</FileUploadMainContainer>
	);
};

const mapStateToProps = (state: RootState) => ({
	FileList: state.file.FileListing,
	FileUploadList: state.file.FileUploadList,
	Growers: state.grower.Growers,
	SelectedGrowerId: state.ui.SelectedGrowerId,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		AddFileToUploadList: makeDispatch(dispatch, addFileToList),
		ClearFileState: makeDispatch(dispatch, clearFileState),
		ClearFileUploadList: makeDispatch(dispatch, clearFileUploadList),
		ClearSelectedGrowerId: makeDispatch(dispatch, clearSelectedGrowerIdState),
		DownloadFile: makeDispatch(dispatch, getDownloadUrlForFile),
		GetFileListingForUser: makeDispatch(dispatch, getUploadedFilesForUser),
		GetFileUploadUrl: makeDispatch(dispatch, getUploadUrlForFile),
		UploadFileToUrl: (file: RcFile) => dispatch(uploadFileToUrl(file)),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const FileUploadMain = connector(FileUploadComponent);