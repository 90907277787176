import React, { useCallback, useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import _ from 'lodash';
import { Button } from '../../../components/Button/Button';
import { useHistory } from 'react-router-dom';
import { FieldPlanCropPlanColorsList } from '../../../logic/Utility/Colors';
import { dynamicSort, makeDispatch } from '../../../logic/Utility/Utils';
import { setSelectedPlanId } from '../../../logic/store/UI/UISlice';
import { deleteCropPlan, getFieldPlan, pollGetFieldPlan, runCropPlan, updateFieldPlan } from '../../../logic/store/Plans/FieldPlan/FieldPlanThunks';
import { Tooltip } from 'antd';
import { cornId, CropConfig } from '../../../logic/store/Seeds/CropsSlice';
import { CropPlanOverviewCard } from './Cards/CropPlanOverviewCard';
import { ReactComponent as CornIcon } from './Icons/CornIcon.svg';
import { ReactComponent as SoyIcon } from './Icons/SoybeanIcon.svg';
import { ReactComponent as PdfIcon } from '../../../assets/images/PDFIcon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/PlusIcon.svg';
import { ReactComponent as Pencil } from './Cards/Icons/Pencil.svg';
import { clearState as clearFieldPlanState } from '../../../logic/store/Plans/FieldPlan/FieldPlanSlice';
import { IconButton } from '../../../components/IconButton/IconButton';
import { EditableInputDisplay } from '../../../components/EditableInputDisplay/EditableInputDisplay';
import { IFieldPlanResponse } from '../../../logic/Models/Responses/FieldPlanResponse';
import { StyledModal } from '../../../components/StyledModal/StyledModal';
import { downloadPlanPdf } from '../../../logic/store/Grower/PlanThunks';
import { IPlanCoverConfig } from '../../../logic/Models/Requests/PlanRequest';
import { RoundAcres } from '../../../logic/Utility/CalculationUtilities';
import { useCropPlanTrackingState } from './useCropPlanTrackingState';
import { useAmplitudePassthrough } from '../../../logic/Utility/useAmplitude';
import { useScopedSession } from '../../../tracing/session';
import { EventCropPlanTags, EventPlanTags, EventStructureTags } from '../../../tracing/EventTagNames';
import { EventFieldPlanNames, EventNavigationNames } from '../../../tracing/EventNames';

const OverviewContainer = styled.div`
	padding-left: 20px;
	padding-top: 20px;
	padding-right: 20px;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-bottom: 10px;
	flex: 1 1 auto;
	overflow: hidden;
`;

interface ICropPlanOverviewProps
{
	selectCropPlan: (planId: string) => void;
	addNewCropPlan: () => void;
}

const mapStateToProps = (state: RootState) =>
{
	const SelectedGrower = state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId);

	return {
		FieldPlan: state.fieldplan.currentFieldPlan,
		IsFieldPlanLoading: state.fieldplan.isLoading,
		IsFieldPlanPollComplete: state.fieldplan.pollComplete,
		IsPollLoading: state.fieldplan.pollLoading,
		SelectedGrower,
		SelectedPlanId: state.ui.SelectedPlanId,
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) => 
{
	return {
		ClearFieldPlanState: makeDispatch(dispatch, clearFieldPlanState),
		GetFieldPlanData: makeDispatch(dispatch, getFieldPlan),
		SelectPlanId: makeDispatch(dispatch, setSelectedPlanId),
		PollGetFieldPlan: makeDispatch(dispatch, pollGetFieldPlan),
		DeleteCropPlan: makeDispatch(dispatch, deleteCropPlan),
		RerunCropPlan: makeDispatch(dispatch, runCropPlan),
		UpdateFieldPlan: makeDispatch(dispatch, updateFieldPlan),
		DownloadFieldPlanPdf: makeDispatch(dispatch, downloadPlanPdf),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/**
 * The Crop Plan Overview page 
 */
const CropPlanOverview = (props: ICropPlanOverviewProps & PropsFromRedux) =>
{
	const {
		selectCropPlan,
		addNewCropPlan,
		FieldPlan,
		IsFieldPlanLoading,
		IsFieldPlanPollComplete,
		IsPollLoading,
		SelectedGrower,
		SelectedPlanId,
		ClearFieldPlanState,
		DeleteCropPlan,
		DownloadFieldPlanPdf,
		RerunCropPlan,
		GetFieldPlanData,
		SelectPlanId,
		PollGetFieldPlan,
		UpdateFieldPlan,
	} = props;

	const theme = useTheme();
	const history = useHistory();
	
	const [showLastCropPlanDeleteModal, setShowLastCropPlanDeleteModal] = useState<boolean>(false);
	
	const trackingData = useCropPlanTrackingState();
	
	const session = useScopedSession(CropPlanOverview.name, trackingData, {
		[EventStructureTags.PageContext]: 'crop_plan_overview',
		[EventStructureTags.PageUrl]: window.location.toString()
	});

	const returnToRecallPage = useAmplitudePassthrough(session, EventNavigationNames.To, () =>
	{
		ClearFieldPlanState();
		SelectPlanId(undefined);
		history.push('/fieldactivities/recall');
	}, [history], { [EventStructureTags.DestinationUrl]: '/fieldactivities/recall', [EventStructureTags.DestinationName]: 'recall' });

	useEffect(() =>
	{
		if (SelectedPlanId && !IsFieldPlanLoading)
		{
			GetFieldPlanData({ planId: SelectedPlanId });
		}
	},[SelectedPlanId]);

	useEffect(() =>
	{
		// Poll until the Crop Plans are all successes
		if (!IsFieldPlanPollComplete && FieldPlan && !IsPollLoading && FieldPlan.CropPlans.some(cp => cp.Status === 'Pending' || cp.Status === 'Processing'))
		{
			PollGetFieldPlan();
		}
	},[FieldPlan]);

	const cropPlans = FieldPlan ? _.cloneDeep(FieldPlan.CropPlans) : [];
	const fieldPlanCopy: IFieldPlanResponse = FieldPlan ? _.cloneDeep(FieldPlan) : undefined;

	const validateFieldPlanName = (value: string): boolean =>
	{
		return true;
	};

	const saveFieldPlanName = useAmplitudePassthrough(session, EventFieldPlanNames.CreateFieldPlan, (value: string) =>
	{
		UpdateFieldPlan({ Name: value.trim() });
	}, [UpdateFieldPlan]);

	const trackAddNewCropPlan = useAmplitudePassthrough(session, EventFieldPlanNames.CreateCropPlan, addNewCropPlan, [addNewCropPlan]);
	const trackRerunCropPlan = useAmplitudePassthrough(session, EventFieldPlanNames.RerunCropPlan, RerunCropPlan, [RerunCropPlan]);

	const disablePdfButton = useCallback(() =>
	{
		if (FieldPlan && FieldPlan.CropPlans)
		{
			if (FieldPlan.CropPlans.length === 0)
			{
				return true;
			}
			
			if (FieldPlan.CropPlans.flatMap(cp => cp.FieldIds).length === 0)
			{
				return true;
			}

			return false;
		}
	},[FieldPlan]);

	const onDeleteCropPlan = useAmplitudePassthrough(session, EventFieldPlanNames.DeleteCropPlan, (cropPlanId: string) =>
	{
		if (FieldPlan.CropPlans.length === 1)
		{
			// Last Crop Plan, prompt the user if they want to continue
			setShowLastCropPlanDeleteModal(true);
		}
		else if (FieldPlan.CropPlans.filter(cp => cp.Id !== cropPlanId).flatMap(f => f.FieldIds).length === 0)
		{
			// If you're deleting the last crop plan with fields available, the entire Field Plan will be deleted
			setShowLastCropPlanDeleteModal(true);
		}
		else
		{
			// Delete the plan as usual
			DeleteCropPlan({ CropPlanId: cropPlanId, ConfirmLastCropPlan: false });
		}
	},[FieldPlan]);

	const userConfirmDeleteLastPlan = useAmplitudePassthrough(session, EventFieldPlanNames.DeleteFinalCropPlan, async () =>
	{
		setShowLastCropPlanDeleteModal(false);
		await DeleteCropPlan({ CropPlanId: FieldPlan.CropPlans[0].Id, ConfirmLastCropPlan: true });
		returnToRecallPage();
	},[FieldPlan]);

	const onDownloadFieldPlanPdf = useAmplitudePassthrough(session, EventFieldPlanNames.DownloadFieldPlanPdf, () =>
	{
		const coverConfig: IPlanCoverConfig = undefined;
		DownloadFieldPlanPdf({ PlanId: FieldPlan?.Id, CoverConfig: coverConfig });
	},[FieldPlan], {
		[EventPlanTags.PlanType]: 'field'
	});

	return (
		FieldPlan ?
			<div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', overflow: 'hidden' }}>
			
				<OverviewContainer>
					<div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20, fontWeight: theme.fontWeights.bold, alignItems: 'center' }}>
						<div style={{ fontSize: 16 }}>
							<EditableInputDisplay
								className={'FieldProposalNameEditable'}
								validationTooltip={'Proposal Name is not valid.'}
								description={''}
								editIconButtonStyle={{ position: 'relative', top: -3, marginLeft: 10 }}
								editButtonTooltip={'Edit Proposal Name'}
								editIcon={<Pencil />}
								inputValue={fieldPlanCopy?.Name}
								inputType='text'
								validateInput={validateFieldPlanName}
								saveInput={saveFieldPlanName}
							/>
						</div>
						<div style={{ marginLeft: 'auto' }}>
							<IconButton
								showDisabledTooltip={true}
								tooltip={disablePdfButton() ? 'Must have at least one Crop Plan with fields assigned to print the PDF.' : 'Print PDF' }
								disabled={disablePdfButton()}
								placement='right'
								className={'FieldProposal_PrintPDF'}
								onClick={onDownloadFieldPlanPdf}
								hoverbgcolor={theme.colors.lightestGrey}
							>
								<PdfIcon height={35} width={35} />
							</IconButton>
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							borderTop: '1px solid',
							borderColor: theme.colors.lightestGrey,
							paddingTop: 20,
							alignItems: 'center',
							marginBottom: 10
						}}>
						<div style={{ fontWeight: theme.fontWeights.bold, fontSize: theme.fontSizes.large, fontFamily: theme.fonts.heading }}>Crop Plans</div>
						<div style={{ marginLeft: 'auto'}}>
							<Button
								disabled={false}
								className='AddPlan_Button'
								style={{ height: 40, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
								variant='outlined'
								onClick={trackAddNewCropPlan}
							>
								<PlusIcon width={12} height={12} style={{ position: 'relative', top: -1, marginRight: 5 }} fill={theme.colors.darkGrey} />
								Add Plan
							</Button>
						</div>
					</div>
					<div style={{ overflowY: 'auto', paddingRight: 5 }}>
						{
							cropPlans && cropPlans.length > 0 ?
								cropPlans.sort(dynamicSort('Created', 'descending')).map((cropPlan, index) =>
								{
									const totalAcres = SelectedGrower.Farms.flatMap(fm => fm.Fields)
										.filter(f => cropPlan.FieldIds.includes(f.Id))
										// Apply the same rounding being done internally and on the PDF
										.reduce((total, field) => total + RoundAcres(field.Area), 0);
									const totalFields = cropPlan.NumberOfFields;
									const disabled = false;
									const disabledTooltip = '';
									const colorsList: string[] = FieldPlanCropPlanColorsList[`${cropPlan.CropId}_${cropPlan.BrandApplication}`];
					
									let effectiveStatus = cropPlan.Status;
									let effectiveMessage = cropPlan.Message;

									if(!cropPlan.FieldIds.length)
									{
										effectiveStatus = 'Error';
										effectiveMessage = 'No fields assigned to this plan';
									}
									// Check if this has timed out.
									else if((cropPlan.Status === 'Pending' || cropPlan.Status === 'Processing') 
										&& new Date(cropPlan.Modified).getTime() < new Date().getTime() - (1000 * 60))
									{
										effectiveStatus = 'Error';
										effectiveMessage = 'Plan timed out';
									}

									return (
										<Tooltip key={cropPlan.Id} title={disabled? disabledTooltip : ''} placement='right'>
											<div key={cropPlan.Id} style={{ cursor: disabled ? 'not-allowed' : 'inherit', display: 'flex' }}>
												<CropPlanOverviewCard
													key={cropPlan.Id}
													disabled={disabled}
													className={`CropPlanTabButton_${CropConfig()[cropPlan.CropId].cropName}`}
													title={cropPlan.Name}
													tabColor={cropPlan.Color ? cropPlan.Color : colorsList[index % colorsList.length]}
													displayNamesAndValues={[
														['Fields', totalFields?.toString()],
														['Acres', totalAcres?.toLocaleString('en-US', {maximumFractionDigits:1})]
													]}
													status={effectiveStatus}
													message={effectiveMessage}
													onClick={() => selectCropPlan(cropPlan.Id)}
													onDelete={() => onDeleteCropPlan(cropPlan.Id)}
													onRerun={() => trackRerunCropPlan({ CropPlanId: cropPlan.Id} )}
													icon={cropPlan.CropId === cornId ? <CornIcon height={26} width={26} /> : <SoyIcon />}
												/>
											</div>
										</Tooltip>
									);
								})
								:
								< ></>
						}
					</div>
				</OverviewContainer>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',  marginTop: 'auto', padding: '10px 20px 10px 20px' }}>
					<Button
						disabled={false}
						className='Cancel_Button'
						style={{ height: 40, minWidth: 160, marginRight: 15 }}
						variant='outlined'
						onClick={returnToRecallPage}
					>
						Go to Proposal List
					</Button>
				</div>
				<StyledModal title='' open={showLastCropPlanDeleteModal} onCancel={() => setShowLastCropPlanDeleteModal(false)}>
					<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
						<div style={{ marginTop: 10 }}>Deleting this crop plan will delete this proposal. Would you like to continue?</div>
						<div style={{ marginLeft: 'auto', marginTop: 20 }}>
							<Button className='DeleteModal_Cancel_Button'
								style={{ height: 'auto', width: 75, marginRight: 15 }}
								variant='dark'
								onClick={() => setShowLastCropPlanDeleteModal(false)}
							>
								Cancel
							</Button>
							<Button className='DeleteModal_Continue_Button'
								style={{ height: 'auto', width: 75, marginRight: 15 }}
								variant='outlined'
								onClick={userConfirmDeleteLastPlan}
							>
								Continue
							</Button>
						</div>
					</div>
				</StyledModal>
			</div>
			:
			<></>
	);
};

export const ConnectedCropPlanOverview = connector(CropPlanOverview);